import React from 'react'
import { Link } from 'react-router-dom';
import { getSlamMasters } from "../../data";

const GrandSlamChampions = () => {
  let slamMasters = getSlamMasters();
  return (
    <div>
      <h1 className='champs-header-main'>Nuyorican Grand Slam Champions</h1>
      <div className="row">
      {slamMasters.map((slamMaster) => (

        <div className="col-md-4 col-sm-6 col-xs-6">
          <Link to={`${slamMaster.slug}`}><img className="img-fluid" src={slamMaster.picture} alt="" /></Link>
          <p><Link to={`${slamMaster.slug}`}>{slamMaster.name}</Link></p>
        </div>
        ))}
      </div>
      <p>The Grand Slam Champion title was introduced at the Nuyorican by Bob Holman, our founding SlamMaster. While some
people would agree that it takes a kind of champion to even get up in front of a few hundred people and read /perform their
own poetry in the first place, the tradition of the Grand Slam Champion is honored and pretty common throughout the
entire Poetry Slam community...most venues have a yearly contest to award it.</p>
<p>
The Champion title at the Nuyorican is won from the Grand Slam Finale...the culminating Friday Night Poetry Slam each
year that usually happens in April or May. The title can be won by less than one point in that competition, so truth be told,
everyone who has ever Slammed in one of our Finales is Championship material. (And that's why there's an entire section
of this site dedicated to them!)
For a brief period of time at the Nuyorican, from 1991 until 1993. there were two Grand Slam Champions named during
different parts of the year instead of one. Holman was creatively experimenting with formats and ideas as he first brought
Poetry Slam to the Nuyorican and not all his experiments "stuck". But from 1994 on, the title belonged to that one person
who emerged victorious from the Finale.</p>
<p>
What do Nuyorican Grand Slam Champions have in common? I think it's incredible focus and a passionate message that
is universal and aware of the world around them. While it is often said in the Slam community that "the best poet often
loses", Grand Slam Champions represent the brightest new talent of their time...often on a cutting edge between art and
activism, clearly driven to inspiring others with their work.</p><p>
Some of these wonderful writers and performers are academics now; many are widely published or have been seen on
television and some have even had their own Broadway shows. Reg E Gaines (1991) has won a Grammy, as well as Tony
and Bessie Awards for Best Book/Lyrics for "Bring In Da Noise Bring In Da Funk". Sarah Jones (1997) has gone on to win
a Tony Award for an original one-woman show on Broadway called "Surface Transit". If I was to list the inspiring
accomplishments of every single one of our Champs, well, I'd need another website! You'll just have to check out each
one for yourself. The Nuyorican Poets Cafe is proud of all their impressive accomplishments and pleased to have played a
role in helping to "get their voices heard". -CU</p>
    </div>
  )
}

export default GrandSlamChampions
