import React from 'react'

const KarenJaime
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-karen.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1 className='title-bold'>karen jaime</h1>
      <h2 className='sub-head'>Nuyorican SlamMistress from 2002 until 2005</h2>
    <p>Karen is a New York based spoken word/performance artist, cultural activist and
writer. Her fiery delivery and in your face style has been gracing audiences since
she first put pen to paper at Cornell University where she received a Bachelor of
Arts in History and Spanish Literature with a concentration in Latin American
Studies. She first slammed at the Nuyorican Poets' Cafe in 1998 and since then
has performed at The Bottom Line Cabaret, Bluestockings Women's Bookstore,
CBGB's, The Town Hall and at colleges throughout the U.S. and Latin America .
In the summer of 2002, she performed and co-produced "The Mystique of Fly" in
the Queer HERE theater festival held at HERE Theater. She is the former curator/
SlamMstress of the Friday Night Slam at the Nuyorican Poets Cafe. Currently, a
PhD. candidate in the Department of Performance Studies at New York
University , she recently received a Rockefeller Humanities Research Fellowship. More bout Karen here: <a href="https://pma.cornell.edu/karen-jaime">https://pma.cornell.edu/karen-jaime</a></p>

      </div>

      </div>

    </div>
  )
}

export default KarenJaime

