import React from 'react'

const GrandSlam1998 = () => {
  return (
    <div>
    <h1>1998 Nuyorican Grand Slam Finale</h1>
    <p>This was the first year I created the evening "program" for the Nuyorican Grand Slam
and it was the only booklet-style Grand Slam program we ever made as we had some
grant money at the time for it. All Grand Slam Finale programs since then have been
much simpler and less expensive to produce. The boxing gloves used here on the
cover were never used again in subsequent programs; while they make visual sense,
it seemed to me that they are pretty overused in Slam. <a href="#poster">See 1998 Grand Slam Finale Program Cover/Back Page Scorecard (created by Clare Ultimo)</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1998-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>nikki byrd</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1998-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>steve colman</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1998-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>kayo</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1998-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>guy lecharles gonzalez</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1998-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>andre salaam</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1998-6.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>faraji salim</p>
        </div>
        <img id="poster" src="/1998Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam1998
