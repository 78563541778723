import React from 'react'

const GrandSlam1991 = () => {
  return (
    <div>
      <h1>1991 Nuyorican Grand Slam Finale</h1>
      <p>Participants of the 1991 through 1995 Nuyorican Grand Slam Finales have not been verified.</p>
    </div>
  )
}

export default GrandSlam1991
