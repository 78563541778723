import React from 'react'

const GrandSlam2002 = () => {
  return (
    <div>
    <h1>2002 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: FELICE BELLE</p>
<p>Poets submitted these bios to me for the 2002 Finale Program, so they may not reflect projects and
accomplishments since that time. <a href="#poster">See original 2002 Grand Slam Finale Program</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2002-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>kahlil almustafa</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2002-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>devynity</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2002-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>gemineye</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2002-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>125</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2002-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>narubi selah</p>
        </div>
        <img id="poster" src="/2002Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam2002
