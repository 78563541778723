import React from 'react'
import ImageGallery1 from '../image-gallery-1';

const MichaelMeyers = () => {
  return (

    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/photos-michael.jpg" alt="" />
        </div>
      <div className="col-md-8">
      <h2 className='champs-header-main header-bold'>michael meyer's nuyorican slam album</h2>
    <ImageGallery1 />
    <p><a href="https://www.mikemeyer-photography.com">Michael Meyer</a></p>
    <p>E.B. White, in 1948, wrote: "A poem compresses much in a small space and adds mus,
thus heightening its meaning. The city is like poetry: it compresses all life all races and
breeds, into a small island and adds music and the accompaniment of internal engines."
When I came to New York I was looking for that island of compressed life driven by its
internal engine: and I had a very specific image in my mind of what I was looking for. I
enrolled at NYU without understanding that cities change, mutate, and shift, New York
doubly so. Neighborhoods are not neighborhoods are not neighborhoods forever.
Coming from a small town, I did not know that. What I was looking for was invisible,
gone.</p>
<p>A couple of weeks into my first semester at NYU, a friend of mine, a student in Tisch's
experimental theater program, suggested we head over to the Nuyorican Poet's Cafe to
see a poetry slam. I'd never heard of slam. Not once. At Auburn Middle School, Mr.
Berry, my eighth grade English teacher, taught me about the poetry of the beats and
hippies. The unit culminated in a "Poetry Night" at which small groups of students make
"coffee houses" and create a one night East Village escapade; a very tame East Village
escapade. When I recited my memorized poems I wore a brown suede anorak and
leggings with fringes. It was a sweet night rife with sentiment, but I'm not sure that any
of the students there would have tied that unit of poetry or that night of recitation with
any sort of current poetry practice. We did not talk about slam. We did not talk about hip
hop. What we talked about was history. So when Jaimie made the suggestion, she had
to show me "Aloud: Voices from the Nuyorican Poets' Cafe" to orient me. From the first
poem I read, I was hooked; and I still had no idea what slam was really all about</p>
<p>We went on a good night. I don't remember all of the slammers that graced the stage
that night, but I remember for sure that Stephen Colman was one. I remember this,
because every time I went back I hoped I'd get to hear Stephen again. And I remember
that when I left that night the one thing running through my head was, I'm coming back
next week. I did return the following week and for most Fridays for the next two years. I
even starting showing up on Wednesday nights for the open slams and on Saturday
nights for Rocky Presents. This was a kernel of the New York that I had been seeking
but not found. This was what I had come to New York for: an intensity of energy. A
down at the heels I'm gonna yell to whoever will listen kind of energy. And slam was fun
and entertaining to boot.</p>
<p>Here was a place with energy, character and characters. And the light was nice. What
more could a young, new to New York photographer ask for? Every time I went to the
Nuyo, I had a camera. I never read there (much to the benefit of all) but I photographed
most of the poets who did between 1998 and 2001. Some I would photograph many
times, some only once. Some went on to fame and fortune while others quietly slipped
away. This was a scene that seemed to be coming into its moment, and I wanted to be
there to record it.</p>
<p>When I made these photographs, I was a student. I was experimenting and playing with
documenting a space and its various occupants, as well as making the viewer feel like
they could smell the cigarette smoke, the crush of warm bodies and the piercing of the
spotlight onto the stage. I could never show the words and aural sensations--the best I
could do was to hint at that emotion and energy flowing from the stage. What I could
show were the physical manifestation of the slams: the characters and the details of the
Nuyorican and its slams. I could show Mum da Schemer's bomber jacket and carved
walking stick. I could show the curl of smoke around Keith Roach's head or his
sunglasses against the spotlight or the beer bottle inevitably dangling from his hand as
he moved the mic stand this side or that. I could show the Twin Poets seeming to meld
into a single figure. I could show Pablo Rosenbluth getting a little crazy. I could show
Jennifer Murphey's pant cuffs askew on her boots. I could show the different faces of
Sarah Jones. The physical forms of the Cafe and its poets I could show. As such, these
are pictures that give a feel for the place, a feel for the performance, a hint of the
energy, the feel of a slam, but not a whit of the poetry. For the poetry you have to listen
to the poets.</p>
<p>This is the Nuyorican at the turn of the century. If you want to know what slam is now,
you have to go to the Nuyorican. In the same way that my reading "Aloud" didn't fully
prepare me for what I was about to see, so too with my photographs. They're a
document of a specific time and a specific place where a cast of characters spoke from
the stage and listened from the audience. This isn't about slam, its about being at the
Nuyorican. It's about me and the experiences I had and the sights I saw at the
Nuyorican.</p>
      </div>
      </div>

    </div>

  )
}

export default MichaelMeyers
