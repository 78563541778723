import './App.css';
import MainNav from './Nav';
import { Outlet, Link } from "react-router-dom"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <MainNav />
      </header>

      <div className="container">



        <div className="row">
          <div className="col-md-8">
            <Outlet />
          </div>
          <div className="col-md-4">
            <ul className='champ-photo'>
            <li>
                <Link to="/accuracy">Accuracy & Updates</Link>
              </li>
              <li>
                <Link to="/before-we-begin">Before We Begin</Link>
              </li>
              <li>
                <Link to="/peter-tribute">Tribute to Peter of the Earth</Link>
              </li>
              <li>
                <Link to="/sensibility">The Artistic Sensibility of
Slam</Link>
              </li>
              <li>
                <Link to="/visuality">Visual-ity of Nuyorican Poetry
Slam</Link>
              </li>
              <li>
                <Link to="/glossary">Glossary of Terms</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
