import React from 'react'

const Sensibility = () => {
  return (
    <div>
      <h1>The Artistic Sensibility of Slam</h1>
<p>This might be surprising to some... Artistic SENSIBILITY of Poetry Slam? I've realized that in looking
carefully at the elements of this form, a modern approach to creativity emerges. For better or worse
(decide for yourself) I believe the points below outline relevant ways that many artists today are
responding to social and cultural issues. Any one of these points could be dilated, but these are the
primary "ingredients" of the Poetry Slam Artistic Sensibility:</p>
<h2>1. Word Over Image</h2>
<p>There is a visual minimalism to Poetry Slam. It is about WORDS after all. There are no physical images
attached to any performance or poem and the message is delivered without props or scenery.</p>
<h2>2. Art For Social Change</h2>
<p>The totally idealistic belief that one person can make a difference in the mind of someone else is at the
root of Poetry Slam. For many, it becomes the chance to "answer back" a society asleep at the TV. And
while political Slam Poetry may not always be politically astute, Slammers often reflect current world
affairs and events with refreshing clarity.</p>
<h2>3. Anyone Can Play</h2>
<p>A willingness to soil elevated poetic hands with the sport of scores, decimal points and digits defines
the Poetry Slam. These "ugly" elements are exactly what makes a Slam accessible to a broad
audience. In turn, accessibility itself creates the possibility for social change or awareness. The judges
of the Slam are not usually experts at anything so it's a simple example of democracy in action. More
importantly, win or lose, this is risky business for any artist...leaving your "artistic" fate in the hands of a
stranger is dangerous business. Sensitive poet meets "insensitive" judge. Poetry Slam becomes the
modern caricature for this classic artistic relationship.</p>
<h2>4. Interdisciplinary Form</h2>
<p>Poetry Slam blurs all kinds of lines. Is it poetry or prose? Theater or commercial break, political or
private, a sporting event or a literary reading? Even though it's twenty years old by now, Slam remains
unsure of itself: is it the poem, the message or the performance that will win over the judges? And does
their opinion matter anyway? Perhaps the hybrid ingredients of Poetry Slam suggest a kind of artistic
evolvement (or devolvement!) of our cultural life, but that will certainly remain to be seen.</p>
<h2>5. Gender Awareness</h2>
<p>The issue of gender in society is a continual source of inspiration for Poetry Slammers and every single
woman involved in this scene is a strong individualist and leader in their own right without exception.
These are hardly complaints or rants alone; the poetry consistently becomes a description of gender
struggle and personal examination of the larger social framework we all live in.</p>
<h2>6. Questioning the Authority</h2>
<p>Like all good "movements" of one kind or another, Poetry Slam questions the authority of everything
that came before it. Sure, sometimes it asks the wrong questions, sometimes its sounds predictable,
but nonetheless, it remains responsive and reflective of the changing world around us.</p>
<h2>7. Reaching for the Spiritual</h2>
<p>The best moments of Poetry Slam are deeply personal expressions of an inner search. These are
expositions of universal longings that defy time and place and bring the audience to an elevated yet
common ground. These moments must be experienced; they are difficult to describe unless you stand
witness and participate. At the height of the best Poetry Slam performance, the poet is a true medium,
the medicine woman/man at the center of the crowd, conjuring up a universal and transcendent
experience for everyone present.</p>
- Clare Ultimo, 2007
    </div>
  )
}

export default Sensibility
