import React from 'react'
import { Link } from 'react-router-dom'

const KahlilalMustafa = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/khalil_amustafa.jpeg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Khalil Amustafa</h1>
      <h2 className='sub-head'>2002 Nuyorican Grand Slam Champion</h2>
    <p>Kahlil Almustafa is the People’s Poet, whether for a mass rally of hundreds of
people, a nightclub, church, university or a backyard family reunion. He uses the
stage & page to speak the truth of the people. almustafa is the author of two
collections of poetry: "Grandma's Soup" (February 2001) and "I'm Crying
Everyone's Tears" (August 2002) which has sold more than 5,000 copies
internationally. almustafa recently released his highly-anticipated debut CD
"CounterIntelligence" (May 2006). He is currently working on a collection of
poems "Chivalry is Dead", a conversation on gender, sexism, masculinity and
romance.</p>
<p>"kahlil almustafa’s poetry has a mixture of content and soul. It comes out as
powerful in its message as it does in its style."
- Ras Baraka, Deputy Majority Leader of Newark</p>
<p>almustafa has opened for and shared the stage with Amiri Baraka, Sonia
Sanchez, Manning Marable, dead prez, Floetry, Dilated Peoples, Motown
Recording Artist Donnie and tours regularly with the live hip-hop/rock band GAME
Rebellion. He has been a featured poet at the Geraldine R. Dodge Poetry
Festival, the C-Span televised "March to End Occupation in Iraq," Columbia, NYU,
Indiana, Yale, Wesleyan, Northwestern, Princeton, the International African Arts
Festival, the Harlem Book Festival, the Afrikan Poetry Theatre, the Baltimore Arts
Festival, Pacifica Radio, the National Black Writer's Conference, Afro-Punk
Liberation Sessions and many more.</p>
<p>"The words of kahlil almustafa are both fierce and loving, chilling yet warm, a
reminder that poetry goes beyond spoken word touching not only the ears, but the
heart and the soul. His work possesses an integrity reserved for the truly gifted,
one that reminds the elders and the ancestors that we are still here, still fightingwith
genuine spirits like kahlil on the frontlines."
- Toni Blackman, Author Inner-Course/US Hip-Hop Ambassador</p>
<p>In addition to performing and writing, almustafa is a dedicated educator. He
conducts regular poetry workshops at universities, schools, community
organizations and prisons. Some of his workshops include Poetry Is a Necessity,
Poetry in Our Communities, Metaphorically Speaking and Poetry for Social
Change. He also conducts CounterIntelligence workshops designed to transform
the way participants use language and frame their political, social and historical
contexts.</p>
<p>almustafa is currently a performer and speaker on the Hip-Hop Is Dead Tour
spearheaded by Rosa Clemente of the Malcolm X Grassroots Movement ” the
follow-up to the Speak Truth to Power tour (2003-05). The tour also features M-1
and Stic.man from dead prez, Immortal Technique, Jennifer "J-Love" Calderon, cofounder
of the B-Boy Summit, Boots Riley from The Coup, Marinieves Alba, cofounder
of the International Hip-Hop Exchange (IMX) and Dawn-Elisa Fischer, cofounder
of the National Hip-Hop Political Convention. The tour is dedicated to
raising consciousness about identity, the war, the role of hip-hop, sexism,
reparations and political prisoners.</p>
<p>See also <Link to="/grand-slam-finale/2002">Grand Slam Finale/2002</Link> and <Link to="/national-teams/2002">National Teams/2002</Link>.</p>
<p>Photo by Jodi Berenblatt from the 2002 Grand Slam Finale at the Nuyorican.</p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/kzmhvN2YgUs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default KahlilalMustafa
