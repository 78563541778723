import React from 'react'
import { Link } from 'react-router-dom'

const OpenKeith
 = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className="img-fluid champ-photo" src="/hosts-keith.jpg" alt="" />
</div>
    <div className="col-md-8">

    <h1 className='title-bold'>keith roach</h1>
    <h2 className='sub-head'>Open Room Host: 1992 - 1995</h2>
    <h3>Addendum/ 2022:</h3>
  <p>We lost this Slam legend in 2017. Keith's passing was a great loss to our community but his legacy remains strong. His last poetry stop was the group known as Capicu. Look them up. Nuyorican Poetry Slam owes a great debt of gratitude to this talented poet, host & curator.</p>
<p>Originally, the Open Room host at the Cafe from 1992 until 1995, Keith began his run as SlamMaster at probably the most crucial point in the development of Nuyorican Slam. The list of poets he has affected and influenced reads like a "who's who" of definitive slammers. Alix Olsen, Steve Colman, Roger Bonair Agard, Taalam Acey, Felice Belle, Nathan P,...the list is long. Keith was an unusual combination of poet, literary observer, and community builder. He was a guiding force behind the Nuyorican Slam scene that we have come to know today.</p>
<p>He is fairly legendary in the slam community, and has played a role in the National scene as well, accompanying the teams from 1994-1999, (except for 1996) and was right there when his National Team won the competition in Austin in 1998.</p>
<p>He is the author of "The World Changes at the Expense of Black People"
published by Clique Calm Books, and was producer and host of "Eadon's
Place", the only 24/7 Poetry/SpokenWord/IndieArtists internet broadcast around. His show is called "Conversations".
</p>
<p><a href="/01.NYFAKeith.pdf"> Read "The Culture of Slam: Creating Community" by Keith Roach, published in the NYFA Journal 1999.
</a></p>
<p><a href="/02.PoCalArticle.pdf">Read an article written about Keith as SlamMaster in the NYC
Poetry Calendar, circa 1999.
</a></p>
<p>See <Link to="/slam-masters">Friday Night SlamMasters</Link> <br /> <Link to="/national-teams/1998">1998 National Team</Link>.</p>
<p>Bio Photo by Jay Ward.</p>
<div className="row">
<div className="col-md-6">
  <img  className="img-fluid" src="/keith-inner.jpg" alt="" />
</div>
<div className="col-md-6"><p className='caption'>SlamMaster Keith in a
pretty happy mood
with the First Place
trophy won by his
1998 Team at the
National Poetry Slam.</p></div>
</div>
    </div>

    </div>

  </div>
  )
}

export default OpenKeith

