let photographers = [
{
  name: "Michael Meyer",
  picture: "photos-michael.jpg",
  slug: "michael",
},
{
  name: "Syreeta McFadden",
  picture: "photos-syreeta.jpg",
  slug: "syreeta",
},
{
  name: "Clare Ultimo",
  picture: "photos-clare.jpg",
  slug: "clare",
},
{
  name: "Herb Brooks",
  picture: "photos-herbs.jpg",
  slug: "herb",
},
{
  name: "Thomas Poole",
  picture: "photos-thomas.jpg",
  slug: "thomas",
},
];

export function getPhotography() {
return photographers;
}
