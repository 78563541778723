import React from 'react'

const Visuality = () => {
  return (
    <div>
      <h1>Visual-ity of Nuyorican Poetry Slam</h1>
<p> Using black and white photographs as the basis of my aesthetic approach throughout "Verbs on
Asphalt" reflects visual references seen in early jazz music. The limitations of technology used during
the early popularity of jazz (when black and white photographs were not a choice but a necessity)
created what we think of now as the dramatic visual appeal of the jazz genre. The color simplicity of this
project refers to this early cultural period in American popular music and puts a greater emphasis on
content and information. For example, until recently, and before the advent of the internet, people got
their breaking news, their "real" information, only in black and white” as in a newspaper.</p>
<p>
I see this simplicity as a strong visual tool. It suggests that the information contained in the image is
more important than its entertainment value. Besides the practical considerations inherent in this project
(many of our archival photographs are originally in black and white), I see this approach as an
appropriate response to the historic content of the subject.</p>
<p>
In the sophisticated world of the internet, few websites dare not to use color extensively. Limiting my
approach with color creates a highly unique visual attitude that reflects the grassroots development of
Poetry Slam at the Nuyorican Poets Cafe.
- Clare Ultimo</p>
    </div>
  )
}

export default Visuality
