import React from 'react'
import { Link } from 'react-router-dom'

const regEGaines = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/reg-gaines.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Reg E Gaines</h1>
      <h2 className='sub-head'>1991 Nuyorican Grand Slam Champion</h2>
    <p>Reg is author of three books, "24/7/365", "Head Rhyme Lines", "The Original
    Buckwheat", and "2 B blk & wrt." on B.A.G. Lady Press. His poetry can be found
    in the anthologies "Word Up", "Aloud, Voices From The Nuyorican Poets Cafe",
    "The Outlaw Bible of American Poetry" and "Bum Rush The Page". Reg's own B.
    A.G. Lady Press has released "Brick City Blues", the result of a 16-session poetry
    workshop he led as Writer-N-Residence at The New Jersey Performing Arts
    Center in 2001.</p>
    <p>In 1993, Reg appeared on MTV Spoken Word Unplugged, the Fightin' Wordz
    Series and headlined the MTV Free Your Mind Spoken Word Tour. He has
    performed his poetry on The Arsenio Hall Show, The Jon Stewart Show,
    MSNBC's Edgewise and Def Poetry on HBO. In 1994, Reg's "Please Don't Take
    My Air Jordans" was the first poetry CD released by Mercury Records. His
    Countee Jail Records has released Show & Tell, Hush Project, and film scores
    including Senior Year, a 13-episode series for PBS.</p>
    <p>Reg is a two time Tony Award nominee, a Grammy award nominee and a Bessie
    Award winner for Best Book/Lyrics for "Bring In Da Noise Bring In Da Funk". His
    jazz musical, "Other Aspects", was the 2003 Downtown Urban Theater Festival
    (NYC) Best Play Award winner. Reg served as artistic director for the 2004 DUTF
    and his play, "Murphy's Law", has been shown at The Cherry Lane Theater in
    New York.</p>
    <p>Reg's theater directing credits include his own "Other Aspects, Blak" by Marcella
    Goheen, "Mongo Affair"; Aaron Davis Hall's 25th Anniversary Celebration with
    Miguel Algarin and Amiri Baraka; Regie Cabico's "Straight/Out", Maarteen van
    Hinte's "OJ-Othello", "Sadico" by Aileen Reyes and The Brick City Playas' "Brick
    City Blues". His film directing credits include "Underground Voices" starring Saul
    Williams and Jessica Care Moore.</p>
    <p>Reg can be seen in "If Trane Wuz Here" with Savion Glover and saxophonist
    Matana Roberts. The piece pays homage to the music of John Coltrane and
    began a 10-city tour in celebration of Black History in February 2006. Reg is
    Writer-N-Residence for San Diego City College's City Works Literary Magazine
    Volume 12 and is writer and director for The Instant Theater Project which has
    performances in July 2005 at The Penn @ Sixth Theater in San Diego.</p>
    <p>Reg developed the "Theatricalization Of Poetic Expression" workshops at scratch
    theatrical (NYC) and has facilitated workshops around the country for the New
    York City Hip-Hop Theater Festival in conjunction with Youth Speaks in San
    Francisco. Bio is from http://www.theworldstage.org/popwe.
    html#fall17of20</p>
    <p>See <Link to="/national-teams/1992">National Teams/1992</Link>.</p>
    <p>Photo taken from:
    http://www.artistdirect.com/nad/music/artist/card/0,,433735,00.
    html</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/wOR7seVjHuc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

      </div>
    </div>
  )
}

export default regEGaines
