import React from 'react'
import { Link } from 'react-router-dom'

const williePerdomo = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/willie.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Willie Perdomo</h1>
      <h2 className='sub-head'>1991 Nuyorican Grand Slam Champion</h2>
    <p>Willie Perdomo is the author of "Where a Nickel Costs a Dime" and "Smoking
    Lovely", which received a PEN America Beyond Margins Award. He has also
    been published in The New York Times Magazine and Bomb. He is the author of
    a "Visiting Langston, a Coretta Scott King Honor Book for Children" and has been
    featured on several PBS documentaries including Words in Your Face and The
    United States of Poetry as well as HBO's Def Poetry Jam and BET's Hughes'
    Dream Harlem. He has received a Woolrich Fellowship in Creative Writing at
    Columbia University, New York Foundation for the Arts Poetry and Fiction
    Fellowships, and a 2006 Urban Artist Initiative/ New York City grant. He is cofounder
    of Cypher Books.</p>
    <p>See <Link to="/national-teams/1991">National Teams/1991</Link></p>
      </div>

      </div>
    </div>
  )
}

export default williePerdomo
