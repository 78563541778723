import React from 'react'

const NatTeam2000 = () => {
  return (
    <div>
    <h1>2000 Nuyorican National Slam Team</h1>
    <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/2000.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">

<p>Coach: Kirk Nugent</p>
<p>Photo by Syreeta McFadden<br />
<a href="/2000Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2000-bryonn.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>bryonn bain</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2000-thema.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>thema bryant (Alternate)</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2000-jamal.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>jamaal st. john</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2000-helena.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>helena d. lewis</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2000-tehut.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>tehut-nine</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam2000
