import React from 'react'

const ShutUpShelly = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-shelly.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1 className='title-bold'>shut-up shelley</h1>
      <h2 className='sub-head'>Wednesday Night Slam Open Host circa 1994</h2>
    <p>Despite the fact that Shut-Up Shelley has most likely not been shutting up lately,
current information remains unavailable. She was a fantastic Poetry Slam host,
pretty wild and very theatrical. You will see her in the film "SlamNation" as the
National Team coach, along with Beau Sia, muMs, Saul and Jessica. She was
also probably the most "decorative" and beautiful Friday Night Slam Scorekeeper
we ever had!</p>
<p>When Ava Chin, in "TimeOut/NY" came to see the Wednesday Night Slam, she
wrote "Shut-Up Shelley's slam is a carnival of comedic monologue and narrative
poetry. " That was pretty much what it was like – Shelley entertained the
audience! Her poetry appears in "Verses That Hurt: Pleasure and Pain from the
PoemFone Poets" (St. Martin’s, 1997), along with the poetry of Allen Ginsberg.</p>
<p>In 1979 his play, “A Very Butch Libido,” was banned by the apartheid regime in
South Africa. His poetry is anthologized in “Aloud: Voices from the Nuyorican
Poets Cafe,” “The Outlaw Bible of American Poetry,” “Revival: Spoken Word from
Lollapalooza,” “Poetry Nation,” and “Jane For A Day,” as well as on the CDs
“Family Matters,” “Nuyorican Symphony,” “Urbana Poetry,” and his own CD “Suck
My Poems.”</p>
<p>Two of her poems ("Fuck Art" and "Mantra') appear on http://www.angelfire.
com/goth/remorsefulpoetry/mantra.html, along with legendary poetic
personalities like Nicole Blackman, Penny Arcade and Sparrow. If you're out
there, Shelley, we'd love to hear what you've been up to! Email here.</p>
<p>Photo was taken from 1996 Grand Slam video footage by Tom Poole.</p>
      </div>

      </div>

    </div>
  )
}

export default ShutUpShelly

