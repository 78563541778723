import React from 'react'

const Kayo = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/kano.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Kayo</h1>
      <h2 className='sub-head'>1998 Nuyorican Grand Slam Champion</h2>
    <p>Brooklyn-born Kraal “Kayo” Charles is a Nuyorican Poets Cafe’s Grand Slam
Champion and one of the initial poets on HBO’s DEF POETRY. Kayo has been
featured on television in “Hughes Dreams Harlem” (a tribute to Langston Hughes)
on The Black Starz Network and “Bone Bristle - A Spoken Word Documentary,”
as well as at various venues, colleges and universities across the nation. He has
traveled abroad to perform in Portugal and Austria as part of the first European
Poetry Exposition. Kayo is the founding director of Urbintel Inc.. Most recent
theatrical productions are "The Male Ego" and "HerStory". Check out their site:
Urbintel.com</p>
    <p>Photo by Christopher Weil</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/w79gt6fUdNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default Kayo
