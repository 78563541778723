import React from 'react'

const BobHolman
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/host-bob.jpg" alt="" />
        </div>
      <div className="col-md-8">

      <h1 className='title-bold'>bob holman</h1>
      <h2 className='sub-head'>Founding Nuyorican SlamMaster until 1996</h2>
    <p>Recently dubbed a member of the "Poetry Pantheon" by the New York Times
Magazine and featured in a Henry Louis Gates, Jr. profile in The New Yorker,
Holman has previously been crowned "Ringmaster of the Spoken Word" (New
York Daily News), "Poetry Czar" (Village Voice), "Dean of the
Scene" (Seventeen), and “this generation’s Ezra Pound,” (San Francisco’s Poetry
Flash).</p>
<p>From Slam to Hiphop, from performance poetry to spoken word, Bob Holman has
been a central figure in the reemergence of poetry in our culture. The series he
produced for PBS, the United States of Poetry , features over sixty poets including
Derek Walcott, Rita Dove, Czeslaw Milosz, Lou Reed and former President Jimmy
Carter, as well as rappers, cowboy poets, American Sign Language poets, and
Slammers. USOP lives on as an anthology from Harry Abrams Publishers (in its
second printing), a home video from KQED, and soundtrack CD from Mouth
Almighty/Mercury Records, a label Holman co-founded. He has appeared widely
on TV: "Nightline," "Good Morning America," "ABC News Magazine," MTV's
"Spoken Word Unplugged," and "The Charlie Rose Show," among others. The
NEA has announced major preproduction support for his new poetry media
project, the World of Poetry (worldofpoetry.org), the world’s first digital poetry
anthology.</p>
<p>Holman’s latest collection of poems, A Couple of Ways of Doing Something , was
published in 2003 by Art of This Century/Pace Editions, and was first exhibited at
the Peggy Guggenheim Museum during the Venice Biennale, and will be released
by Aperture in 2006. The book consists of twenty daguerreotypes by Close and
twenty praise poems by Holman, one for each of the artists Close photographed.
Also in 1993, Tin Fish published his translations (from Chinese, with the author) of
Zhang Er’s Carved Water . His selected, The Collect Call of the Wild , from Henry
Holt was proclaimed "the first poetic drop-kick into the new millennium" by Next
magazine and “Impressive (to say the least)” by Robert Creeley. He co-edited
Aloud! Voices from the Nuyorican Poets Cafe (also from Holt), winner of the
American Book Award, having helped reopen the Cafe in 1989, where he ran the
infamous Poetry Slams through 1996. Holman’s first CD, In With The Out Crowd ,
was produced by needle-drop wizard Hal Willner. Backed by Chris Spedding,
Wayne Kramer, and Bobby Neuwirth, the album moves from rock to country to
ballad, shot through with urgent humor and what can only be called, “poetry.” Lou
Reed says it is “an astonishing achievement.”</p>
<p>Bob fronts poetry into daily life by all means: as Curator for the biennial People’s
Poetry Gathering (peoplespoetry.org) in NYC, he brings together oral poetry
traditions from Africa (griot), Brazil (cordel), NYC (Braggin Rites), Mexico (decima)
and other countries with hobo poets (U. Utah Phillips), cowboys (Wally McRae),
dub (Linton Kwesi Johnson), blues poets (Sterling Plumpp) and rockers (Ani
DiFranco) and the proverbial others in a 3-day “populist bacchanal” (Stanley
Kunitz) that attracted 12,000 people in April 03 and will reappear May 5-7, 2006.
His current work for the Gathering is focused on the poetry of endangered
languages. He is also poetry guide at About.com (poetry.about.com), consistently
a banner site with 7,000 “Museletter” subscribers and 35,000 hits/week.</p>
<p>He won three Emmys over six seasons producing Poetry Spots for WNYC-TV,
received a Bessie Performance Award, has twice been Featured Artist at the
Chicago Poetry Video Festival and won International Public Television Awards for
USOP and Words in Your Face, a production of the PBS series "Alive TV.” He
produced the reading series rAP mEETS pOETRY with Bill Adler, which resulted
in the "Fighting Wordz" intersticials on MTV. Holman, Adler, and Sekou Sundiata
created “the world’s first” poetry record label, Mouth Almighty/Mercury Records
(1995-8) -- releases included Sekou Sundiata, Flippin the Script: Rap Meets
Poetry, Allen Ginsberg’s The Ballad of the Skeletons, with music by Paul
McCartney and Philip Glass, young poets Wammo, Michele Serros and Beau Sia;
major voices Maggie Estep and The Last Poets and Hal Willner’s Edgar Allan Poe
feast, with stories and poems read by Iggy Pop, Dr. John, Christopher Walken
and others, and the definitive 4-CD William Burroughs box, nominated for a
Grammy.</p>
<p>In 2001, NYU’s Fales Library acquired his Poetry Media Library, calling it “the
finest of its kind,” and has recently received NEA funding to catalogue and digitize
the collection. In 2004-5, Holman was Poet in Residence at WNYC Radio , New
York’s NPR station, reading a poem, generally contemporary and related to the
day’s events, on Morning Edition every week or so; he is also that station’s guide
to spoken word recordings, appearing monthly on Sound Check. Work has begun
on a radio series, “Live from the Bowery Poetry Club!”</p>
<p>Holman was founding editor of the NYC Poetry Calendar (1977), curated reading
series at St. Marks Church (he worked at the Poetry Project for seven years), the
Whitney Museum, the Public Theater, and other locales. He has toured the world
with his "amazing traveling word show," and is Artistic Director of the touring
company Real Live Poetry. He is a firm believer in the United States of Poetry,
with recent stops at National Poetry Slams (he coached the 1997 championship
Mouth Almighty team), the Annual Cowboy Poetry Gathering in Elko, Nevada, and
the American Sign Language Literary Conference in Rochester, New York. He
has been awarded three NY Foundation for the Arts Fellowships in Poetry, been
funded by the NEA, New York State Council on the Arts and the Lannan
Foundation. In 2004, he became a Def Poet, appearing on HBO’s Def Poetry
Jam. In 2000, he hosted the International Poets Cabaret at the Frankfurt Book
Fair where he also premiered his SemiCento, a polyglot poem gathered line-byline
from poets around the world, and he performs regularly with David “Pere Ubu”
Thomas in the punk opera, Mirror Man. He presented his paper, “The
Reemergence of the Oral Tradition in the Digital Age” at the Pan-African Literature
and Languages Conference in Asmara, Eritrea; and is the recipient of Curbstone
Press’s “Honored Poet” award. In summer 2002, he was the Featured Artist at the
First International Zebra Poetry Film Festival in Berlin, screening USOP, judging
the competition, and performing his multimedia poem “@the Cafe” with a live DJ.
In 2003 he was awarded the Barnes and Noble “Writers for Writers Award” by
Poets & Writers, and represented the USA in Poetry Africa in Durban. In 2004, he
performed in Warsaw, teaching a workshop in performance poetry, and in
Denmark, at the huge Roskilde Rock Festival.</p>
<p>From 1998-2002, he was Visiting Professor of Writing and Integrated Arts at Bard
College , where he adapted and directed John Ashbery’s book-length poem, Girls
on the Run and SUDDEN EKPHRASIS!: The Poetry of Robert Kelly. Since 2003,
he has taught Exploding Text: Poetry Performance at Columbia University, as a
graduate course in the School of the Arts.</p>
<p>Located at the corner of art and entertainment, the opening in fall 2002 of the
Bowery Poetry Club “at the foot of First” in downtown Manhattan, is dream
realized. This seven day a week poetry place is “a beacon on the Bowery,”
according to the New York Times. Bob Holman has spent a life in poetry, and the
Club, with a coffee shop, bookstore, bar and performance space, is its
physicalization. A non-profit, Bowery Arts & Science, Ltd., works with the Club for
educational, outreach, and technological pursuits. Open door twixt art and
technology, content and media. In short, the Bowery Poetry Club is poised to be
the nexus of the new poetry for the new century. Last year Bowery Arts & Science
and The Open Center launched Study Abroad on the Bowery, a certificate course
in applied poetics with Anne Waldmanas Co-Artistic Director with Bob. Recent
faculty include: Amiri Baraka, Jonathan Lethem, Jessica Hagedorn, Paul Auster,
Samuel Delany and Sapphire. Spring 06 marks the beginning of collaborative
programming for SAB! with the CUNY Grad Center; the summer session is
centered on the annual HOWL Festival. This bio is from Bob's website. Please
see the site for updates on Bob's latest books and projects. You will also want to
check out Bob's poetry joint, The Bowery Poetry Club.</p>
<p><a href="http://bobholman.com">Bob Holman's Website</a></p>
<p><a href="http://bowerypoetry.com">The Bowery Poetry Club</a></p>
      </div>
      </div>

    </div>
  )
}

export default BobHolman

