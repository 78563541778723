import React from 'react'
import { Link } from 'react-router-dom'

const FeliceBelle
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-felice.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1 className='title-bold'>felice belle</h1>
      <h2 className='sub-head'>Nuyorican SlamMistress: October 1999 until December 2002</h2>
    <p>Felice Belle is a poet and playwright. She holds a BS in Industrial Engineering
from Columbia University and an MA in Creative Writing and Performance from
New York University's Gallatin School of Individualized Study. In 2006, she was
selected as one of the Real Hot 100, an annual list that celebrates positive young
women who are breaking barriers and making a difference in their communities</p>
<p>Felice has performed at PS 122, Town Hall, the American Museum of Natural
History, the Apollo Theater, and various colleges and universities throughout the
United States. Her poetry has been published in "Roots and Culture", "SLAM",
"DrumVoices Revue", "Longshot", and "Bum Rush the Page".</p>
<p>Her short, "Joy in Repetition", was featured in the Hip-Hop Theater Festival
(2002). Her full-length play, "Tell the Truth Lying", premiered as part of the ROAR
Theater Festival at the Nuyorican (2003) and her play "Definately" received a
staged reading at the Real Theatre Works Fresh Voices Festival (2004). She also
created original poetry for the Vineyard Theatre production of "History of the
Word" (2007). She writes the Stakes is High column for the blog Crucial Minutiae. More about Felice here: <a href="http://www.felicebelle.com">www.felicebelle.com</a></p>
<p>See <Link to="/national-teams/1999">National Teams/1999</Link>.</p>
<div className="row">
  <div className="col-md-6">
    <img className="img-fluid" src="/felice-inner.jpg" alt="" />
  </div>
  <div className="col-md-6">
    <p className='caption'>The "Farewell Felice" collectable
Nuyorican SlamMaster's Magnet,
given out on her last night as
SlamMistress.</p>
  </div>
</div>
      </div>

      </div>

    </div>
  )
}

export default FeliceBelle

