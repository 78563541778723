import React from 'react'

const PeterCalling = () => {
  return (
    <div className="row">
          <h1>The Calling</h1>
<p>by Peter of the Earth</p>
<p>
Searching for the missing pieces that is me<br />
I want to know what it’s like to be out from under my own thumb<br />
To run to places I dream about when I’m sleeping<br />
When I’m awake<br />
To wake<br />
Up<br />
<br />
I’d be floating on a sea<br />
I’d be free<br />
Like the breeze on my back<br />
Be slick<br />
Tight<br />
Right<br />
I’d swim like Dolphins till my skin stretches and wrinkles<br />
Feels like pins and needles<br />
Piercing my fins till it hurts<br />
But I don’t want to stop ‘cause I like it<br />
Get all caught up in it<br />
Like the sun<br />
Washing me through my window at noon<br />
Warming me in my bed when I’m not working<br />
Like the music from y alarm clock that gives me no choice but to rise and dance<br />
Lift the air up from under my feet and fly<br />
To see out of my third eye and speaks<br />
Words kept secret and silent for generations<br />
By grandmothers who told you no to unleash them except for when you need<br />
Or when you get the spirit or<br />
Some spirit gets you<br />
<br />
Words<br />
<br />
That open up the way to worlds long past and<br />
Those you just create by thinking them<br />
And when you sleep their people come to meet and great<br />
Eat and commune with –<br />
<br />
Non-sequitor—<br />
Do you remember when we were gods?<br />
<br />
I’m talking about that power we’ve always had but chose<br />
No choose<br />
To ignore no longer<br />
To grow stronger with the power to unleash our own Orisha and<br />
The knowledge needed to maintain it<br />
To open my lips and spit fire or<br />
Breath life into someone with a kiss –<br />
Wait –<br />
I already have that gift<br />
<br />
But to have it refined<br />
With all the gifts that are mine<br />
To know what it’s like to be air or rain<br />
Who seductively wrap and stickily drip down strong trunks and<br />
Green leaves of trees, soft blossoms of flowers<br />
Who open themselves up to take them in<br />
Then turn them out into creation<br />
For us to live<br />
And breathe<br />
And drink<br />
<br />
Think of all there is to be done this life around<br />
To raise my ancestors from the ground at the rapture and<br />
Take them home now that I have come into being<br />
My mothers<br />
Who scrubbed windows and floors<br />
Forced behind doors to do things we were told were unspeakable<br />
To speak of such things<br />
To raise kings from dark Christian cemeteries and return their bodies to sarcophagi<br />
Or offer them to nature so their spirits may fly<br />
To look in the face of our impotent captors<br />
And forgive<br />
Forgive<br />
And cry when it is finally over<br />
So we can laugh once back on the path we were one when the world was pagan ‘cause<br />
We were of the earth who birthed<br />
And gave us nourishment<br />
<br />
To evolve to the next step of our continuum<br />
And help those still struggling through lifetimes<br />
To unlock my mind and know what I’m supposed to<br />
Relieve the pain that torments and drains my spirit<br />
I can no longer ignore that I have the calling<br />
<br />
I have the calling<br />
<br />
And with each breath I fall deeper under the spell that derives from this incantation<br />
It is Word<br />
And god<br />
In the beginning I was<br />
And Am<br />
And the things I see<br />
That I desire and need<br />
I will receive<br />
And my will will be free<br />
Once I give up my attempts of control of others<br />
Of me<br />
‘cause we all want to be in this creation<br />
<br />
I want to receive revelations that will make rivers turn fast onto deserts<br />
And rejuvenate them to jungles of Eden<br />
Or simply leave them if that be their freedom<br />
But to see them<br />
And be them<br />
And then<br />
I can say I was human and <br />
move on<br />
<br />
Salvation Song…<br />
<br />
The calling<br />
<br />
<br />
<br />
 – Peter of the Earth<br />
<br />
</p>
    </div>
  )
}

export default PeterCalling
