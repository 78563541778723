import React from 'react'

const OpenRich
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-anthony.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>anthony morales</h1>
      <h2 className='sub-head'>Open Room Host: 2006</h2>
    <p>Anthony Morales is a 25-year-old Nuyorican poet/speaker/teacher/actor from the
Bronx who has delivered lectures, conducted writing workshops, and performed
his writings throughout the United States. In May 2003, he received his Master’s
Degree in English Education from Columbia University’s Teachers’ College. He
received his Bachelor’s Degree in English and Latino Studies from Columbia
College in May 2002.</p>
<p>Anthony Morales is in the tradition of Nuyorican poetry and literature. He began
writing and performing poetry at the age of 14 while attending Phillips Academy in
Andover, Massachusetts. At the age of 16, he published his first chapbook, “U-N-Iverses”.
To date, his work has garnered widespread acclaim from his peers as
well as distinguished writers. He has shared the stage with talented artists such
as Martin Espada, Gayle Danley, Roger Bonair Agard, Kenneth Koch, Suheir
Hammad, Willie Perdomo, Flaco Navaja, Kahlil Almustafa, Celena Glenn and La
Bruja. He has also opened for Hip-Hop acts like Dilated Peoples and Immortal
Technique.</p>
<p>In 2000, Mr. Morales received the Van Renselaar Poetry Prize from the Columbia
English Department while editor of Roots and Culture Magazine. In 2001, he was
named Fresh Poet by the Nuyorican Poets’ Cafe. In January 2002, he appeared
on National Public Radio as a part of a panel discussion on the film, “Pinero” and
was featured in the New York Times article entitled, “The Poetry of the Nuyorican
Experience” (Jan 2, 2002). In the same year, Anthony let the world know where
he is from on HBO’s Def Poetry Jam by performing “Story Avenue” (2nd Season).
In 2003, he was awarded the Bronx Writers’ Center’s Van Lier Literary Fellowship,
which gave him the chance to teach adult writing workshops. His work has been
featured in BLU Magazine (Puerto Rico Libre), MiGente.com and the 10th annual
Muevete Boricua Youth Conference CD.</p>
<p>Anthony Morales is currently touring schools, universities, community centers, and
spoken word venues throughout the United States. He plans to publish “Story
Avenue” ” a multi-genre collection of poetry and short stories.</p>
<p>Please see <a href="http://anthonymorales.blogspot.com">Anthony's Blogspot</a> for updates on new projects and appearances</p>
<p>Photo by Clare Ultimo.</p>
      </div>
      </div>

    </div>
  )
}

export default OpenRich

