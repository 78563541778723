import React from 'react'
import { Link } from 'react-router-dom'
const MaydaDelValle = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/mayda.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Mayda Del Valle</h1>
      <h2 className='sub-head'>2001 Nuyorican Grand Slam Champion</h2>
    <p>Mayda del Valle has taken the national spoken word scene by storm. In a few
short years she has gone from being virtual unknown to becoming one of the most
accomplished and respected voices on the scene, proving her skill as both a
writer and performer. With her signature “spitfire”? delivery, Mayda recounts
deeply personal narratives steeped with the cadence and beats of Hip Hop.</p>
<p>A proud native of Chicago’s South side. Mayda began performing her own writing
while in high school and relocated to New York City after graduating from Williams
College with a B.A. in Art. One of Mayda’s first stops was the legendary Nuyorican
Poets Cafe. In spite of never having peformed poetry competitively Mayda won
the 2001 Nuyorican Grand Slam Championship and went on to the National
Poetry slam in Seattle that August. There she won the individual National poetry
Slam Championship, becoming the first poet from the Nuyorican, the youngest
poet ever, and the first Latino person to win the title. Her quick rise to success
was no accident. Fresh off her success in the poetry slam scene, Mayda was
chosen to perform for the first season of the HBO series Russell Simmons
Presents Def Poetry, and has since been featured on 6 episodes.</p>
<p>She was an original cast member and writer of the critically acclaimed Tony award
winning production of Russell Simmons Def Poetry Jam on Broadway, and was
also part of the national tour of Def Poetry. In addition she was a cast member of
the Declare Yourself Spoken Word Tour, a national, non-profit, non-partisan
campaign produced by Norman Lear to encourage young voter participation in the
2004 elections.</p>
<p>A talented actress, Mayda refuses to limit herself to the world of poetry. Mayda
wrote and performed her one-women show, The Insecurites of an Eagle at NYC’s
3rd Annual Downtown Urban Theatre Festival in 2004 and won an Audience
Favorite Award. She has also been featured in a number of publications including
El Diario, Urban Latino, Mass Appeal Magazine. Latina Magazine, Trace, The
source and the New York Times. She has performed and facilitated workshops
and discussions at colleges and universities across the country, and has graced
the stages of The Public Theatre, Joe’s Pub, the Williamstown Theatre Festival.
And Lincoln center in a special tribute to Tito Puente.</p>
<p>Most recently she has appeared in several films: Sp!t a documentary that explores
the world of Spoken Word, Race is the Place a flim exploring the intersection of
performance and race featured on PBS, as well as the independent short
Speechless directed by Blu Fox. She was also a featured voice on a mixtape DJ
J. Period’s "The Official Best of The Root’s" mixtape.</p>
<p>Mayda continues to move audiences everywhere she peforms with her raw
honesty and passion. Hers is a unique, powerful voice able to fuse profoundly
personal and thought provoking work with the rhythm and pulse of Hip Hop. Bio
and photo are from maydadelvalle.com. Please see Mayda's site for updates
on new projects and appearances. <a href="https://www.maydadelvalle.com">https://www.maydadelvalle.com</a></p>
<p>"a wild spirit, defiant and determined and thrilling" -The Boston Herald</p>
<p>"Mayda del Valle is simply the most supreme of the young poets on the scene" To
hear her is to understand the energy of poetry." -Bob Holman</p>
    <p>See <Link to="/grand-slam-finale/2001">National Teams/2000</Link> and <Link to="/national-teams/2001">National Teams/2001</Link>.</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/41Cv2IuPDi8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default MaydaDelValle
