import React from 'react'

const GrandSlam2001 = () => {
  return (
    <div>
    <h1>2001 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: FELICE BELLE</p>
<p>Poets submitted these bios to me for the 2001 Finale Program, so they may not reflect projects and
accomplishments since that time. <a href="#poster">See original 2001 Grand Slam Finale Program </a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2001-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>mayda del valle</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2001-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>heru</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2001-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>natural</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2001-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>greg purnell</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2001-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>shihan</p>
        </div>
        <img id="poster" src="/2001Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam2001
