import React from 'react'
import { Link } from 'react-router-dom'

const Media = () => {
  return (
    <div>
      <h1>Verbs Media</h1>
<p>The Slam videos here were created from footage by <Link to="/photography/thomas">Tom Poole</Link> & <Link to="/photography/herb">Herb Brooks</Link>. Videos edited and created by Clare Ultimo.</p>

<p><b>"Nuyorican Nostalgia"</b> Video below taken from early footage by Tom Poole.
</p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/BgMBfqrx0Sw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<h2>Original Nuyo Slammasters</h2>
<ul>
<li>
    <a href="https://youtu.be/Q_bf33-UPAI" target="_blank" rel="noreferrer">Keith Roach</a>
  </li>
  <li>
    <a href="https://youtu.be/W5sXQ4jewCU" target="_blank" rel="noreferrer">Bob Holman</a>
  </li>
</ul>
<h2>Grand Slam Champions</h2>
<ul>
  <li>
    <a href="https://youtu.be/wOR7seVjHuc" target="_blank" rel="noreferrer">Reg E Gaines</a>
  </li>
  <li>
    <a href="https://youtu.be/riPdKmp0QhQ" target="_blank" rel="noreferrer">Regie Cabico</a>
  </li>
  <li>
    <a href="https://youtu.be/w79gt6fUdNc" target="_blank" rel="noreferrer">Kayo</a>
  </li>
  <li>
    <a href="https://youtu.be/om6jXKIB2hI" target="_blank" rel="noreferrer">Kirk Nugent</a>
  </li>
  <li>
    <a href="https://youtu.be/efWPhTI0fkY" target="_blank" rel="noreferrer">Bryonn Bain</a>
  </li>
  <li>
    <a href="https://youtu.be/41Cv2IuPDi8" target="_blank" rel="noreferrer">Mayda del Valle</a>
  </li>
  <li>
    <a href="https://youtu.be/kzmhvN2YgUs" target="_blank" rel="noreferrer">Kahlil Amustafa</a>
  </li>
  <li>
    <a href="https://youtu.be/2QzB0UYUO9k" target="_blank" rel="noreferrer">Daniel Beaty</a>
  </li>
  <li>
    <a href="https://youtu.be/fUFk-CLwje0" target="_blank" rel="noreferrer">Aja Monet</a>
  </li>
</ul>
<h2>National Team Members</h2>
<ul>
  <li>
    <a href="https://youtu.be/-SjRy5pF7Ck" target="_blank" rel="noreferrer">Beau Sia</a>
  </li>
  <li>
    <a href="https://youtu.be/4sg7MfPrQO4" target="_blank" rel="noreferrer">Steve Colman</a>
  </li>
  <li>
    <a href="https://youtu.be/spYQakC_0Es" target="_blank" rel="noreferrer">Jamaal St. John</a>
  </li>
  <li>
    <a href="https://youtu.be/dvp2hAr_haE" target="_blank" rel="noreferrer">Carlos Andres Gomez</a>
  </li>
  <li>
    <a href="https://youtu.be/nDtY37Er_h4" target="_blank" rel="noreferrer">Kenaya</a>
  </li>
  <li>
    <a href="https://youtu.be/DbKq6MvsXDw" target="_blank" rel="noreferrer">Jive Poetic</a>
  </li>
  <li>
    <a href="https://youtu.be/r1BuAj4OlV8" target="_blank" rel="noreferrer">Desiree</a>
  </li>
  <li>
    <a href="https://youtu.be/TTA2l1VGyVE" target="_blank" rel="noreferrer">Brady</a>
  </li>
  <li>
    <a href="https://youtu.be/qdyQr6V1nK4" target="_blank" rel="noreferrer">Big Mike</a>
  </li>
</ul>
<h2>SlamMasters As Slammers</h2>
<ul>
  <li>
    <a href="https://www.youtube.com/watch?v=NPi8eVYnD_8" target="_blank" rel="noreferrer">Felice Belle</a>
  </li>
  <li>
    <a href="https://youtu.be/6EJQ234YGz4" target="_blank" rel="noreferrer">Nathan P.</a>
  </li>
  <li>
    <a href="https://youtu.be/fa15oxpL7G8"  target="_blank" rel="noreferrer">Mahogany Browne</a>
  </li>
</ul>
<a href="https://www.cafepress.com/verbs"><img src="/verbscafe.jpg" alt="" /></a>
<div className="row">
  <div className="col-md-7">
  <a href="/2000program.pdf"><img src="/SlambookCover.jpeg" className="img-fluid" alt="" /></a>
  <p className='caption'>The Slambook was the first Nuyorican Slam magazine. It was distributed at 2000 Grand Slam Finale instead of an evening program. Designed and edited by Clare Ultimo. Click on cover to see magazine PDF.
</p>
  </div>
</div>

    </div>
  )
}

export default Media
