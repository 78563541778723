import React from 'react'

const GrandSlam2005 = () => {
  return (
    <div>
    <h1>2005 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: KAREN JAIME</p>
<p>The 2005 Finale Program not available at this time.</p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2005-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>brady</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2005-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>mad</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2005-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>marxman</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2005-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>oveous maxius</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2005-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>andrew tyree</p>
        </div>
      </div>
  </div>
  )
}

export default GrandSlam2005
