import React from 'react'

const NatTeam1995 = () => {
  return (
    <div>
    <h1>1995 Nuyorican National Slam Team</h1>
   <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/1995-hil.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>hil cato</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1995-xavier.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>xavier cavazos</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1995-poppy.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>poppy</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1995-crystal.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>crystal williams</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam1995
