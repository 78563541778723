import React from 'react'
import { Link } from 'react-router-dom'

const SaulWilliams = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/saul.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Saul Williams</h1>
      <h2 className='sub-head'>1996 Nuyorican Grand Slam Champion</h2>
    <p>To thousands of youth across America, Saul Williams has defined and redefined
poetry as an accessible, living art form. His poetry has served as the archetype for
a new generation of poets and spoken word artists.</p>
<p>His groundbreaking work as a writer and actor in the critically acclaimed film,
"Slam" (Sundance's Grand Jury Prize '98; Cannes' Camera D'Or '98); placed him
as a figurehead of the spoken word/slam poetry movement that has spread
across the United States and culminated in such commercial successes as Def
Poetry Jam.</p>
<p>Saul is the author of three collections of poetry ", said the shotgun to the
head" (2003 MTV Books/Simon and Schuster), "She" (1999 MTV Books/Simon
and Schuster), and "The Seventh Octave" (1997 Moore Black Press). His musical
debut, "Amethyst Rock Star", (2001 American Recordings/Island Def Jam) was
produced by legendary rock/hip hop producer Rick Rubin and received high
praises, including "album of the year" by the Times of London.</p>
<p>Williams has lectured and presented his work at nearly one hundred universities
and colleges here and abroad. Saul has performed, toured, and lectured across
the world and perhaps, the nation's youngest poet to have his writings added to
the cirriculum of dozens of high schools and colleges across the country. Saul has
made several television appearances, including: a recurring role on the UPN hit
sitcom Girlfriends; HBO's Def Poetry Jam; the Orlando Jones Show; The Chris
Rock Show, Politically Incorrect and many more. His writings appear in several
anthologies and periodicals such as "Catch the Fire" and The New York Times
respectively. Bio is from www.saulwilliams.com. Please see Saul's site for
recent books and projects.</p>
    <p>See <Link to="/national-teams/1996">National Teams/1996</Link>.</p>
    <p>Bio is from <a href="http://saulwilliams.com">saulwilliams.com</a></p>
    <p>Photo is from: <a href="http://authors.aalbc.com/saul.htm">http://authors.aalbc.com/saul.htm</a>
</p>
      </div>

      </div>
    </div>
  )
}

export default SaulWilliams
