import React from 'react'

const KirkNugent = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/kirk.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Kirk Nugent</h1>
      <h2 className='sub-head'>1999 Nuyorican Grand Slam Champion</h2>
    <p>For almost ten years Kirk Nugent has served as a catalyst for change, challenging
preconceived notions about success and self-actualization. Kirk started public
speaking in poetry venues where he displayed a keen understanding of social and
political issues delivered with his signature brand of infectious wit and frenetic
energy. Early audiences crowned him “The People’s Poet,” and the stage was set
for Kirk to widen his speaking base to include college campuses, key note
addresses for Fortune 500 companies, church ministries and empowerment
workshops. He has spoken at venues across the United States, Canada, Europe
and the Caribbean. Kirk continues to impact the lives of people from around the
world with public speaking engagements, and live appearances, as well as with
his book, The Unpopular Truth and CD’s The Unpopular Truth, and I want to
Testify, as well as with his highly anticipated guide to self-actualization, Ripple. <a href="https://knuge.org">See his site here</a></p>
<p>Photo by Christopher Weil</p>

<iframe width="560" height="315" src="https://www.youtube.com/embed/om6jXKIB2hI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default KirkNugent
