import React from 'react'

const ThomasPoole = () => {
  return (

    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/photos-thomas.jpg" alt="" />
        </div>
      <div className="col-md-8">

      <h1>Thomas Poole</h1>
    <p>Thomas Poole is the executive director of the community television station in the city of
Pittsburgh, PA” Pittsburgh Community Television (PCTV) (2002 - Present). His first
experience in public access was being part of a collective of video artist who produced
a politically oriented public affairs show called Not Channel Zero (NCZ). Over a five
year period, NCZ was awarded prizes by Hometown Video Festival Awards, American
Film Institute Awards and Black Hall of Fame Awards to name a few. The collective was
part of the 1992 Whitney Biennial and is the only collective of artist to receive a
Rockefeller Foundation Media Arts Fellowship. Mr. Poole resume includes working on
the PBS series ” Positive: Life with HIV as an associate producer (1993-1994) and as a
segment producer for another PBS series called Signal to Noise (1994-1995). Also, he
has managed The Nuyorican Poetry Cafe’s (1994 ” 1996) video program, directed a
media youth program YO-TV at Educational Video Center (1995-1998) and ran a media
distribution company Deep Dish TV (1998-2002). Lastly, he was a visiting artist in
residence for a four year project, 2001 Class Project (1997 ” 2001) commissioned by
the Wexner Art Center on the campus of Ohio State.</p>

      </div>
      </div>

    </div>

  )
}

export default ThomasPoole
