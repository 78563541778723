import React from 'react'


const NatTeam1998 = () => {
  return (
    <div>
    <h1>1998 Nuyorican National Slam Team</h1>
    <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/1998.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8">
       <p>In 1998, the Nuyorican National Team won the National Championship in
Austin, Texas, making them the first Nuyorican Team to win the title. I was
sitting in third row champ-photo center with a Nuyorican posse that included Miguel Algarin
and legendary poet, raul salinas, when Alix got her perfect 30 and took the
night for the Nuyorican. Roger Bonair-Agard holds the trophy. Keith Roach,
SlamMaster at the time and Dot Portella, are in the background of this shot as
well. -CU</p>
<p>Coach: Roger Bonair Agard</p>
<p>Photo: Clare Ultimo <br />
<a href="/1998Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1998-steve.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>steve colman</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1998-guy.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>guy lecharles gonzalez</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1998-alix.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>alix olson</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1998-lynne.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>lynne procope</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam1998
