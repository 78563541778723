import React from 'react'
import { Link } from 'react-router-dom'

const CelenaGlenn
 = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className="img-fluid champ-photo" src="/hosts-celena.jpg" alt="" />
</div>
    <div className="col-md-8">

    <h1 className='title-bold'>celena glenn</h1>
    <h2 className='sub-head'>Wednesday Night Slam Open Host circa 1999-2000</h2>
  <p>One of the twelve most fascinating NYC poets according to UNTOLD magazine,
Celena Glenn is an artist, educator and activist who most recently was the United
States poetry representative for Poetry Africa in Durban, South Africa.
Internationally she has performed at the Double Talk Festival in Amsterdam, the
Schafhauser Jazz Fest in Switzerland, and at Londons Jazz Cafe. She has
performed nationally at the Paramount in Seattle, and NYC's Town Hall and
Apollo Theater.</p>
<p>In 2004, she was voted Best Local Poet by the NY Press Reader's Poll and is a
two-time National Poetry Slam Champion. Her work can be found in Spoken Word
Revolution [Source Books], Bullets and Butterflies [Suspect Thought Press], and
the Women on the Bowery [BPC Press], as well as in the documentaries Slam
Planet and Urban Scribe.</p>
<p>Celena is currently the Artistic Director of Urban Word NYC, one of the nation's
leading literary and performance organizations for youth. She has performed as a
visiting artist/educator at colleges and universities across the US including
Princeton, Brown, UCLA and Columbia. In addition, as part of the spoken word
tour, Fight Apathy, she has performed as part of an initiative to raise awareness
about the global AIDS epidemic.</p>
<p>Celena has created and featured in productions for the Kitchen, the Culture
Project, Cross Roads Theater, New World Theater and BAM Harvey Theater,
along with working on projects with the Whitney Museum of Art, the NY Knicks
and Russell Simmon"s Def Jam.</p>
<p>As a beat boxer and DJ for the high-acclaimed band CocoRosie, she has toured
with Antony & the Johnsons and has independently produced music for Beau Sia,
Cheryl Boyce-Taylor and Roger Bonair-Agard. She is currently making tracks for
Bunny Rabbit . Bio and photo are from Celena's MySpace page. so you
should go and check it out for updates.</p>
<p>See <Link to="/grand-slam-finale/2000">Grand Slam Finale 2000</Link>.</p>


    </div>

    </div>

  </div>
  )
}

export default CelenaGlenn

