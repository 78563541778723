import React from 'react'
import { Link } from 'react-router-dom';

const OpenRoom = () => {
  return (
    <div>
      <h1>The Open Room</h1>
      <div className="row">
        <a href="#host-content">Read about the history of Open Room</a>
        <p><b>"The Cafe has been a place where those who believe in
the power of art have come to identify themselves. From
the inception of the Nuyorican there has always been an
Open Room. There has always been a book at the end of
the bar in which anyone can sign up and be guaranteed
time on stage to speak poetry or express views. It is our
'home base' for the poetic experience." -Lois E. Griffith</b></p>
      <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-indigo"><img className="img-fluid" src="/open-indigo.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-indigo">Indigo</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-lois"><img className="img-fluid" src="/open-lois.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-lois">Lois Elaine Griffith</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-keith"><img className="img-fluid" src="/open-keith.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-keith">Keith Roach</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-guy"><img className="img-fluid" src="/open-guy.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-guy">Guy Lecharles Gonzalez</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-juliet"><img className="img-fluid" src="/open-juliet.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-juliet">Juliet Gomez</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-jeffrey"><img className="img-fluid" src="/open-jeffrey.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-jeffrey">Jeffrey Feller</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-rich"><img className="img-fluid" src="/open-rich.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-rich">Rich Villar</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-anthony"><img className="img-fluid" src="/open-anthony.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-anthony">Anthony Morales</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/open-atrayn"><img className="img-fluid" src="/open-atrayn.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/open-atrayn">A-Trayn</Link></p>
        </div>
      </div>
<div id='host-content'>
      <p>As Lois E. Griffith has said, "the Open Room is home base" for poetry
at the Nuyorican. It is a classic open mic where anyone who signs the
book at the end of the bar can get up and read one poem. There are no
judges and no numbers and the host keeps the energy moving and the
audience appreciative.</p>
<p>
Nuyorican Open Room hosts have been great "night birds." They must
be enthusiastic, alert and ready to go Friday Night at around one am
after the Slam, when the Open Room begins. Each and every one has
been special and important to the Nuyorican. Often, they mentor shy
poets, or those who don't want to slam but love to write and share their
work. They create a different kind of atmosphere once all the sounds of
the Slam subside and folks are settled into their seats again. The Open
Room is the place where you can actually feel comfortable "reading"
something – you can have a piece of paper right in front of your nose
and not necessarily perform it. Like many others, I often tried out a new
piece in the Open Room – sometimes written just hours before.</p>
<p>
Lois, the Cafe Director, hosted the first Open Room that happened after
the Friday Night Slam in the early years. Keith Roach began his
SlamMaster history in this spot, taking over for Lois. Evert Eden was
also hosting here on occasion in those early days.</p>
<p>
I had my first experience reading my poetry in public at the Nuyorican
Open Room in 1993. I was so exhilarated by this experience that I didn't
sleep at all that night. It remains a wonderful open door for creativity
and the tradition continues to this day. -CU</p>

</div>
</div>
  )
}

export default OpenRoom
