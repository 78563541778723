let slamMasters = [
  {
    name: "1990 Paul Beatty",
    picture: "paul-beatty.jpg",
    slug: "paul-beatty",
  },
  {
    name: "1991 Willie Perdomo",
    picture: "willie.jpg",
    slug: "willie-perdomo",
  },
  {
    name: "1992 Reg E Gaines",
    picture: "reg-gaines.jpg",
    slug: "reggie-gaines",
  },
  {
    name: "1992 Dana Bryant",
    picture: "dana.jpg",
    slug: "dana-bryant",
    info: `<h1>1992 Nuyorican Grand Slam Champion</h1>
    <p>"Dana Bryant grew up in Brooklyn, New York. She made her poetry debut in 1991; in 1995, she released her first book of poems, ""Song of the Siren"" (Boulevard Books/Putnam Berkeley) and the following year her debut solo album, ""Wishing From the Top"" on Warner Bros. Records. She has performed in Europe and Japan with artists such as Speech (of Arrested Development), Zap Mama, PM Dawn and Ronnie Jordan. She is featured in ""The Rolling Stone Book of Women
    In Rock"" and the ""International Who's Who of Music"". Bio is from http://www.
    cosmoetica.com/TOP21-DES19.htm.</p>
    <p>See National Teams/1992.</p>
    <p>Photo is taken from: Route Magazine"
    </p>`,
  },
  {
    name: "1992 Tracie Morris",
    picture: "tracie.jpg",
    slug: "tracie-morris",
    info: `<h1>1992 Nuyorican Grand Slam Champion</h1>
    <p><Tracie Morris teaches at Sarah Lawrence College. She is a multi-disciplinary poet
    who has worked in theater, dance, music and film. She has toured extensively
    throughout the United States, Canada, Europe, Africa and Asia. Primarily known
    as a "musical poet," Tracie has worked with an extensive range of internationally
    recognized muscians and other artists. She has participated in a dozen recording
    projects. Her sound poetry has most recently been featured in the 2002 Whitney
    Biennial. She is the recipient of numerous awards for poetry including the New
    York Foundation for the Arts Fellowship, Creative Capital Fellowship, the National
    Haiku Slam Championship and an Asian Cultural Council Fellowship. She is the
    author of two poetry collections, "Intermission" and "Chap-T-her Won". She has
    delivered academic papers at the New York University Soul: Black Power, Politics
    and Pleasure Conference; The Hemispheric Conference in Lima, Peru; The
    Langston Hughes Centenary Conference at Yale University; Poetry and the Public
    Sphere at Rutgers University and the African-American Poetry Conference for the
    Poetry Society of America. As a writer, Tracie's poetry has been anthologized in
    literary magazines, newspapers and books including "360 Degrees: A Revolution
    of Black Poets", "Listen Up!", "Aloud: Voices from the Nuyorican Poets Cafe",
    "The Outlaw Bible of American Poetry and Soul". Her words have also been
    featured in commissioned pieces for several organizations including Aaron Davis
    Hall, the International Festival for the Arts, The Kitchen, Franklin Furnace and
    Yale Repertory Theater for choreographer Ralph Lemon.Tracie holds degrees
    from Hunter College and New York University. She also holds certificates from the
    Cave Canem Summer Institute and the Hemispheric Institute of NYU.</p>
    <p>Bio is from http://pages.slc.edu/~mtracie/</p>
    <p>See National Team/1993.</p>
    <p>Photo is from:
    http://deseretnews.com/photos/3378068.jpg</p>`,
  },
  {
    name: "1993 Pete Spiro",
    picture: "petespiro.jpg",
    slug: "pete-spiro",
  },
  {
    name: "1993 Regie Cabico",
    picture: "regie.jpg",
    slug: "regie-cabico",
  },
  {
    name: "1994 Tish Benson",
    picture: "tish.jpg",
    slug: "tish-benson",
  },
  {
    name: "1995 Xavier Cavazos",
    picture: "xavier.jpg",
    slug: "xavier-cavazos",
  },
  {
    name: "1996 Saul Williams",
    picture: "saul.jpg",
    slug: "saul-williams",
  },
  {
    name: "1997 Sarah Jones",
    picture: "sarah.jpg",
    slug: "sarah-jones",
  },
  {
    name: "1998 Kayo",
    picture: "kano.jpg",
    slug: "kayo",
  },
  {
    name: "1999 Kirk Nugent",
    picture: "kirk.jpg",
    slug: "kirk-nugent",
  },
  {
    name: "2000 Bryonn Bain",
    picture: "bryonn.jpg",
    slug: "bryonn-bain",
  },
  {
    name: "2001 Mayda Del Valle",
    picture: "mayda.jpg",
    slug: "mayda-del-valle",
  },
  {
    name: "2002 Kahlil Almustafa",
    picture: "khalil_amustafa.jpeg",
    slug: "kahlil-almustafa",
  },
  {
    name: "2003 Julian Curry",
    picture: "julian.jpg",
    slug: "julian-curry",
  },
  {
    name: "2004 Daniel Beaty",
    picture: "daniel.jpg",
    slug: "daniel-beaty",
  },
  {
    name: "2005 Andrew Tyree",
    picture: "andrew.jpg",
    slug: "andrew-tyree",
  },
  {
    name: "2006 Archie The Messenger",
    picture: "archie.jpg",
    slug: "archie-the-messenger",
  },
  {
    name: "2007 Aja Monet",
    picture: "aja.jpg",
    slug: "aja-monet",
  },
];

export function getSlamMasters() {
  return slamMasters;
}

export function getSlamMaster(name) {
  return slamMasters.find(
    (slamMaster) => slamMaster.name === name
  );
}
