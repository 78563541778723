import React from 'react'
import { Link } from 'react-router-dom'

const GrandSlamFinale = () => {
  return (
    <div>

      <h1>The Nuyorican Grand Slam Finale</h1>
      <a href="#text">Read the story of Nuyorican Grand Slam</a>
      <div className="row">
      <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1990"><img className="img-fluid" src="/GSHomePageIcons/1990GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1990">1990</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1991"><img className="img-fluid" src="/GSHomePageIcons/1991GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1991">1991</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1992"><img className="img-fluid" src="/GSHomePageIcons/1992GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1992">1992</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1993"><img className="img-fluid" src="/GSHomePageIcons/1993GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1993">1993</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1994"><img className="img-fluid" src="/GSHomePageIcons/1994GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1994">1994</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1995"><img className="img-fluid" src="/GSHomePageIcons/1995GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1995">1995</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1996"><img className="img-fluid" src="/GSHomePageIcons/1996GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1996">1996</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1997"><img className="img-fluid" src="/GSHomePageIcons/1997GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1997">1997</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1998"><img className="img-fluid" src="/GSHomePageIcons/1998GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1998">1998</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/1999"><img className="img-fluid" src="/GSHomePageIcons/1999GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/1999">1999</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2000"><img className="img-fluid" src="/GSHomePageIcons/2000GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2000">2000</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2001"><img className="img-fluid" src="/GSHomePageIcons/2001GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2001">2001</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2002"><img className="img-fluid" src="/GSHomePageIcons/2002GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2002">2002</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2003"><img className="img-fluid" src="/GSHomePageIcons/2003GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2003">2003</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2004"><img className="img-fluid" src="/GSHomePageIcons/2004GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2004">2004</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2005"><img className="img-fluid" src="/GSHomePageIcons/2005GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2005">2005</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2006"><img className="img-fluid" src="/GSHomePageIcons/2006GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2006">2006</Link></p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-6">
          <Link to="/grand-slam-finale/2007"><img className="img-fluid" src="/GSHomePageIcons/2007GS.jpg"alt="" /></Link>
          <p><Link to="/grand-slam-finale/2007">2007</Link></p>
        </div>
      </div>
      <p id="text">The five poets who end up competing in the Grand Slam Finale each year have managed to beat some pretty big odds and because of this, I felt it was crucial to name them in Nuyorican Slam history. Whether or not they won the Championship title at the end of that night, they represent a kind of poetic warrior that we should not underestimate.</p>

<p>The Nuyorican averages two slams a week and in the course of a year, close to 1000 individuals have come through our doors to participate in Poetry Slam. And it goes something like this: The Slam Open, will end with a winner who will be asked to Slam on a Friday night. The winner of a Friday Night Slam will be eligible to participate in a Semi-Final Slam and the winner of each Semi-Final qualifies for the Grand Slam Finale (thus five Semi-Finals get us five Grand Slam Finalists). But winning the first time you Slam at the Nuyorican isn't the rule, it's the exception, and often, poets will participate in many Wednesday Night Open Slams and many Friday Night Slams before they emerge victorious and can participate in this yearly event.</p>

<p>Bob Holman experimented with different combinations for the Grand Slam Finale in the earliest years of Slam at the Nuyorican...number of participants varied for a while. By 1999, when Keith Roach was SlamMaster, the show became most comfortable at five and has stayed that way ever since.</p>

<p>I began going to the Nuyorican in 1993, but it wasn't until 1997 that I became involved in creating promotional materials for the Grand Slam Finale. Because of that, I have verifiable bios and photos of all the participants from 1997 until today since I created the programs or posters for those nights. Grand Slam Finale participants prior to 1997 were found through our video archives or through the filmmaker's journals of the film "Slam". See those pages for more details.-CU</p>
    </div>
  )
}

export default GrandSlamFinale
