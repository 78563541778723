import React from 'react'

const NatTeam1993 = () => {
  return (
    <div>
       <h1>1993 Nuyorican National Slam Team</h1>
      <div className="row champ-photo teams">
        <div className="col-md-4">
          <img src="/nat-teams/1993-regie.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>regie cabico</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1993-maggie.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>maggie estep</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1993-hal.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>hal sirowitz</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1993-tracie.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>tracie morris</p>
        </div>
      </div>
    </div>
  )
}

export default NatTeam1993
