import React from 'react'

const NatTeam2004 = () => {
  return (
    <div>
    <h1>2004 Nuyorican National Slam Team</h1>
    <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/2004.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">

<p>Coach: Ainsley Burrows</p>
<p>Photo: Clare Ultimo<br />
<a href="/2004Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2004-daniel.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>daniel beaty</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2004-tshaka.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>tshaka</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2004-august.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>august green</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2004-desiree.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>desireé marshall</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam2004
