import React from 'react'
import { Link } from 'react-router-dom'

const RegieCabico = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/regie.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Regie Cabico</h1>
      <h2 className='sub-head'>1993 Nuyorican Grand Slam Champion</h2>
    <p>Regie Cabico took top prizes in the 1993, 1994 & 1997 National Poetry Slams and
won the 1993 Nuyorican Poets Cafe Grand Slam. His work appears in over 30
anthologies including "Aloud: Voices from the Nuyorican Poets Cafe", "Spoken
Word Revolution" & "The Outlaw Bible of American Poetry". He received three
New York Foundation for the Arts Fellowships for Poetry & Performance Art , The
Barnes & Noble Writers for Writers Award presented by Poets & Writers, two 2006
New York Innovative Theater Award Nominations for his work in the New York
Neo Futurist production of "Too Much Light Makes The Baby Go Blind" with a win
for Best Performance Art Production, a finalist for Washington, DC's Larry Neal
Award for Poetry and received grants from Poets & Writers, Mid Atlantic Arts
Foundation with The Asian American Arts Initiative. As a theater artist, his works
have been produced at The 2003 Humana Theater Festival, 2001 New York
Fringe Festival, The Kitchen, Dixon Place & Joe's Pub. With Aileen Cho he cowrote
and starred in several two person plays including "Crouching Cabico,
Hidden Cho" for Theater Offensive & U-Hauls, "Bridemaids & Backward
Mountains" for the Asian American Theater Festival. He is artistic director of Sol &
Soul, a Washington, DC arts & activist organization where he directed Suzan Lori
Park's "365" and "The Other Side", a spoken word play. He is a distinguished
Artist In Residence at NYU's Asian Pacific American Studies Department and
University of Maryland's Asian American & Filipino Studies Program. He is
currently working on the lyrics for the musical adaptation of John Waters'film "Pink
Flamingos".</p>
    <p>See <Link to="/national-teams/1993">National Teams/1993</Link>.</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/riPdKmp0QhQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default RegieCabico
