import React from 'react'
import { Link } from 'react-router-dom'

const BryonnBain = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/bryonn.jpg" alt="" />
</div>
        <div className="col-md-8">

        <h1>Bryonn Bain</h1>
<h2 className='sub-head'>2000 Nuyorican Grand Slam Champion</h2>
<p>Bryonn Bain is Brooklyn's own spoken word champion and hip hop poet.
Interweaving social justice themes and spirituality with intricate wordplay and
soaring stage presence, Bain was wrongfully imprisoned by the NYPD during his
second year at Harvard law school. Bryonn sued the City of New York, was
interviewed by Mike Wallace on CBS' "60 Minutes," and wrote the Village Voice
cover story - "Walking While Black: The Bill of Rights for Black America" - which
drew the largest response in the history of the nation's most widely-read
progressive newspaper. Described by Cornel West as "...a poet who speaks his
truth with a power we desperately need to hear," Bain has lectured and performed
at over 100 colleges and universities worldwide, and shared stages with The Last
Poets, Amiri Baraka, Sonia Sanchez, Run DMC, and Dead Prez. For the past five
years, Bain has organized workshops and performances in prisons across the
country with his grassroots organization's award-winning "Lyrics on Lockdown
Tour." Currently teaching courses exploring hip hop, spoken word poetry and the
prison industrial complex at Columbia University, Bain also hosts BET-J's leading
talk show "My Two Cents" on air each week in 27 million homes nationwide.
Crowned the Nuyorican Grand Slam Poetry Champion in 2000, Bain's acclaimed
debut album "Problem Child: The Philosophy and Opinions..." fuses hip hop with
spoken word and the world music he grew up with as the son of African and
Indian parents who immigrated from Trinidad to Brooklyn. Sold out on CD Baby.
com, in New York state prisons, at colleges throughout the nation, as well as on
two european tours, you can now sample tracks and purchase your own copy of
this future-classic album online at: www.BryonnBain.com. The groundbreaking
music video - "Ancestors Watching" - executive produced by Warrington Hudlin
(of "House Party," "Boomerang" and "Posse" fame) is available online as well.
</p>
<p>See <Link to="/national-teams/2000">National Teams/2000</Link> and <Link to="/grand-slam-finale/2000">Grand Slam Finales/2000</Link>.</p>
<p>Photo is from: <a href="www.blackstudies.pdx.edu/images/clip_image002.jpg">www.blackstudies.pdx.edu/images/clip_image002.jpg</a>
</p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/efWPhTI0fkY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      </div>
    </div>
  )
}

export default BryonnBain
