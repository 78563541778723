import React from 'react'

const GranSlam1990 = () => {
  return (
    <div className='row'>
      <h1>1990 Nuyorican Grand Slam Finale</h1>
      <p>The first-ever Grand Slam Finale was held at the Nuyorican on March 23, 1990.</p>
      <p>Participant names for the 1990 and 1996 Slam were taken from Bob Holman's journals published in the
book "Slam". See 1996 Grand Slam Finales for more on this groundbreaking film and book.</p>
<div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1990-anya.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>anya achtenberg</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1990-paul.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>paul beatty</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1990-denise.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>denise bell</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1990-bruce.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>bruce isaacson</p>
        </div>
      </div>
    </div>
  )
}

export default GranSlam1990
