import React from 'react'
import { Link } from 'react-router-dom';

const WedSlamMasters = () => {
  return (
    <div>
      <h1>The Nuyorican Wednesday Night Slam Open</h1>
      <div className="row">
        <a href="#host-content">Read about the Wednesday Night Slam Open</a>
      <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/evert-eden"><img className="img-fluid" src="/hosts-evert.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/evert-eden">Evert Eden</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/shut-up-shelly"><img className="img-fluid" src="/hosts-shelly.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/shut-up-shelly">Shut Up Shelly</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/eileen-reyes"><img className="img-fluid" src="/eileen.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/eileen-reyes">Eileen Reyes</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/dot-portella"><img className="img-fluid" src="/hosts-dot.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/dot-portella">Dot Portella</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/celena-glenn"><img className="img-fluid" src="/hosts-celena.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/celena-glenn">Celena Glenn</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/nathan-p-weds"><img className="img-fluid" src="/hosts-nathan.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/nathan-p-weds">Nathan P</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/mahogany-browne-weds"><img className="img-fluid" src="/hosts-mahogany.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/mahogany-browne-weds">Mahogany Browne</Link></p>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to="/slam-masters/jive-poetic"><img className="img-fluid" src="/hosts-jive.jpg" alt="" /></Link>
          <p><Link to="/slam-masters/jive-poetic">Jive Poetic</Link></p>
        </div>
      </div>
<div id='host-content'>
      <p>The first stop for any potential National Team member or Finalist is the
Wednesday Night Slam Open. The Slam Open happens every
Wednesday of the month, except for the first. The folks who have
hosted this event provide a crucial link between the first-time Slammer
and the Friday Night stage; they are vital to our Slam since they provide
a comfortable space to begin the process. Many Wednesday Night
Hosts have also hosted the Friday Night event, though their
responsibility to Friday Nights was never as demanding and ongoing as
the SlamMaster. A few here (Nathan P and Mo Browne) have continued
at the Nuyorican as "resident" Friday Night SlamMasters.</p>
<p>
The structure of a Wednesday night allows for any poet to come up,
sign the book (hopefully legibly!) and read a poem. The winner of any
Wednesday night is offered a Friday Night Slam spot; and while some
Slammers move quickly to this point, others may take many
Wednesdays to get there. The Slam Open also functions as a kind of
practice for the Friday Slam...even seasoned Slammers come through
to "work out" a piece in front of a live audience. For many, it's been an
effective way to sharpen poetic and performance skills.</p>
<p>
But you don't have to be a Slammer to participate or enjoy a
Wednesday Night Slam Open. All kinds of poets show up, some
curious, some hardly competitive at all – old, young and everyone inbetween.
Poets will even cycle in and out over time, but like Poetry
Slam in general, Wednesday nights are never predictable.</p>
<p>
Our Wednesday Night Hosts have been memorable, entertaining and
dedicated, each with their own style and flavor. All of them have
encouraged the poets they've heard and made this night still one of the
greatest open mics in New York City.</p>
<p>All the names you'll see here have given selflessly (and for very little
financial reward) to the great cause of poetry. The Nuyorican has been
fortunate to have had their talents at the helm of the Slam Open. We
couldn't have done it withoutcha! -CU</p>
</div>
</div>
  )
}

export default WedSlamMasters
