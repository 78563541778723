import React from 'react'
import { Link } from 'react-router-dom'

const Reviews = () => {
  return (
    <div className='row'>
      <div>
      <h1>What People Say</h1>
      <p>Almost every one of our Nuyorican Poetry Slam luminaries has had reams of press written about them. Their books, plays, performances, films, or TV shows have been reviewed and praised; they've been interviewed, honored and talked about a lot in media. The best way for you to see all the media attention given to these Slam Poets and Hosts is to go to their individual websites, which are linked to their bios on this site.</p>
<p>The idea of "Verbs on Asphalt" is to document Poetry Slam as it developed at the Nuyorican and there have been many articles written about this subject specifically. But journalists have also been interested in the relationship of Poetry Slam to earlier poetic and cultural histories, which broadens the subject quite a bit. And Poetry Slam, as a cultural phenomenon with national and international interest, has been continually covered in print and other media around the world.</p>
<p>A really exhaustive search for all Nuyorican Poetry Slam references would have to include the millions of words written about legendary Nuyorican poets (such as Algarin and Pinero); the plethora of text on the influence of the Beat Poets on late 20th Century American poetry, and the considerable work of "poetry activist" Bob Holman. It would have to include the profound influence that hiphop had on the form, as well as the impact that artists like The Last Poets and Gil Scott Heron were making on the scene. Powerful cultural seeds were planted long before Poetry Slam entered our consciousness and there's an entire (and separate) journalistic history that led to the "why" and "how" it came to its present popularity in the world. The Nuyorican became the place where these poetic repercussions converged; true to its mission as a New York City cultural institution, it brought the "freshest" voices to the world poetic stage through the Poetry Slam.</p>
<p>The articles here show a variety of perspectives on the people and ideas that created Nuyorican Poetry Slam, but they are only a small sampling of what's been written on the subject. This collection is not meant to be inclusive, but rather a cross-section of the print media attention that the Nuyorican Slam has managed to attract over the years. -CU</p>
<div className="row">
  <div className="col-md-4">
  <img class="img-fluid" src="/ny-mag.jpg" alt="" />
  </div>
  <div className="col-md-8">
    <p className='caption'>One example of how Slam hit the mainstream on May 3,1993, New York Magazine "The Beats Are Back: The Nuyorican Poets Cafe" by Rebecca Mead. Edwin Torres is on the cover. Article not available online right now.</p>
  </div>
</div>
<hr></hr>
<img className='link-bottom' src="/NYTimesNewHeader2.jpg" alt="" />
<ul>

<li>May 14, 1976 <br />
<a href="/01.news.pdf"><b>"Nuyoricans" Express Pain and Joy in Poetry</b></a>  by David Vadal (This is way before the Slam at the Nuyorican, but it's one of many early articles about Nuyorican founding fathers).</li>
<li>
November 19, 1990 <br />
<a href="/02.news.pdf"><b>Again, Clubs for Poets to Read and Rage</b></a> by Evelyn Nieves
</li>
<li>
January 31, 1992 <br />
<a href="/03.news.pdf"><b>The Word's Turn: Urban Poets Re-emerge</b></a> by Eve M. Kahn
</li>
<li>
September 14, 1993 <br />
<a href="/04.news.pdf"><b>Chronicle</b></a> by Nadine Brozan (About Indigo, one of our legendary hosts, and his Left- Handed Poets Slam at the Nuyorican)
</li>
<li>
September 25, 1994 <br />
<a href="/05.news.pdf"><b>POP VIEW; From Beat Poet to Pop Chic</b></a> by Ken Tucker
(A bit of history that connects Allen Ginsberg, Amiri
Baraka, Patti Smith, Jim Carroll, Maggie Estap and Reg
e Gaines).
</li>
<li>
September 26, 1994 <br />
<a href="/06.news.pdf"><b>After the Beats: A New Generation Raises Its Voice in Poetry</b></a> by Diana Jean Schemo
</li>
<li>

September 29, 1996 <br />
<a href="/07.news.pdf"><b>From Rap's Rhythms, a Retooling of Poetry</b></a> by Michel Marriott
(Saul Williams, Reg e Gaines, Amiri Baraka, Willie Perdomo and the culture of urban poetry).
</li>
<li>
February 1, 1998 <br />
<a href="/08.news.pdf"><b>POP/JAZZ; A Hip-Hop Poet Looks Beyond Her Roots</b></a> by Somini Sengupta
(Focuses on Tracie Morris, Grand Slam Champion 1992)
</li>
<li>
July 14, 1998 <br /><a href="/09.news.pdf"><b>A Louder Voice for Poetry; From Self- Publication in the 50's to Contests Today</b></a> by Holland Cotter
(A review of "A Secret Location on the Lower East Side: Adventures in Writing, 1960-1980", which provides some interesting backdrop references to Nuyorican Poetry Slam).
</li>
<li>
August 16, 1999 <br /><a href="/10.news.pdf"><b>Part Art, Part Hip-Hop And Part Circus; Slammers Shake Up an Interest in Poetry</b></a> by Bruce Weber
(A general reaction to the Poetry Slam that focuses on the National scene).
</li>
<li>
January 2, 2002 <br />
<a href="/11.news.pdf"><b>The Poetry of the Nuyorican Experience; Writers Following in the Literary Tradition of Miguel Pinero Thrive in a Poets' Cafe</b></a> by Mireya Navarro
</li>
</ul>

<h2 className='bib'>Verbs on Asphalt <Link to="/bibliography">Bibliography</Link></h2>

      </div>

    </div>
  )
}

export default Reviews
