import React from 'react'

const NatTeam1996 = () => {
  return (
    <div>
    <h1>1996 Nuyorican National Slam Team</h1>
    <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/1996-main.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8">
       <p>In case you didn't already know, the entire
Nuyorican 1996 National Team were featured in the
film "SlamNation" by five-time Emmy Award winner,
Paul Devlin. It's an incredibly engaging behind-thescenes
look at what it was like to participate on the
National Poetry Slam stage in 1996. You can buy
it here, if you don't already own it, but please find
a way to see it...it was the first of the Poetry Slam
film genre, and was also co-produced by our very
own Tom Poole.</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1996-jessica.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>jessica care moore</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1996-mums.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>muMs da schemer</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1996-beau.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>beau sia</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1996-saul.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>saul williams</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam1996
