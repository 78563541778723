import React from 'react'

const Bibliography = () => {
  return (
    <div id="top">
      <h1>Bibliography</h1>
      <p>References & Bibliography>Verbs on Asphalt: The History of Nuyorican Poetry Slam
by Clare Ultmo</p>


<h2>Books:</h2>

Algarin Miguel and Bob Holman, Aloud: Voices from the Nuyorican Poets Cafe New York. Henry Holt & Co, 1994. <br />
 <br />
Algarin, Miguel & Lois Griffith, Action! the Nuyorican Poets Cafe Theater Festival. New York, Simon and Shuster, Touchstone 1997. <br />
 <br />
Algarin, Miguel and Miguel Pinero, Nuyorican Poetry: An Anthology of Puerto Rican Words and Feelings. New York: HarperCollins Publishers 1975 <br />
 <br />
Amburn, Ellis, Subterranean Kerouac: The Hidden Life of Jack Kerouac. New York: St. Martin's Griffin, 1999 <br />
 <br />
Angelsey, Zoe, Listen up! Spoken Word Poetry. New York: Random House Publishing Group, 1999. <br />
 <br />
Brinkley, Douglas, Jack Kerouac, Windblown World: The Journals of Jack Kerouac 1947-1954. New York: Viking 2004. <br />
 <br />
Cabico, Regie and Todd Swift, Poetry Nation: the North American Anthology of Fusion Poetry. Montreal, Quebec, Véhicule Press 1998 <br />
 <br />
Dennis McNally, Desolate Angel: Jack Kerouac, the Beat Generation, and America. New York: DeCapo Press, 1980 <br />
 <br />
Eleveld, Mark, The Spoken Word Revolution (slam, hip-hop & the poetry of a new generation). Naperville, Illinois: sourcebooks/mediaFusion, 2002. <br />
 <br />
Fernando, S.H. Jr., The New Beats: Exploring the Music, Culture and Attitudes of Hip-Hop. New York: Anchor Books Doubleday 1994 <br />
 <br />
Finnegan, Ruth, Oral Poetry: Its Nature, Significance, and Social Context. Indiana University Press, 1992. <br />
 <br />
Foley, John Miles, How to Read an Oral Poem, University of Illinois Press,  2002. <br />
 <br />
Gaines, Reg E., The Original Buckwheat. New Jersey: Long Shot Productions, 1998. <br />
 <br />
Gilbert, Derrick I.M., Catch the Fire! A Cross-Generational Anthology of Contemporary African-American Poetry. New York: Riverhead Books, 1998. <br />
 <br />
Ginsberg, Allen, Collected Poems 1947-1980. New York: Harper and Row, 1984. <br />
 <br />
Glazner, Gary Max, Poetry Slam: The Competitive Art of Performance Poetry. San Francisco, Manic D Press, 2000. <br />
 <br />
Griffith, Lois, Among Others. New York, Crown Publishers, 1998. <br />
 <br />
Holman, Bob, The Collect Call of the Wild. New York, Henry Holt & Co, 1995. <br />
 <br />
Kaufman, Alan and S. A. Griffin, Outlaw Bible of American Poetry. New York: Thunders Mouth Press, 1999 <br />
 <br />
Kaufman, Bob, The Ancient Rain: Poems 1956-1978. New York: New Directions, 1981. <br />
 <br />
Kayo, Bridges to Build, Rivers to Cross, Mountains to Move. New York: PAGACommunications, 2000. <br />
 <br />
Lipton, Lawrence, The Holy Barbarians. New York: Julian Messner Inc., 1959. <br />
 <br />
Moore, Alan and Josh Gosciak,  A Day in the Life; An Anthology of Writings from the Lower East Side 1940-1990. New York, Evil Eye Books, 1990 <br />
 <br />
Pietri, Pedro, Puerto Rican Obituary. Toronto, Monthly Review Press, 1974 <br />
Ritchie,. Donald A., Doing Oral History. Oxford University Press, 2003. <br />
 <br />
Salinas, Raúl,  East of the Freeway.  San Antonio,Texas: Real Salmon Press, 1995. <br />
 <br />
Smith Marc, The Complete Idiot's Guide to Slam Poetry. Alpha Books, 2004. <br />
 <br />
Swados, Elizabeth and Joe Cepeda, Hey You! C'mere! A Poetry Slam. New York: Arthur A. Levine Books, 2002. <br />
 <br />
Troupe, Quincy,  Avalanche. Minneapolis: Coffee House Press, 1996. <br />
 <br />
Vansina, Jan,  Oral Tradition as History. University of Wisconsin Press, 1985 <br />
 <br />
<h2>Journals:</h2>
Christy, Jim,  Jack and Jazz: Woodsmoke and Trains. Moody Street Irregulars A Jack Kerouac Newsletter, Summer/Fall 1980: 10-11. <br />
 <br />
Dardess, George,  The Questing Continues. Moody Street Irregulars A Jack Kerouac Newsletter, Winter/Spring 1981: 6-7. <br />
 <br />
Knight, Arthur & Kit,  The Beat Diary. Volume 5 The Unspeakable Visions of the Individual, California, PA, 1977, 80-94. <br />
 <br />
Moore, Dave,  A Beat Conversation, 1949. Moody Street Irregulars A Jack Kerouac Newsletter, Spring/Summer 1982: 22-23. <br />


<h2>Newspapers:</h2>
Fenton, Patrick,  Kerouac in Queens. The Newsday Magazine, 1 April 1990: 8-13. <br />
 <br />
Philips, Robert,  Are Those Real Poems, or Did You Write Them Yourself? Adventures on the Reading Circuit. New York Times Book Review, 12 Feb. 1995: 3. <br />
 <br />
Slavitt, David R..,  Passionate Intensity. The New York Times Book Review, 12 Feb. 1995: 3. <br />
 <br />

<h2>Online Articles and Papers:</h2>
Miazga, Mark, The Spoken Word Movement of the 1990s.
http://www.msu.edu/~miazgama/spokenword.htm <br />
 <br />
Smooth, Jay, Spoken Word UP!: Common and Saul Williams. The Core/Feature, 18, April 2005
http://www.sohh.com/article_print.php?content_ID=6957 <br />
 <br />
Irwin, Elizabeth, Slam! Bringing Poetry to Life. 2006
http://bostonteachnet.org/irwin/sigproj.htm <br />
 <br />
Blalock, Nancy,  Holding a Poetry Slam. Learn NC, K-12 Teaching and Learning>>From the University of North Carolina at Chapel Hill School of Education
http://www.learnnc.org/articles/poetryslam0610 <br />
 <br />
Brady, John, Everyone's a Poet: Criticizing the Poetry Slam. Bad Subjects, Issue 8, Oct. 1993
http://bad.eserver.org/issues/1993/08/brady.html <br />
 <br />
Dyck, Brenda,  A Poetry Slam Cures the Midwinter Blahs. Voice of Experience,
Education World, 2003
http://www.education-world.com/a_curr/voice/voice069.shtml <br />
 <br />
Holman, Bob, Poetry Slam in the Classroom. Cultural Arts Resources for Teachers and Students, 2001.  http://www.carts.org/pdfs/poetry_slam_classroom.pdf <br />
 <br />
Slam Poetry, Wikipedia, The Free Encyclopedia, 2004
http://en.wikipedia.org/wiki/Slam_poetry <br />
Pietrzykowski, Marc, On the Privatization of Poetry Drunken Boat 6, Spring 2004.
www.drunkenboat.com/db6/ pietrzykowski/privitization1.html <br />
Palattella, John, 10 Years After, Poetry Still Matters. The Chronicle Review, The Chronicle of Higher Education, 27, September, 2002
http://chronicle.com/free/v49/i05/05b01301.htm <br />

 <br />
<h2>Websites:</h2>
www.alaskapoetry.org <br />
www.alixolson.com <br />
www.bluemilk.com (Georgia-based poetry scene) <br />
www.carts.org (Cultural Arts Resources for Teachers and Students) <br />
www.ckut.ca (Groove Garden, Canadian based poetry group) <br />
www.e-poets.net <br />
www.e-poets.net/library/slam <br />
www.edwintorres.com <br />
www.freshpoetry.com <br />
www.halsirowitz.com <br />
www.historicalvoices.org/spoken word <br />
www.ironicworld.com <br />
www.oraltradition.org <br />
www.performancepoetry.com <br />
www.poetryslam.com <br />
www.sarahjonesonline.com <br />
www.slampapi.com <br />
www.thebattleofthepoets.com (The Battle of the Poets) <br />
www.womenoutloud.com <br />


<h2>Documentary Film: </h2>
Antonelli, John, Kerouac. Active Home Video 1985. <br />
 <br />
Devlin, Paul,  SlamNation: The Sport of Spoken Word. Cinema Guild Inc. 1998 <br />
 <br />
Dullaghan, John,  Bukowski: Born into This Life. Magnolia, 2006. <br />
 <br />
Evans, Robert,  The Kid Stays in the Picture.  A film by Brett Morgan and Nanette Burstein,
Universal City Studios Productions, 2002 <br />
 <br />
Fooling With Words With Bill Moyers. A Production of Public Affairs Television, Inc. with Tatge/Lasseur Productions, 1999 <br />
 <br />
Lathan, Stan (director), Russell Simmons presents Def Poetry Season 1 and 2. Warner Home Video, 2005 <br />
 <br />
Leslie, Alfred and Robert Frank, Pull My Daisy. G-String Enterprises,1959. <br />
 <br />
Mann, Ron,  Poetry in Motion. Voyager 1992. <br />
 <br />
Pellington, Mark, The United States of Poetry.  Tapeworm Studios, 1995 <br />
 <br />
Poetry Slam Inc. Presents the World's Greatest Poetry Slam; Live from Chicago 2003. Poetry Slam Inc., 2003 <br />

    </div>
  )
}

export default Bibliography
