import React from 'react'

const NatTeam2001 = () => {
  return (
    <div>
    <h1>2001 Nuyorican National Slam Team</h1>
    <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/2001.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">

<p>Coach: Felice Belle</p>
<p>Photo by Robin Twoney<br />
<a href="/2001Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2001-malcolm.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>malcolm barrett</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2001-ainsley.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>ainsley burrows</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2001-mayda.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>mayda del valle</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2001-courtney.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>courtney martin (Alternate)</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2001-jade.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>jade sharma</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam2001
