import React from 'react'
import ImageGallery from '../image-gallery';

const ClareUltimo = () => {
  return (

    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/photos-clare.jpg" alt="" />
        </div>
      <div className="col-md-8">
      <h2 className='champs-header-main'>clare's nuyorican slam "family" album</h2>
    <ImageGallery />
    <p>Here are some of my Nuyorican snapshots dating from 1998 until 2007. You'll see
photos of National Slams, Spotlight Poets, and Nuyorican staff here; photos from the
after-party when the film "Pinero" opened in 2001, and some of my favorite photos of
people like Felice Belle and Lois Griffith. At the end, I've included the photography of
my friend Jody Berenblatt, who has taken many photos for the Nuyorican. This is a
series of Miguel in action, and I really love it.</p>
<p>Lots of people and moments are missing here so this is a very brief history of my
affectionate Nuyorican eyes.</p>
<p>This photo of me taken by Felice Belle in the Nuyorican alleyway in 2007. Read "How
This Project Came To Be" to get my backstory.Photos subject to copyright and
cannot be used without permission.</p>
      </div>
      </div>

    </div>

  )
}

export default ClareUltimo
