import React from 'react'

const PeterMemo = () => {
  return (
    <div className="row">
          <h1>Memo to Midnight Dove</h1>
<p>by Peter of the Earth</p>
<p>
<pre>
Me and you must never part<br />
Makki Dadda<br />
Makki Dadda<br />
We so close you know my heart<br />
<br />
Where<br />
	Truth<br />
		Lays<br />
			Hidden<br />
Buried beneath the seems of Time<br />
And we…<br />
<br />
Adorn its fabric<br />
<br />
When I am completely honest<br />
I admit to loving you more than I ever have any man<br />
Try to see the what ifs that everyone else says is clear<br />
And<br />
I can<br />
And it<br />
Is<br />
Good<br />
Like seven days of creation<br />
But there is where it ends, see<br />
Agents Mulder and Scully can only be friends<br />
The day they get together is when the series ends<br />
Sam could never marry Diane<br />
Understand Urkel-Laura tension must continue<br />
Few television couples can seldom be because<br />
They keep other people’s passion burning<br />
Along with their own<br />
<br />
If we were to give in<br />
The sun might burn out<br />
Or at least a fuse<br />
Look<br />
What we’d create…<br />
<br />
No, wait –<br />
I contemplated the what-ifs sincerely<br />
In fields where we watched our reflections look<br />
Back on us and laugh<br />
Maybe this was the way we would always be<br />
Maybe this way we will always be<br />
<br />
Have movie sequels<br />
Special television Christmas reunions after twenty years<br />
‘Cause even Cheers ran out of stories told.<br />
<br />
Maybe this way we’ll be immortalized<br />
On late night television syndication<br />
Playing on every station around the world like<br />
“The Honeymooners” starring you and me<br />
Forever…<br />
<br />
Maybe—<br />
No—<br />
Only this way can we look back on our glory days<br />
Wrapped in some rainy evening<br />
In some smoky café<br />
Where we finally had a Friday night commitment<br />
That freed us from TGIF<br />
And when we left<br />
After winning nationals a second time<br />
Saw the world unraveled<br />
Time’s threads<br />
Revealing truth’s secrets<br />
Left naked<br />
Unabashed<br />
And beautiful<br />
<br />
We’ll have stories<br />
Stories to tell our children’s children’s children<br />
Woven into them like our parent’s parent’s stories<br />
Are into us<br />
<br />
Maybe<br />
Just maybe, baby<br />
Meet me in that field when we’re 82.<br />
60 years from now<br />
It’ll be the Sunday before Labor Day<br />
You’ll be wearing red<br />
I’ll be wearing blue<br />
We’ll reflect on our younger selves<br />
Look back and laugh<br />
Drink herbal tea<br />
Play spades<br />
Listen to our voices etched into whatever recording device is then being used<br />
Some young one will have to turn it on for us<br />
And we can stay until the Angels take us home<br />
Or<br />
We grow tired<br />
Or<br />
Whatever<br />
<br />
Because sometimes “I don’t know”<br />
Is the only answer that won’t confine our freedom<br />
<br />
Our younger selves will sew us caterpillar ribs and moth wings<br />
Swing dance until the thunders come down<br />
We’ll sing Lauryn Hill songs<br />
Be butterflies<br />
Leaving hurricanes<br />
Where we once left<br />
This world<br />
<br />
<br />
– Peter of the Earth<br />
<br />
<br />


</pre>
</p>


    </div>
  )
}

export default PeterMemo
