import React from 'react'

const OpenLois
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-lois.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>lois elaine griffith</h1>
      <h2 className='sub-head'>First Open Room Host at the Nuyorican</h2>
    <p>Lois Elaine Griffith has her roots in Afro-Caribbean traditions and is one of the founding poets of the Nuyorican Poets Cafe. She is a graduate of Barnard College where she majored in religion, then went on to Pratt Institute to develop her talents in the visual arts, particularly graphic design and printmaking. Her challenge has always been to say what cannot be said. In the 1970s when she became part of the Nuyorican Poets Cafe, she discovered the power of poetry.</p>
<p>After winning a NYSCA grant for poetry, she was encouraged to embrace the value of her own unique experience as a vehicle of expression. She began to explore other forms of writing that lead her to write for the theater. In 1979, Joseph Papp produced her play "White Sirens" at the Public Theater. In 1980 "Coconut Lounge" was produced at the Nuyorican, followed the next year by "Dancehall Snapshots". In 1984, Theater for the New City produced her play "Hoodlum Hearts".</p>
<p>Currently she is a writer and professor of English at Borough of Manhattan
College, and continues her work with the Nuyorican to fulfill its mission to create a
stage for presenting the freshest art outside the mainstream of American and
world culture. Next year, Steve Cannon and Gathering of the Tribes
Publishers will publish a collection of her poetry, "Dedications".</p>
<p>Photo by David Sanders.</p>
      </div>
      </div>

    </div>
  )
}

export default OpenLois

