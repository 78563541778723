import React from 'react'
import { Link } from 'react-router-dom'

const JivePoetic

 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-jive.jpg" alt="" />
      </div>
      <div className="col-md-8">
      <h1 className='title-bold'>jive poetic</h1>
      <h2 className='sub-head'>Wednesday Night Slam Open Host: 2007 to present</h2>
    <p>"Jive Poetic will write until he flatlines"...</p>
<p>From his hometown in Buffalo, NY to London, England Jive has ignited
microphones with humorous wordplay and thought provoking verbal display.
During a lengthy deliverance of revolutionary regression, to teen suicide and sex,
he opts to teach the masses a lesson in life. His incentive? Watching the reaction
of his listeners.</p>
<p>Jive began to season his art form through feature performances at local New York
and New Jersey venues earning his stripes as a verbal connoisseur after rave
performances at the Brooklyn Moon to Serengeti Plains. The University of Buffalo
graduate would later return home to claim Buffalo New York's Grand Slam title in
2002.</p>
<p>He has acquired slam titles including Tri-City Grand Slam Champ, Across The
Border Team Slam Champ, Njozi Grand Slam Champ, Buffalo Poet of the Year,
Nuyorican National Slam Team Member 2003, Providence National Slam Team
Member 2004, AIPF Grand Slam Champ 2004, TransAtlantic UK Team Slam
Champ 2003 and Pensacola Grand Slam Champ 2004.</p>
<p>While his voice is lightened in humor, his topics are heavy. Jive is preparing to
release his second solo CD "The Pain of a Crowded Solitude" a spoken word
production filled with sensual images and turmoil through the reflection of social
acceptance. "Crowded Solitude" is a follow up project to his First CD "Assault with
a Loaded Statement." As a mindful poet, serious performer and inquisitive
speaker, Jive attempts to take his listeners in to a mind state beyond the obvious
and dares you to listen, further.</p>
<p>See <Link to="/national-teams/2003">National Teams/2003</Link>.</p>
      </div>
      </div>

    </div>
  )
}

export default JivePoetic


