import React from 'react'
import { Link } from 'react-router-dom'

const OpenGuy
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-guy.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>guy lecharles gonzalez</h1>
      <h2 className='sub-head'>Open Room Host circa 1998</h2>
    <p>Guy LeCharles Gonzalez was born in New York, NY in 1969, the only child of
Frank and Debra Gonzalez. After their separation when he was three years old,
Guy was raised by his mother, spending most of his childhood in the Claremont
Park section of the Bronx with later moves to Manhattan, Mt. Vernon and
Yorktown Heights, from where he graduated Lakeland High School in 1987.</p>
<p>It was in 5th grade that Guy wrote his first story, after nearly getting caught
plagiarizing because he liked the praise he received for a story taken from a kid's
book of ghost stories so much, he'd decided to copy another. Stashing the book in
his closet, he sat down and wrote his own story that he wishes he remembered
what it was about. Unfortunately, he doesn't, which kind of makes this tangent
irrelevant...</p>
<p>Over the years, he'd write several more stories, most often influenced by his
current taste in books (Ian Fleming, Stephen King, Robert McCammon, Sue
Grafton and Lawrence Block were all early inspirations) and girls (most stories
had to do with him getting the girl in question, usually at the expense of an exboyfriend
cast as the bad guy). He also dabbled in journalism and broadcasting,
writing for his high school newspaper and writing/reporting/directing for its local
public access show.</p>
<p>Post-high school, after a year or two as a full-time Jehovah's Witness where he
began to develop his speaking and debating skills knocking on doors in Northern
Westchester County, he had a crisis of faith - partly inspired by the movie It's A
Wonderful Life, which he saw for the first time at eighteen - and moved back to
the NYC-area, staying with his estranged father in North Bergen, NJ. A year later,
in 1990, he picked up and moved to Miami Beach, FL with a cousin and a friend -
eventually living four blocks from a beach he somehow only went to four times! -
and spent a semester and a half at Miami Dade Community College before
blowing his student loan money and hopping a Greyhound back north.</p>
<p>Having gotten rid of all his winter clothes the year before, he spent his first week
back in NYC sick and ended up signing up for the US Army, leaving three weeks
later for Ft. Dix, NJ and Basic Training/AIT where he trained for six months as the
Army equivalent of a Jiffy Lube "mechanic," followed by another three weeks in Ft
Benning, GA for Airborne School. He then spent the next two years stationed at
Ft. Campbell, KY with the 2/5th Special Forces Group (Airborne) as a Light Wheel
Vehicle Mechanic, doing everything he could to avoid Somalia, Saudi Arabia and
getting dirty working on HMMWV's (aka Hummers), while figuring out what his
next move would be.</p>
<p>Many years, poems and stories later, Guy once again resides in the Bronx, with a
beautiful wife and two amazing kids. He won some poetry slams, founded a
reading series, co-authored a book of poetry, and still writes when the mood hits
him and he has the time. He prefers Pumpkin and India Pale Ales or Skyy Vodka
with cranberry, still reads comic books, and hasn't completely let go of his plans
for world domination.</p>
<p>This bio from Guy's website <a href="http://www.loudpoet.com" rel="noreferrer" target="_blank">www.loudpoet.com</a>. Please see his site for
updates on new books and projects.</p>
<p>See <Link to="/grand-slam-finale/1998">Grand Slam Finales/1998</Link> and <Link to="/national-teams/1998">National Teams/1998</Link>.</p>
<p>Photo by David Huang from www.loudpoet.com.</p>
      </div>
      </div>

    </div>
  )
}

export default OpenGuy

