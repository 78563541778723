import React from 'react'

const GrandSlam1999 = () => {
  return (
    <div>
    <h1>1999 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMASTER: KEITH ROACH
SPONSOR: GRAND MARNIER</p>
<p>Except for Staceyann Chin, poets submitted these bios to me for the 1999 Finale Program, so they may
not reflect projects and accomplishments since that time. <a href="#poster">See original 1999 Grand Slam Finale Program (created by Clare Ultimo)</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1999-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>taalam acey</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1999-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>felice belle</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1999-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>staceyann chin</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1999-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>kamal symonette-dixon</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1999-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>kirk nugent</p>
        </div>
        <img id="poster" src="/1999Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam1999
