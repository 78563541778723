import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const MainNav = () => {
  return (
    <>
    <div className='container home-header'>
      <img className="header-img" src="/verbs-banner.jpg" alt="" />
    </div>
    <Navbar expand="lg">
    <Container>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav><Link to="/verbs-overview">Verbal Overview</Link></Nav>
          <NavDropdown title="SlamMasters & Hosts" id="basic-nav-dropdown">
            <NavDropdown.Item><Link to="/slam-masters">THE NUYORICAN FRIDAY NIGHT POETRY SLAM</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/wednesday-slam-masters">
            THE WEDNESDAY NIGHT SLAM OPEN</Link>
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3"><Link to="/open-room">THE OPEN ROOM</Link></NavDropdown.Item>
          </NavDropdown>
          <Nav><Link to="/grand-slam-champions">Grand Slam Champions</Link></Nav>
          <Nav><Link to="verbs-overview/national-teams">National Teams</Link></Nav>
          <Nav><Link to="/verbs-overview/grand-slam-finale">Grand Slam Finales</Link></Nav>
          <Nav><Link to="/photography">The Photography</Link></Nav>
          <Nav><Link to="/verbs-overview/reviews">What People Say</Link></Nav>
          <Nav><Link to="/media">Media</Link></Nav>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  </>
  )
}

export default MainNav
