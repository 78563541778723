import React from 'react'

const Glossary = () => {
  return (
    <div>
      <h1>Glossary of Terms</h1>

<h2>Calibration Poet</h2>
<p>See "Sacrificial Goat"</p>
<h2>Grand Slam Champion</h2>
<p>Winner of the Grand Slam Finale, an event that happens once a year at the Nuyorican. The idea here is
that this poet is competing with four others who have also won Semi-Final Slams during that year but
emerges victorious on this particular night.</p>
<h2>Grand Slam Finale</h2>
<p>A yearly slam where winners of five Semi-Poetry Slams during the previous year compete against each
other for the Grand Slam Champion title.</p>
<h2>Performance Poetry</h2>
<p>is not specifically created for any competition, but rather it is a hybrid of words and dramatic expression,
much like a miniature theatrical performance. Generally, this would also need to be memorized work. It
can range from a strict metered poetic form to the more broadly prosaic. It does not require a time limit.
And while some performance poetry can become a successful Poetry Slam piece, the term reflects a
performance that doesn't necessarily receive a score.</p>
<h2>Poetry Slam</h2>
<p>is the contest of words in performance; set up within accepted parameters, with judges and often, time
limits. There is also some sort of prize (often monetary) for the winner at its conclusion. See Poetry
Slam Inc. to learn about the inception and worldwide development of Poetry Slam.</p>
<h2>Rounds</h2>
<p>The number of times a poet gets up and performs a poem varies from venue to venue, but it is most
commonly a three-round event, meaning that the poet must have at least three different poems ready to
perform. The winner obviously has gotten the highest score at the final round.</p>
<h2>Run-Off Slam</h2>
<p>Begun at the Nuyorican by Keith Roach, the Run-Off Slam happens after the Grand Slam Finale.
There are usually at least three of these, happening in the subsequent weeks right after the Grand
Slam Finale. Winners of these Slams become teammates on the Nuyorican National Team that year.
Keith's original thinking was that the Run-Off would enable poets who had been Slamming throughout
the year to participate in the National Team experience.</p>
<h2>Sacrificial Goat</h2>
<p>At the National Poetry Slam event, this is called the "Calibration Poet", but at the Nuyorican, we call
these poets "Sacrifical Goats" and it means the same thing. This is a poet who is not participating in the
Poetry Slam that night, but comes up at the start of the show as a way to give inexperienced judges a
chance to practice their scoring skills before the "real" Slammers for that evening perform.</p>
<h2>Scorekeeper</h2>
<p>The person at any Poetry Slam who adds up the judge's scores and reduces them to a single number
for each round. This can be a volunteer from the audience, or someone assigned in the job for the
evening by the SlamMaster. This person is not a judge or a performing poet that evening. At the
Nuyorican, a judge is simply an audience member, and hopefully decent at adding up numbers. No
other criteria is necessary.</p>
<p>Poetry Slam scoring is similar to Olympic scoring, at least that's how we do it at the Nuyorican, and how
it is done at the National event. Five scores between 0 and 10 are received after each poet performs;
lowest and highest are eliminated, and the three central scores are added up to produce the final score
for that round. Decimals are really critical in Poetry Slam. Slams are often won or lost by tenths of a
point!</p>
<h2>Semi-Final Slam</h2>
<p>Nuyorican Poets Cafe has five of these per year, to come up with the five finalists who will be in the
Grand Slam Finale. Each winner of the Semi-Finals automatically gets to participate in the Grand Slam
Finale.</p>
<h2>Slam Open</h2>
<p>The Slam Open is consistently accessible to public participation. You simply come in and sign the book.
The first 20 names compete. Winner of this evening is offered a chance to participate in the Friday
Night Slam. This is the first open door to the Nuyorican Poetry Slam but it's also useful for experienced
Slammers who might use it to practice a new piece in front of a live set of judges and an audience.</p>
<h2>SlamMaster</h2>
<p>The role is many things to many people across the Poetry Slam scene, but at the Nuyorican, it means
that this person is in charge of the Friday Night Slam in a consistent, ongoing manner, over a significant
period of time. This person books Spotlight Poets for the evening, finds audience judges each Friday
Night and is involved in the National Poetry Slam event each year. The SlamMaster may also be known
as the emcee, host, even curator. And FYI, women SlamMasters at the Nuyorican tend to call
themselves "SlamMistress."</p>
<h2>Spoken Word</h2>
<p>encompasses a broad area, and includes Slam Poetry and Performance Poetry. Storytelling and novelreading,
political speeches even...all considered Spoken Word. Reading a poem, (contrasted to
"performing it") is obviously a Spoken Word event.</p>
<h2>Time Limit</h2>
<p>There is no time limit to the length of a Slam Poet's performance at the Nuyorican, but through the
years, a three-minute time limit has been strictly enforced at the National competition, with points taken
off your score if you exceed. But Performance Poets, for instance don't focus on keeping it within a time
limit, as their work may not be created for Poetry Slam alone.</p>
<p>Most artists interested in the Poetry Slam model will tend to keep their pieces within a reasonable
amount of "short-attention-span" time and their goal is generally the three-minute time frame.</p>
    </div>
  )
}

export default Glossary
