import React from 'react'

const GrandSlam1994= () => {
  return (
    <div>
    <h1>1994 Nuyorican Grand Slam Finale</h1>
    <p>Verifying each participant of the '91 through '95
Nuyorican Grand Slam Finales is still in process.</p>
  </div>
  )
}

export default GrandSlam1994
