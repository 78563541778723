import React from 'react'
import { Link } from 'react-router-dom'

const ArchieTheMessenger = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className="img-fluid champ-photo" src="/archie.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Archie The Messenger</h1>
      <h2 className='sub-head'>2006 Nuyorican Grand Slam Champion</h2>
<p>A phenomenal poet and teacher, Archie the Messenger (ATM) has touched the
hearts and minds of people of all races, classes and educational backgrounds. He
is a matriculating senior at Morgan State University where he studies both
philosophy and telecommunications. From his hometown Buffalo, New York to
Baltimore, Maryland ATM has used spoken word theatre as healing and
entertainment for the urban community. Aside from being a student and
performer, Archie The Messenger spends most of his time with his students at
Baltimore Talent Development High School where he teaches Language Arts and
Oral Interpretation to aspiring teens.</p>
<p>Archie the Messenger's honors and accolades include winning the NAACP's Act-
So National Slam Championship, the New Africa House Slam, the Last Poet
Standing 2004 championship, a Rip Da Mic Slam prize, the Njozi Poets Slam, and
the Cleveland Tri City Slam Series. He was also a finalist in the Baltimore-vs.-DC
Slam Last Poet Championship and a member of slam troupe Morgan's Battle of
Da Skoolz Team. He has been performing on the national spoken word circuit for
over seven years.</p>
<p>See <Link to="/grand-slam-finale/2006">Grand Slam Finales/2006</Link>.</p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/CL4V1VZfWRk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default ArchieTheMessenger
