import React from 'react'

const NatTeam1990 = () => {
  return (
    <div>
      <h1>1990 Nuyorican National Slam Team</h1>
      <div className="row">
        <div className="col-md-4">
          <img src="/nat-teams/1990-paul.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>paul beatty</p>
        </div>
      </div>
    </div>
  )
}

export default NatTeam1990
