import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const ImageGallery2 = () => {
  return (
    <div>
                <Carousel interval="1000" transitionTime="1000">
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/01.bryonn1.jpg" alt="" />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/02.bryonn2.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/03.kirkbryonn.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/04.kirk bryonn.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/05.celena.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/06.anthonycel.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/07.cristin.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/09.HalFeliceB.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/10.halSirow.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/11.helena.yolanda.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/12.onamae.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/13.clare.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/14.dot.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/15.khalil.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/16.nathan.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/17.greatshihan.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/18.mayda.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/19.mayda.court.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/20.tehut.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/21.ainsley.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/22.bigsmoke.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/23.jenmurphy.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/24.kevinoneil.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/25.SteveCon.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/26.Tammy.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/27.celena2.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/28.cory.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/29.eyesaudience.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/29.poet3.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/30.Poet2.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/30.scores.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/31.listen.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/32.closeupclappin.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/32.rockybooth.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/33.beerboxes.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/34.bathwall.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/35.greatcolorfulbar.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/03.Syreeta/03.SyreetaPhotos/36.great mic.jpg" alt=""  />

                    </div>


                </Carousel>
            </div>
  )
}

export default ImageGallery2
