import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const ImageGallery1 = () => {
  return (
    <div>
                <Carousel interval="100" transitionTime="100">
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/01.outdoor.jpg" alt="" />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/02.endaud.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/03.scoreaud.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/04.kirkopen.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/05.kirkscrem.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/06.keithaud2.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/07.juliet.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/08.kayo.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/09.miguel1.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/10.miguel2.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/11.mumsup.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/12.mumsfun.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/13.mumsmo.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/14.keithsilo.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/15.handscore.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/16.keithstage.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/17.keithGS.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/19.keithhug.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/20.keithaud.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/21.twins1.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/22.twins2.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/23.twins3.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="//PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/24.jamaal.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/25.bassey.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/26.audback.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/27.felice1.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/28.peterinAud.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/29.felicepeter.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/30.safari.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/31.talaam.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/32.pablo.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/33.chin.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/34.letson.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/35.dot.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/36.julio.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/37.pepe.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/38.rocky1.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/39.SteveCann.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/39.rocky2.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/40.flowment.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/41.oneill.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/43.morris.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/44.onemae.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/45.sarah3.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/46.sarah2.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/47.sarahup.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/48.steve.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/50.bodysilo1.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/51.artsyguy.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/52.galcig.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/53.stairwell.jpg" alt=""  />
                    </div>
                    <div>
                        <img src="/PhotographyPage/01.MichaelMeyer/1. MichaelPhotos/54.beaumic.jpg" alt=""  />
                    </div>

                </Carousel>
            </div>
  )
}

export default ImageGallery1
