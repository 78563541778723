import React from 'react'

const EvertEden
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4"> <img className="img-fluid champ-photo" src="/hosts-evert.jpg" alt="" /></div>
      <div className="col-md-8">

      <h1 className='title-bold'>evert eden</h1>
      <h2 className='sub-head'>Evert hosted the Wednesday Night Slam Open from 1992-1994,
but also hosted The Open Room as well as the Friday Night Slam
during this time.</h2>
    <p>Evert Eden is a writer-performer who grew up in apartheid South Africa. He
moved to New York in 1980. He writes novels, plays and articles, and has sold a
screenplay to Hollywood.</p>
<p>He has performed his poetry and a one-man show, “How To Cook A Man,” on
three continents.</p>
<p>In 1979 his play, “A Very Butch Libido,” was banned by the apartheid regime in
South Africa. His poetry is anthologized in “Aloud: Voices from the Nuyorican
Poets Cafe,” “The Outlaw Bible of American Poetry,” “Revival: Spoken Word from
Lollapalooza,” “Poetry Nation,” and “Jane For A Day,” as well as on the CDs
“Family Matters,” “Nuyorican Symphony,” “Urbana Poetry,” and his own CD “Suck
My Poems.”</p>
<p>In 1994 he toured as a performing poet with the rock festival Lollapalooza. From
1992 to 1994 he hosted poetry slams every week at the Nuyorican Poets Cafe. As
a slam poet, he was part of the winning Mouth Almighty Team from Manhattan at
the 1997 National Poetry Slam, and came second in the 1996 individuals. He won
through to the finals of the National Poetry Slam four times.</p>
<p>His one-act play, “40 Acres and an SUV,” written with Aviva Jane Carlin (“Jodie’s
Body”) had a two-week run at the Sacramento Theater. His play, “The
Interrogation,” was produced at the O’Neill Playwrights Conference.He’s been a
guest lecturer in creative writing at NYU. He founded the performance group “Poet
Nation,” an ensemble of six black, white, Latina, female, male, gay, lesbian and
straight performers. As an advertising copywriter he is best known for his work on
Absolut Vodka.</p>
<p>He appeared in the documentary “Slammin’” shown on PBS. On World AIDS Day
2001 he performed for world leaders and celebrities at the UN. He fronts a rock
band, The Dingbots, and has released a rock opera album on CD, "Kid Radar."</p>
      </div>
      </div>

    </div>
  )
}

export default EvertEden

