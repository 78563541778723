import React from 'react'
import { Link } from 'react-router-dom'

const PeteSpiro = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/PeteSpiro.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Pete Spiro</h1>
      <h2>1993 Nuyorican Grand Slam Champion</h2>
    <p>Peter Spiro is a playwright and poet. His plays have been produced in New York
City and Los Angeles; his poetry has been widely published in magazines and
anthologies. Pete has appeared on the PBS special "The United States of Poetry"
and MTV's Spoken Word Unplugged, and he recently gave a reading/interview on
National Public Radio. (We had a hard time finding information as to Pete's most
recent where and whatabouts, but this short bio was found below an essay he
wrote on the Monroe Institute website, which explores cutting edge mind
technology. The essay is great by the way, so please check it out).</p>
    <p>See <Link to="/national-teams/1992">National Teams/1992</Link>.</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/2lxQH3SKEKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p>"Work" by Pete Spiro (7:07 AM) Video from The United States of Poetry episode "A Day in the Life"</p>
      </div>

      </div>
    </div>
  )
}

export default PeteSpiro
