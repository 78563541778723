import React from 'react'

const NatTeam2002 = () => {
  return (
    <div>
    <h1>2002 Nuyorican National Slam Team</h1>
    <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2002.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">

<p>Coach: Felice Belle</p>
<p>Photo: Clare Ultimo<br />
<a href="/2002Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2002-kahlil.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>kahlil almustafa</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2002-julia.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>julia barnes (Alternate)</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2002-devynity.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>devynity</p>
     </div>
   </div>
   <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/2002-kamal.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>kamal symonette-dixon</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2002-heru.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>heru ptah</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam2002
