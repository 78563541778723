import React from 'react'

const Accuracy = () => {
  return (
    <div>
      <h1>2022: ACCURACY & UPDATES</h1>
      <h2>2022 > To the Historically Verbal:</h2>
      <p>This project was created in 2008 as my thesis for an MFA in Integrated Media Arts at Hunter College.  This archive holds information from 1990-2007, and it's important to remember that nothing past 2007 is included. This 2022 revise of Verbs on Asphalt was not about adding new information; it was revised because original software no longer "worked" across newer platforms and it had been unavailable online until now.</p>

<p>The original project was 342 pages, and included 24 videos edited by me. All the videos are here, as well as all the original materials, with the following notes:</p>

<p><b>1.  Grand Slam Finale and National Team pages:</b> The poet bios I was given while creating the Grand Slam Finale programs and National Team materials for the Nuyorican were removed because these (very early) bios would no longer be relevant in 2022. Most of these poets have continued to develop as accomplished artists, teachers, performers, writers and leaders in their community. I would prefer that readers look those poets up online and find their most current activities and accomplishments, which are numerous. </p>

<p><b>2. What People Say page</b> in the original project had many more links than are now available in 2022. That page has been revised and limited to only those links to publications posted in 2007 that are still available now. Most of those original links, while great examples of the power of Nuyorican Poetry Slam in the media, are no longer available today to view.</p>

<p><b>3. Sad passing of many Nuyorican Founding Poets</b> happened since 2008. We lost many beautiful friends and poets and I'm sorry for every poet and teacher who have left us, but I want to particularly note the loss of our Founder, Miguel Algarin, SlamMaster Keith Roach, and my beloved friends, Carmen Pietri-Diaz and Sam Diaz.
There are only a very few pieces of this 17-year history missing, and my good intentions of expanding and updating this project were not realised – time and money kept getting in the way. The original project was 342 web pages and without outside help, there was no way I could update, revise or expand it beyond it's original form. I am sorry for this, but my intentions were geniune.</p>

<p>Verbs On Asphalt is a primary source, first-hand record of this particular art form as it grew from 1990 at the Nuyorican Poets Cafe in NYC.  I hope it adds to the overall knowledge about the creative soul of the Cafe and a respectful acknowledgement to those artists who helped make it happen.</p>

<p>This project will eventually become a part of the Nuyorican Founding Poets project, headed up by Lois E. Griffith.</p>

<p>Clare Ultimo August 2022</p>

    </div>
  )
}

export default Accuracy
