import React from 'react'
import { Link } from 'react-router-dom'

const MahoganyBrowneWeds
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-mahogany.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1 className='title-bold'>mahogany browne</h1>
      <h2 className='sub-head'>Wednesday Night Slam Open Host: 2005-07</h2>
    <p>Mahogany L. Browne, born in Oakland, California, has been performing on the
intensely creative performance poetry scene since early 1998. As a member of
the highly regarded safe sex crew, The Punany Poets (as seen on HBO's Real
Sex 24 & 26) Mahogany aspires to alert the Hip-Hop generation with safer sex
methods. Fueled by her position as a freelance journalist of the Hip Hop culture,
Mahogany created the Hip-Hop Poetry educational program as New Professional
Theatre's Educational Director, to implement a healthy relationship between the
music and our children. She currently facilitates workshops throughout New York
Public School System and select group home facilities in Europe & the UK.
Mahogany can also be heard on radio airwaves throughout the country as the
femme fatale poetic voice on Hennessy's national radio advertisement, Poetry
Minute and Music Choice's premier cable channel.</p>
<p>Her past performances include special appearances on CBS' talk shows The
Ananda Lewis Show and Iyanla Vanzant Show and she presently completes the
Black Secret Soul 2005 European tour, her fifth tour overseas to date. Ms.
Browne has earned the highly-acclaimed title as Grand Slam Champ of
Louderarts (2004), Nuyorican Poets Cafe Grand Slam Semi-finalist (2002, 2003,
2004) in addition to winning Harlem's SugarShack Slam, Jimmy's Uptown and
Buffalo, NY Njozi Magazine's Tri-State Team Slam. Author of Unlikely and Other
sorts (Penmanship Books), Thin Skin (Cherry Moon Publishing) a collection of
poetry and essays, Mahogany has released four LPs including the industry
favorite Black Secret Soul (featuring production by DJ Static and DJ Doughboy),
LADYSMITH: Untitled & her newest addition Sheroshima. As co-founder of the
off-broadway poetry production, "Jam On It", Mahogany readies for change.
Bridging the gap between poets and emcees, Mahogany continues her freelance
journalist works which can be found in the pages of internationally distributed
magazines "KING", "XXL", "The Source", Canada's "The Word" and UK's
"MOBO".</p>
<p>See <Link to="/slam-masters">Friday Night SlamMasters</Link> <br />
 <Link to="/grand-slam-finale/2006">2006 Grand Slam Finale</Link> <br />
<Link to="/national-teams/2006">2006 National Team</Link> <br />
<Link to="/national-teams/2007">2007 National Team</Link>.</p>
      </div>

      </div>

    </div>
  )
}

export default MahoganyBrowneWeds

