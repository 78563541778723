import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const ImageGallery = () => {
  return (
    <div>
                <Carousel interval="100" transitionTime="100">
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/01.signs.jpg" alt="" />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/02.Tonight.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/03.keith.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/04.julio.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/05.Pepe.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/06.nathan.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/07.pepe.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/08.pedro.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/09.mayda.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/10.Carlos.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/11.brady.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/12.daniel.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/13.taylor.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/14.shayne.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/15.savion.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/16.sav.reg.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/17.Nath.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/18.Ben.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/19.michW.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/20.Sam.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/22.RegE.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/23.nathan.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/24.miguelR.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/25.clarereg.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/26.raul.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/27.trophy.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/28.winn.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/29.tshirt.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/30.paulie.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/31.Syreeta.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/32.carmen.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/33.felice.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/34.Rehe.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/35.beau.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/36.team.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/37.marc.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/38.Caption.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/39.ClaPais.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/40.felice.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/41.peter.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/42.LoisCap.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/43.carmen.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/44.LoisAlb.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/45.Katrina.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/46.sleepcap.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/47.ainslSleep.JPG" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/48.bradysleep.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/49.andrSleep.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/50.mikesleep.JPG" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/51.ovesleep.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/52.wgirls.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/53.jodycap.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/54.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/55.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/56.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/57.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/58.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/59.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/60.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/61.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/62.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/63.jpg" alt=""  />

                    </div>
                    <div>
                        <img src="/PhotographyPage/02.ClareUltimo/02.ClarePhotos/64.jpg" alt=""  />

                    </div>
                </Carousel>
            </div>
  )
}

export default ImageGallery
