import React from 'react'

const NatTeam1999 = () => {
  return (
    <div>
    <h1>1999 Nuyorican National Slam Team</h1>
    <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/1999.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8">

<p>Coach: Steven Coleman</p>
<p>Photo: Clare Ultimo <br />
<a href="/1999Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1999-taalam.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>taalam acey</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1999-felice.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>felice belle (Alternate)</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1999-lamar.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>lamar hill</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1999-kirk.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>kirk nugent</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1999-faraji.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>faraji salim</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam1999
