import React from 'react'
import { Link } from 'react-router-dom'

const SarahJones = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/sarah.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Sarah Jones</h1>
      <h2 className='sub-head'>1997 Nuyorican Grand Slam Champion</h2>
    <p>Sarah Jones is a Tony Award's winning playwright, actor, and poet. Her multicharacter
solo shows include Bridge & Tunnel, which was originally produced Off-
Broadway by Oscar-winner Meryl Streep, and went on to become a critically
acclaimed, long running smash-hit on Broadway.</p>
<p>Jones’ career has taken her from a sold-out run at The Kennedy Center to tours of
India, Europe and South Africa to performances for such audiences as the United
Nations, members of the U.S. Congress and the Supreme Court of Nepal. Her
multicultural cast of characters has always been a reflection of her diverse
audiences.</p>
<p>A proud Queens, New York native, Sarah attended the United Nations
International School and Bryn Mawr College where she was the recipient of the
Mellon Minority Fellowship, then returned to New York and began writing and
competing in poetry slams at the Nuyorican Poets Cafe. There she developed her
first show, Surface Transit, which was presented at The American Place Theatre
and PS122. Her next piece, Women Can't Wait! was commissioned by Equality
Now to address the human rights of women and girls. A subsequent commission
by the National Immigration Forum yielded Waking the American Dream, the
inspiration for Bridge & Tunnel. Most recently, Jones has been commissioned by
the WK Kellogg Foundation for a piece entitled A Right to Care, which tackles
themes of inequality in health.</p>
<p>Jones has also received grants and commissions from Lincoln Center Theater,
The Ford Foundation, and theater honors including an Obie, a Helen Hayes
Award, two Drama Desk nominations, HBO's US Comedy Arts Festival's Best
One Person Show Award, and an NYCLU Calloway Award in recognition of Jones
as the first artist in history to sue the Federal Communications Commission for
censorship. The lawsuit resulted in reversal of the censorship ruling, which had
targeted her hip-hop poem recording, “Your Revolution.”? A regular uncensored
guest on public radio, she has also made numerous TV appearances on HBO,
NBC, ABC, CBS, PBS, CNN, and in her own special, “The Sarah Jones Show,”?
on Bravo. </p>
    <p>See <Link to="/national-teams/1997">National Teams/1997</Link> and <Link to="/grand-slam-finale/1997">Grand Slam Finale/1997</Link>.</p>
      </div>

      </div>
    </div>
  )
}

export default SarahJones
