import React from 'react'
import { Link } from 'react-router-dom'

const DanielBeaty = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/daniel.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1>Daniel Beaty</h1>
      <h2 className='sub-head'>2004 Nuyorican Grand Slam Champion</h2>
    <p>Daniel Beaty is the award winning actor, singer, writer, & composer of the critcally
acclaimed solo play Emergence-See! which ran off-Broadway to a sold-out,
extended run at The Public Theater this fall. Daniel was most recently seen on
the third and fourth seasons of HBO’s Russell Simmons Presents Def Poetry, as a
guest artist on NBC’s Showtime at the Apollo with Rueben Studdard, and on
BET’s 106 & Park. He holds a BA with Honors in English &Music from Yale
University and an MFA in Acting from American Conservatory Theatre (ACT).</p>
<p>As an actor, singer, and poet, Daniel has worked throughout the US, Europe, and
Africa in a variety of styles ranging from television to solo concerts to theatrical
productions to opera including performance at The White House, The Kennedy
Center in tribute to Ruby Dee and Ossie Davis, and The Apollo. Daniel is the 2004
Grand Slam Champion at the world famous Nuyorican Poet’s Cafe and The Fox
Networks National Redemption Slam Champion. He has performed on programs
with artists such as Jill Scott, Sonia Sanchez, MC Lyte, Mos Def, Tracy Chapman,
Deepak Chopra, and Phylicia Rashad.</p>
<p>As a playwright, screenwriter, and composer, Daniel’s writings have been shared
in collaboration with The Public, BAM, New York Theater Workshop, La Mama,
American Conservatory Theater, Yale University, The NAACP National
Convention, The SCLC National Convention, and various other venues. His show
Emergence-SEE! has been reviewed as a "brilliant, spellbinding performance" by
the Amsterdam News and "a dazzling 90 minutes full of memorable moments”¦
intense”¦hilarious" by the New York Times. For most recent updates on Daniel's
work, please visit <a href="http://danielkoabeaty.com">danielbeaty.com</a>.</p>
<p>See <Link to="/national-teams/2004">National Teams/2004</Link>, <Link to="/grand-slam-finale/2004">Grand Slam Finales/2004</Link> and <Link to="/grand-slam-finale/2003">Grand Slam Finales/2003</Link>.</p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/2QzB0UYUO9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      </div>
    </div>
  )
}

export default DanielBeaty
