import React from 'react'

const GrandSlam2006 = () => {
  return (
    <div>
    <h1>2006 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: KAREN JAIME</p>
<p>Except for ShadowKat, poets submitted these bios to me for the 2006 Finale Program, so they may not
reflect projects and accomplishments since that time. <a href="#poster">See original 2006 Grand Slam Finale Program</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2006-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>archie the messenger</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2006-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>mahogany l. browne</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2006-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>rob hylton</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2006-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>noodles</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2006-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>shadokat</p>
        </div>
        <img id="poster" src="/2006Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam2006
