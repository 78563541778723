import React from 'react'

const GrandSlam2003 = () => {
  return (
    <div>
    <h1>2003 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: KAREN JAIME</p>
<p>Except for Vanessa Hidary, poets submitted these bios to me for the 2003 Finale Program, so they may
not reflect projects and accomplishments since that time. <a href="#poster">See original 2003 Grand Slam Finale Program</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2003-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>daniel beaty</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2003-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>julian curry</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2003-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>dutch</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2003-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>carlos gomez</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2003-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>vanessa hidary</p>
        </div>
        <img id="poster" src="/2003Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam2003
