import React from 'react'
import { Link } from 'react-router-dom'

const TracieMorris = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/tracie.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Tracie Morris</h1>
      <h2 className='sub-head'>1992 Nuyorican Grand Slam Champion</h2>
    <p>Tracie Morris teaches at Sarah Lawrence College. She is a multi-disciplinary poet
    who has worked in theater, dance, music and film. She has toured extensively
    throughout the United States, Canada, Europe, Africa and Asia. Primarily known
    as a "musical poet," Tracie has worked with an extensive range of internationally
    recognized muscians and other artists. She has participated in a dozen recording
    projects. Her sound poetry has most recently been featured in the 2002 Whitney
    Biennial. She is the recipient of numerous awards for poetry including the New
    York Foundation for the Arts Fellowship, Creative Capital Fellowship, the National
    Haiku Slam Championship and an Asian Cultural Council Fellowship. She is the
    author of two poetry collections, "Intermission" and "Chap-T-her Won". She has
    delivered academic papers at the New York University Soul: Black Power, Politics
    and Pleasure Conference; The Hemispheric Conference in Lima, Peru; The
    Langston Hughes Centenary Conference at Yale University; Poetry and the Public
    Sphere at Rutgers University and the African-American Poetry Conference for the
    Poetry Society of America. As a writer, Tracie's poetry has been anthologized in
    literary magazines, newspapers and books including "360 Degrees: A Revolution
    of Black Poets", "Listen Up!", "Aloud: Voices from the Nuyorican Poets Cafe",
    "The Outlaw Bible of American Poetry and Soul". Her words have also been
    featured in commissioned pieces for several organizations including Aaron Davis
    Hall, the International Festival for the Arts, The Kitchen, Franklin Furnace and
    Yale Repertory Theater for choreographer Ralph Lemon.Tracie holds degrees
    from Hunter College and New York University. She also holds certificates from the
    Cave Canem Summer Institute and the Hemispheric Institute of NYU.</p>
    <p>See <Link to="/national-teams/1993">National Teams/1993</Link>.</p>
    <p>Photo is from: <a href="http://deseretnews.com">deseretnews.com</a>
    </p>
      </div>

      </div>
    </div>
  )
}

export default TracieMorris
