import React from 'react'

const EileenReyes
 = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4"> <img className="img-fluid champ-photo" src="/eileen.jpg" alt="" /></div>
    <div className="col-md-8">

    <h1 className='title-bold'>eileen reyes</h1>
    <h2 className='sub-head'>Wednesday Night Slam Open Host circa 1995</h2>
  <p>Aileen Reyes born and raised in New York City is a published Puerto Rican poet,
playwright, and visual artist. Her work has appeared in numerous publications, the
first being Tribes Magazine and the fly by night publication Skyscrapers Taxis and
Tampons.</p>
<p>Her plays have been performed at the Nuyorican Poets Cafe (Five Minutes of
Silence), Dancespace (Sadico) as part of Raw Material, And the Here Arts Center
(sadico) as part of The Downtown Urban Theater Festival. More recently she
worked with a group of an diego poets, directing editing and participating in a
multimedia piece entiled Lavanderia, recounting the history of washerwoman all
over the world through choreopoem, photomontage and music. The piece was
performed throughout San Diego, beginning with San Diego City College. Her
debut as a visual artist was made at Tribes Gallery in 1999 with her collection of
mixed media sculptures (different Levels of Insanity ).</p>
<p>Her current (Recycled Storylines) collection , mixed media sculptures made from
glass and paper made its debut At Voz Alta Project in san diego april 7 -may 3
2007this series of colorful sculptures, strongly influenced by her travels to the
carribbean, depicts Puerto Rican figures, and historical as well as personal
events. She lives (peacefully) writes (diffrerently) and creates (magically) in San
Diego California (for now...).</p>
    </div>
    </div>

  </div>
  )
}

export default EileenReyes

