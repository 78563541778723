import React from 'react'
import { Link } from 'react-router-dom'

const AndrewTyree = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className="img-fluid champ-photo" src="/andrew.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Andrew Tyree</h1>
      <h2>2005 Nuyorican Grand Slam Champion</h2>
    <p>Born Andrew Tyree Wilson Clinkscale, in Nuremberg, Germany, Andrew began
dancing at the age of four and playing the violin at five. He eventually became the
youngest person and the only African American to tour with the Milwaukee Youth
Symphony Orchestra, and to receive a scholarship to the Milwaukee
Conservatory of Music. At fourteen he began modeling for JCPenneys and
Marshalls.</p>
<p>In 2003 with enough determination to fill the Grand Canyon, Andrew set out to
New York to begin an acting career. His first stop was the Nite Star Acting
Ensemble, where his love for poetry began. Since then Andrew has gone on to
act in six Off-Broadway productions, win the 2004 National Slam Bush Poetry
Slam, and to perform at the Nuyorican and The Bowery Poetry Club, Food for
Thought and a number of others. He has also written and stared in his own stage
play titled, "Therapy Session." Upcoming projects include a TV pilot, and a couple
of independent films. Andrew is an artistically driven man, destined for success.
More about Andrew: <a href="https://www.imdb.com/name/nm5175561">IMDB</a></p>
<p>See <Link to="/national-teams/2005">National Teams/2005</Link> and <Link to="/grand-slam-finale/2005">Grand Slam Finales/2005</Link>.</p>
      </div>
      </div>
    </div>
  )
}

export default AndrewTyree
