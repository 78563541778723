import React from 'react'

const OpenRich
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-rich.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>rich villar</h1>
      <h2 className='sub-head'>Open Room Host circa 2004-2006</h2>
    <p>Rich Villar's written work can be found in the journals Rattapallax 13 and Parse:
Volume I; and is forthcoming in the inaugural issue of Achiote. In 2004, he
appeared with Team NYC-louderARTS as part of the National Poetry Slam in St.
Louis; the following year, he was nominated for a Pushcart Prize. His work was
commissioned for Actors Stock NYC's production of "Eve Descending", in which
he also appeared.</p>
<p>Rich has read, lectured, and facilitated adult and teen poetry workshops
throughout the Northeast and beyond. He is the host and curator of Acentos, a
Bronx-based bimonthly reading series celebrating the work of Latino poets. He is
currently at work on his first full-length manuscript, tentatively titled "Noche
Buena".</p>
<p>For more about Rich: <a href="https://www.poetryfoundation.org/poets/rich-villar">https://www.poetryfoundation.org/poets/rich-villar</a></p>
<p>Photo by Peter Dressel</p>
      </div>
      </div>

    </div>
  )
}

export default OpenRich

