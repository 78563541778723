import React from 'react'
import { Link } from 'react-router-dom'

const NathanPWeds
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-nathan.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1 className='title-bold'>nathan p</h1>
      <h2 className='sub-head'>Wednesday Night Slam Open Host: 2000 - 2005</h2>
    <p>Nathaniel Pearson Jr., aka Nathan P., stumbled upon the poetry scene quite by
accident. A motivational speaker and human services director by trade, it was the
prompting of a speaking colleague that got him to attend his first reading in the
summer of 1999. He went reluctantly because he had no interest in poetry. In his
mind it was all bunny rabbits and daffodils. Well, before the night was over, the
bug had bitten him, so much so that he presented an affirmation of success that
he would utilize in his Career Development Workshops. That night on the train
ride home, on scrap of paper, he wrote the first poem he had written since grade
school - which became the title.</p>
<p>When the summer series was over, Nathan was still jonesing for poetry.
Someone told him about the Nuyorican Poets Cafe, which he found one Friday
night in August, (after an exhaustive search uptown on 3rd Ave, in the 80’s). Little
did he know that he had found his home for the rest of his poetry life!</p>
<p>Nathan started attending the Weds slams, and eventually became a Grand Slam
finalist for the year 2000, his first and only year of slamming. The love of the cafe
was such that he would attend every Weds session and Friday session including
the open room. It was an event at Washington Square Park, "Voices in the
Square", at which then Slam Master Keith Roach, was short of poets, spotted
Nathan in the crowd and asked him to perform a piece or 2. Years later Keith told
Nathan that, that very afternoon, Keith saw something in the way Nathan handled
the crowd. And when fate had it that the Weds night host was unable to make it
one night, Nathan was suggested as a fill-in, which lead to being the call to guy
when there was a need on Weds. When that years nationals occurred, Nathan
filled in for the Friday slot as well. This was the launching of his hosting career
which officially began in September of 2000, when Celena Glenn stepped down,
handing the Weds night slot to him. A position he held for 5 years, until
September of 2005, when he took the reigns of the Friday night slam, hosting that
event until Jan ’07. Those six years has made him the longest reigning host in the
cafe’s history, and has made him a member of the Nuyorican family for life, which
is a place he is proud and honored to be associated with.</p>
<p>During his tenure, he has had a lot of notables cross his stage including most of
the performers from Def Poetry Jam, (the television show and Broadway), Savion
Glover, Mos Def, and Malcolm Jamal Warner to name a few. He has also hosted
events across the east coast, including the Apollo Theater. His hosting
performances became the benchmark that other host measured themselves by -
at least that’s what they tell him! And when the mood was right, he’d even "wow"
the crowd with a bootleg Prince impersonation! Performing poetry sort of took a
back seat to hosting, so much so that after a while very few people on the scene
would even know he was a poet.</p>
<p>But his poetry career flourished as well. Nathan has performed in colleges and
universities across the country, has performed as part of the group Machismo
Redefined, appeared in 2 poetic plays, "Be Beautiful" and "An Evolution in
Reinvention", and has had many appearances/interviews, including full _ shows
devoted soley to him broadcast on various cable outlets.</p>
<p>He has completed 2 books of poems, "Madness" and "The Colors of My Mind",
as well as a solo CD, "Chalklines on Black Asphalt". He appears on several other
CD projects too numerous to mention. But after 8 years on the scene, hosting on
the biggest stage in poetry, and meeting the challenge to himself to complete a
book &/or CD, Nathan P. decided that he had had his 15 minutes of fame! So in
stepping down from the Nuyo in Jan ’07, Nathan continues to focus on his work in
the "real world", site director for an organization contracted by the city to help
Welfare Reciepients become gainfully employed. He does perform periodically, in
mean time he just asks that you "Keep That Energy Going!!"</p>
<p>Photo by Clare Ultimo.</p>
<Link to="/grand-slam-finale/2000">2000 Grand Slam Finale</Link> <br />
<Link to="/slam-masters">Friday Night SlamMaster</Link>
<div className="row">
  <div className="col-md-6">
    <img src="/nathan-inner.jpg" alt="" className="img-fluid" />
  </div>
  <div className="col-md-6">
    <p className='caption'>Nathan's collectable Nuyorican
SlamMaster's Magnet, given out on
his last night as SlamMaster.
(Striking resemblance to You-Know-
Who).</p>
  </div>
</div>
      </div>

      </div>

    </div>
  )
}

export default NathanPWeds

