let hosts = [
{
  name: "Bob Holman",
  picture: "host-bob.jpg",
  slug: "bob-holman",
},
{
  name: "Keith Roach",
  picture: "hosts-keith.jpg",
  slug: "keith-roach",
},
{
  name: "Felice Belle",
  picture: "hosts-felice.jpg",
  slug: "felice-belle",
},
{
  name: "Karen Jaime",
  picture: "hosts-karen.jpg",
  slug: "karen-jaime",
},
{
  name: "Nathan P",
  picture: "hosts-nathan.jpg",
  slug: "nathan-p",
},
{
  name: "Mahogany Browne",
  picture: "hosts-mahogany.jpg",
  slug: "mahogany-browne",
},
];

export function getHosts() {
return hosts;
}
