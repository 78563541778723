import React from 'react'

const NatTeam1994= () => {
  return (
    <div>
    <h1>1994 Nuyorican National Slam Team</h1>
   <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/1994-tish.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>tish benson</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1994-bobby.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>bobby miller</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1994-carl.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>carl hancock rux</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1994-cheryl.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>cheryl boyce taylor</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam1994
