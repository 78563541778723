import React from 'react'

const NatTeam1991 = () => {
  return (
    <div>
      <h1>1991 Nuyorican National Slam Team</h1>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1991-nophoto.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>gavin moses</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1991-willie.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>wille perdomo</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1991-adrienne.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>adrienne su</p>
        </div>
      </div>
    </div>
  )
}

export default NatTeam1991
