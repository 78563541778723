import React from 'react'
import { Link } from 'react-router-dom'

const DanaBryant = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/dana.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Dana Bryant</h1>
      <h2 className='sub-head'>1992 Nuyorican Grand Slam Champion</h2>
    <p>"Dana Bryant grew up in Brooklyn, New York. She made her poetry debut in 1991; in 1995, she released her first book of poems, ""Song of the Siren"" (Boulevard Books/Putnam Berkeley) and the following year her debut solo album, ""Wishing From the Top"" on Warner Bros. Records. She has performed in Europe and Japan with artists such as Speech (of Arrested Development), Zap Mama, PM Dawn and Ronnie Jordan. She is featured in ""The Rolling Stone Book of Women
    In Rock"" and the ""International Who's Who of Music"". Bio is from http://www.
    cosmoetica.com/TOP21-DES19.htm.</p>
    <p>See <Link to="/national-teams/1992">National Teams/1992</Link>.</p>
    <p>Photo is taken from: Route Magazine"
    </p>
      </div>

      </div>
    </div>
  )
}

export default DanaBryant
