import React from 'react'
import { Link } from 'react-router-dom'

const TishBenson = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/tish.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Tish Benson</h1>
      <h2 className='sub-head'>1994 Nuyorican Grand Slam Champion</h2>
    <p>turah (tish benson) is an experimental video and ancient funk performance artist
hoodoo shaman water bone rootworker who writes spells that are converted into
profit making award winning movies for hollywood. She builds her writings, voices
and scents from doing indepth anthropological fieldstudy and research of ancient
khemit juju based on her family's secret dreamworking and callin' forth the dead.
For the past 7 years she has been living mainly on a stipened given to her by the
Esther Walker Benson Foundation for Southern Black Girls who grew up with no
father and a po' black struggling momma who tapped into high divine mind
manifestation, Inc. This stipened has enabled her to do research on mythology in
urban culture (from creationism to dark goddess to death to rebirth) and develop
the town of Scenicville Texas as a testimony to the memory of them first african's
who never forgot their superpowers in spite of slavery and niggaraciousness.</p>
    <p>See <Link to="/national-teams/1994">National Teams/1994</Link>.</p>
      </div>

      </div>
    </div>
  )
}

export default TishBenson
