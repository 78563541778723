import React from 'react'
import { Link } from 'react-router-dom'

const paulBeatty  = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className='img-fluid champ-photo' src="/paul-beatty.jpg" alt="" />
</div>
        <div className="col-md-8">

        <h1>Paul Beatty</h1>
<h2 className='sub-head'>1990 Nuyorican Grand Slam Champion</h2>
<p>The very first Nuyorican Grand Slam Champion has long since left his Poetry
Slam days but remains a consummate writer and teacher today.</p>
<p>"I was born in Los Angeles in 1962. Raised on kung fu triple features, samurai
movies with no swordplay, V-8, Philly cheesesteak sandwiches from Al's
Sandwich Shop, and my mother's frayed paperback library. I write because I'm
too afraid to steal, too ugly to act, too weak to fight, and too stupid in math to be a
Cosmologist. As a result two volumes of poetry, "Big Bank Take Little Bank" and
"Joker, Joker, Deuce", and two novels, "The White Boy Shuffle" and "Tuff". Beatty
lives and works in New York. He received an M.F.A. in creative writing from
Brooklyn College and an M.A. in psychology from Boston University". This bio
taken from http://authors.aalbc.com/paulbeatty.htm</p>
<p>See  <Link to="/national-teams/1990">National Teams/1990</Link>.</p>
<p>Photo credit:
http://authors.aalbc.com/paulbeatty.htm</p>
        </div>

      </div>
    </div>
  )
}

export default paulBeatty

