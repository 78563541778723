import React from 'react'

const OpenJuliet
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-jeffrey.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>jeffrey feller</h1>
      <h2 className='sub-head'>Open Room Host circa 1999-2004</h2>
    <p>"I found out about the Nuyorican Poets Cafe by way of Rosendale, NY. In 1989
when I was a resident of New Paltz NY, The Space Co-Op featured Miguel
Algarin and four Nuyorican poets who came to read there. A year later, I moved to
NYC and needing a place to hear the "work" and read my own stuff, the
Nuyorican became my logical destination. Evert Eden was hosting the Open
Room for a while, liked my work and encouraged me to read on Wednesday
nights. I became a familiar face at the Friday Night Slams and since my math
skills are pretty good, I volunteered to be the ScoreKeeper as well, where I found
a niche for myself.</p>
<p>"When an opportunity came to host the Open Room, I jumped into it and hosted
for over four years; even filling in occassionally for the Friday Night Slam. My cohost
in the Open Room was the 'Purple Bastard', a crash dummy with removeable
body parts. When I pulled an arm or leg off during the Open Room, it was my
polite way of letting the poet know their three minutes had elapsed. Of course, it
was rare that I ever had to resort to this! Eventually, I gave up the Open Room
spot to ATryan.</p>
<p>"I created and co-hosted 'Poetry For a Sunday Afternoon' with Rico Steal; it was
an Open Mic with spotlights. It was at the Cafe that I met a woman who
introduced me to someone who hired me to be his assistant at Yankee Stadium.
I've worked there during the baseball season for 11 years.</p>
<p>"I am grateful that the Nuyorican has been and continues to be my oasis and my
oxygen mask. Props to everyone especially Keith, Julio, Pepe, Clare, Carmen,
Lois, Sam, and of course, Miguel."</p>
<p>(Jeffrey is currently studying acting in NYC at The Actors Studio.)</p>
      </div>
      </div>

    </div>
  )
}

export default OpenJuliet

