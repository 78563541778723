import React from 'react'

const GrandSlam1996 = () => {
  return (
    <div>
    <h1>1996 Nuyorican Grand Slam Finale</h1>
<div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-movie.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>Participant names for this Slam were taken from Bob Holman's journals published
in the book "Slam" which was the screenplay and journals of the film of that same
name. Directed by Marc Levin, "Slam" was a winner at both Cannes and Sundance.
Saul Williams play Ray Joshua, gifted poet and rapper, who changes his life and
the lives of others through "the word". <a href="https://devlinpix.com/slamnation/">Film link here</a></p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>ercuement aytac</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>jessica care moore</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>MuMs da schemer</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>carmen renee</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>beau sia</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-6.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>jamaal st. john</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1996-7.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>saul williams</p>
        </div>
      </div>
  </div>
  )
}

export default GrandSlam1996
