import React from 'react'

const VerbsOverview = () => {
  return (
    <div>
      <h1 className='champs-header-main'>Verbal Overview: 1990 &gt; 2007</h1>
      <p>Once upon a time, there was no place to see a Poetry Slam in New York
City unless you were on the Lower East Side and you happened to end up
at the Nuyorican Poets Cafe on East Third Street (now named "Rev. Pedro
Pietri Way", after a Nuyorican Founding Poet). All other New York Slams
originated here, which is one of the key reasons that this particular Slam
was so influential. In fact, in 1989, when the Nuyorican held its first Slam,
there were probably only a handful of places in the entire world where
Poetry Slam existed at all. Things have really changed.</p>
<p>When Poetry Slam began at the Nuyorican, it was an inclusive form; a
generous space for new ideas and poetic sounds to flourish and grow. A
wide variety of artists came through in those early days - many who would
no longer think of themselves as "Slam Poets" today and many who never
considered themselves Slam Poets in the first place. Everything we now
think of as Poetry Slam was yet to be discovered at that point, and these
performers and writers were at the edge of the form. It was an exciting time
to be around! I realised the significance of their important contributions as I
have witnessed Poetry Slam become more "predictable" and formulaic
over the years.</p>
<p>The audience potential at the Cafe also made this Poetry Slam unique. It
gave poets an opportunity to have a large and colorful spectrum of
appreciators...young, old, literary (or not) artistic, conservative,
multicultural, radical, square, rich or hip - anyone might come through the
doors on a Wednesday or Friday night. And there are no particular
qualifications for being a Poetry Slam judge, so the wild democracy of it all
made for an intense and unpredictable show.</p>
<p>From the time Bob Holman first brought Poetry Slam from Chicago to the
Nuyorican over 18 years ago, people and styles from all over the place
have Slammed on our stage: Chicago style, Los Angeles style, London
style...Australian style...people who have now gone on to Film, TV,
Broadway, major publishing houses, universities and won all sorts of
awards for their work. Because so many faces and names that have
"gotten their start" here, this site will commemorate the players with photos
and biographies. In many cases, these were submitted directly by the poet
or taken from their official website with links noted so you can meet them
all for yourself. You’ll also notice that the visual documentarians of the
Nuyorican Poetry Slam are here as well...those wonderful photographers
and videographers who created the visual records I have so cherished
through the years. There’s a lot to say and a lot of people to shout out to.
This is a large constellation of stars.</p>
<p>Trillions of words have been set off on Nuyorican’s "biggest little stage in
the world" by now, but here’s a few more, just to set the record straight. I
have posted my own thoughts, memories and even some academic-style
points here, but mostly this story is told by honoring the people who were
up on that stage each week, engaging and challenging the world, one
poem at a time.</p>
<p><b>Verbs on Asphalt: The History of Nuyorican Poetry Slam</b> is a
project conceived, produced, edited and designed by Clare Ultimo for the
Nuyorican Poets Cafe, as a thesis project for a Masters in Fine Arts/
Integrated Media Arts, Hunter College, City University of New York. Thesis
advisor: Dr. Isabel Pinedo.</p>
<h2>Love and Gratitude to</h2>
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/01.angelN.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p>Los espíritos de los poetas que vinieron antes que nosotros.</p></div>
</div>
<br />
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/02.miguel.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p><b>Miguel Algarin</b>, Founder of the Nuyorican Poets Cafe, whose courageous commitment to the Word made it all happen in the first place.</p></div>
</div>
<br />
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/03.bob.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p><b>Bob Holman</b>, our first SlamMaster, who brought Poetry Slam to the Nuyorican and got the thing started.</p></div>
</div>
<br />
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/04.keith.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p><b>Keith Roach</b>, pivotal Nuyorican SlamMaster, who had the plan that built the house...
</p></div>
</div>
<br />
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/05.carmenlois.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p><b>Lois Elaine Griffith</b> and <b>Carmen Pietri Diaz</b>, mis hermanas poéticas, who believed in my vision for this project</p></div>
</div>
<br />
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/06.samdiaz.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p><b>Sam Diaz</b> for keeping it all together against the odds.</p></div>
</div>
<br />
<div className="row">
  <div className="col-md-1 col-sm-1 col-xs-2"><img className="img-fluid" src="/07.rocky.jpg" alt="" /></div>
  <div className="col-md-8 col-xs-8 col-sm-8"><p><b>Rocky</b>, devoted DJ and the musical heart of the Slam for many years <a href="https://rockypresents.com"> www.rockypresents.com</a>.</p></div>
</div>
    </div>
  )
}

export default VerbsOverview
