import React from 'react'

const HerbBrooks = () => {
  return (

    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/photos-herbs.jpg" alt="" />
        </div>
      <div className="col-md-8">

      <h1>Herb Brooks</h1>
    <p>A native of Newark NJ, Herb has been involved with the New York artistic community
since 1992. In his short 33 years, he has taken many incarnations: writer, singer,
dancer, musician and actor. Recently, he has combined all of these into his prime
passion, filmmaking, earning a degree from NYU. As a lighting technician, he has lent
his expertise to both film and theatre projects. Mr. Brooks' shows include work with the
Mark Morris Dance Group, Meredith Monk, Alvin Ailey and Philadanco. He has also
acted as Chief lighting technician for the past three SpokenWorks ROAR Theatre
Festivals as well as many other productions at the Nuyorican Poet's Cafe. Film work
includes "Dummy" with Adrian Brody (2002), "JailBait" with Michael Pitt (2003) and
segments for the Sundance Channel and Macy's.</p>
<p>He continues to work as a videographer for short films, documentaries and Community
events. His focus in video is arts and culture and its effects on society.</p>
<p>"I had heard about and visited the Nuyorican before and in 1993, decided to do a video
project on it for school. Bob Holman was hosting that blustery January night, the energy
of the place was aflame. I knew then, crowd- crushed against the brick wall with my
camera, that I was capturing something special. To me, the Nuyorican is more than a
collective of ranters and Midnight ravers. More than a bunch of smooth-talking
wordsmiths grandstanding for glory. This is the common ground where the new oral
historians create myths and reveal realities. It's the Speak Easy oasis in a land where
free speech has become the fugitive. It is the lineage house of oral tradition from
African Griots to the Bible; to the blues; to the Bebop; to the Boriccua Bomba Plena
playing in the Barrios. And that lineage must continue with time. Over the past four
years, I have attempted to aide in the retention of the Nuyorican lineage by fusing its
rich history with technology in co-operation with the keeper of the flame, the High
Priestess of slampoetry, Clare Ultimo. I am grateful for the opportunity to take part in
continuing the legacy.</p>

      </div>
      </div>

    </div>

  )
}

export default HerbBrooks
