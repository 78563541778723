import React from 'react'

const NatTeam2005 = () => {
  return (
    <div>
    <h1>2005 Nuyorican National Slam Team</h1>
    <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2005.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">

<p>Coach: Ainsley Burrows</p>
<p>Photo: Clare Ultimo<br />
<a href="/2005Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2005-mike.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>big mike</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2005-brady.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>brady</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2005-loveous.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>oveous maximus</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2005-andrew.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>andrew tyree</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam2005
