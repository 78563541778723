import React from 'react'

const OpenIndigo
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-indigo.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>indigo</h1>
      <h2 className='sub-head'>Nuyorican Host circa 1992-1997</h2>
    <p>Indigo was a regular fixture at the Nuyorican in the early days of Poetry Slam. His off-handed (and left-handed!) style, both as a host and a poet were entirely entertaining and he was a much loved part of the scene. Indigo did host Friday Night Slams while Bob was SlamMaster, but not in any regular fashion and even
invented a few Poetry Slam themes (like the Left-Handed Poets Slam), so unlike others in this section, he sort of defies a category.</p>
<p>Indigo founded the "Left- Handed Poets", was the constant Scorekeeper of the Slams (that unforgettable voice yelling "28.2" or whatever). He was the "Heckler's Slam" Champion (...an
early Slam category), and his poetry can be found in "Aloud: Voices from the Nuyorican Poets Cafe".</p>
<p>Arthur Higbee wrote in the "International Herald Tribune" (1993):
"Mr. Indigo, 24, a student at Hunter College, waxed poetic about left-handers. 'I've always had the feeling that left-handed people were more creative,' he told The New York Times. 'They learn at an early age to do everything in the way that righthanded people do, so they need to show initiative.' "
</p>

<p>Photo by Clare Ultimo/"SlamBook magazine.</p>
      </div>
      </div>

    </div>
  )
}

export default OpenIndigo

