import React from 'react'
import ImageGallery2 from '../image-gallery-2';

const SyreetaMcFadden = () => {
  return (

    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/photos-syreeta.jpg" alt="" />
        </div>
      <div className="col-md-8">

      <h2 className='champs-header-main header-bold'>syreeta mcfadden's nuyorican slam album</h2>
    <ImageGallery2 />
    <p>Syreeta McFadden was born in the dairy state whose motto is 'forward'. She was raised
on Laverne & Shirley, Miller Beer and collard greens and has photographed everything
for over 15 years. She is lactose intolerant and eco-friendly. A Wisconsin native and a
natural New Yorker, Syreeta recently received an MFA in Creative Writing at Sarah
Lawrence College, while continuing socially responsible employment in the real estate
development industry, encouraging affordable housing and sustainable design and
construction. Her work has been honored by the NAACP National ACT-SO Program.
She has exhibited at A.I.R. Gallery, Gallery 718, 1110 Gallery, Magnolia Center and the
Goloborotko Studio. Her work has been featured in "Slambook", a publication of the
Nuyorican Poet's Cafe and Roots and "Culture Magazine" of Columbia University. Her
She is a proud resident of the Shire (ahem, Brooklyn).</p>
<p>All photos Syreeta McFadden.</p>

      </div>
      </div>

    </div>

  )
}

export default SyreetaMcFadden
