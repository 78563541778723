import React from 'react'
import { Link } from 'react-router-dom'

const DotPortella
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/hosts-dot.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1 className='title-bold'>dot portella</h1>
      <h2 className='sub-head'>Wednesday Night Slam Open Host circa 1996-99</h2>
    <p>Dot Portella is an actress, teacher, and spoken word artist. Her work has been
published in "Sky Scrapers, Taxis, and Tampons" (Fly By Night Press) and
"SLAM!" (Alloy Books). Dot was the host of the Wednesday night SLAM open at
the Nuyorican Poet’s Cafe as well as being a member of the Nuyorican’s 1997
Nuyorican National Slam team. She earned her B.F.A. in Acting at Marymount
Manhattan College and her M.A. in Educational Theatre at New York University.
Her favorite poet is her husband Frank. Please visit www.dotportella.com
for more information about Dot and to find out about her current projects.</p>

<p>See <Link to="/national-teams/1997">National Teams/1997</Link>.</p>
      </div>

      </div>

    </div>
  )
}

export default DotPortella

