import React from 'react'

const GrandSlam1997 = () => {
  return (
    <div>
    <h1>1997 Nuyorican Grand Slam Finale</h1>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1997-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>roger bonair-agard</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1997-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>william fogarty</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1997-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>sarah jones</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1997-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>flaco navaja</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1997-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>lynne procope</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/1997-6.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>jamaal st. john</p>
        </div>
      </div>
  </div>
  )
}

export default GrandSlam1997
