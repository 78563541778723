import React from 'react'
import { Link } from 'react-router-dom'


const PeterTribute = () => {
  return (
<><h1 className='champs-header-main'>TRIBUTE TO PETER OF THE EARTH</h1>
    <div className="row">
<p>"I want to be remembered, before I’m forgotten." <br/ >
-Peter of the Earth</p>
    <div className='col-md-8'>

<p>Friday night after leaving a birthday party in Chelsea, Peter and I decided
to go to the Nuyorican Poets Cafe. We hadn’t been since graduation. We
were in need of the Word.</p>
<p>Friday night after leaving a birthday party in Chelsea, Peter and I decided
to go to the Nuyorican Poets Cafe. We hadn’t been since graduation. We
were in need of the Word.</p>
<p>There were five prolific women in the slam that night, Keith Roach was
hosting. After the show the room cleared. Just the die-hards now.</p>
<p>Dot Antoniades (Portella), Guy LeCharles Gonzales, Roger Bonair-Agard,
Lynne Procope and Steve Colman sat at a table near the back brick wall.
The regulars, we supposed.</p>
<p>Rah, the Open Room host, called names from the black and white
composition notebook. Peter read a poem entitled "The Calling".</p>
<p>After Peter’s poem, Rah -- taking a line from the end of the piece --
referred to him as Peter of the Earth. That night gave birth to yet another
layer of an incredibly multifaceted man.</p>
<p>Peter James Conti was a poet, a painter, a changling. He loved calamari
and The Simpsons. He is the best friend I have ever had.</p>
<p>I cannot recall when or where we met. It is as if he had always been a part
of my life. He was the Mulder to my Scully. Homer to my Marge. He was
my heart.</p>
<p>I do not know who I will be in this world without him. But I know that my
days are full of more love and light and laughter because of his incredible
spirit.</p>
<p>- Felice Belle</p>
<p><a href="/peter-poem.PDF" target="_blank">Download a PDF Poem Tribute</a></p>
<p><Link to="/peter-calling">Read "The Calling"</Link></p>
<p><Link to="/peter-memo">Read "Memo to Midnight Dove"</Link></p>
    </div>
    <div className='col-md-4'>
      <img className="img-fluid" src="/peter-1.jpg" alt="" />
      <p className='caption'>Michael Meyer took this photo circa
1999, as Peter was reading a poem
at the Nuyorican mic.</p>
      <img className="img-fluid" src="/peter-2.jpg" alt="" />
      <p className='caption'>Listening to a poet at the Nuyorican.
Photo by Michael Meyer, circa 1999.</p>
      <img className="img-fluid" src="/peter-3.jpg" alt="" />
      <p className='caption'>At the National Poetry Slam Finals
event.<br />
Photo by Syreeta McFadden.</p>
    </div>
    </div>
    </>
  )
}

export default PeterTribute
