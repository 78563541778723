import React from 'react'

const NatTeam1992 = () => {
  return (
    <div>
       <h1>1992 Nuyorican National Slam Team</h1>
      <div className="row champ-photo teams">
        <div className="col-md-4">
          <img src="/nat-teams/1992-dana.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>dana bryant</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1992-reg.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>reg e gaines</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/PeteSpiro.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>pete spiro</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/nat-teams/1992-edwin.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>edwin torres</p>
        </div>
      </div>
    </div>
  )
}

export default NatTeam1992
