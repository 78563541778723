import React from 'react'

const NatTeam2007 = () => {
  return (
    <div>
    <h1>2007 Nuyorican National Slam Team</h1>
    <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2007.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">

<p>Coach: Mahogany Browne</p>
<p>Photo: Clare Ultimo<br />
<a href="/2007Team.pdf">Click here for PDF of poster</a></p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2007-advocate.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>advocate of wordz</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2007-aja.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>aja monet</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2007-eboni.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>eboni</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2007-tahni.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>tahani</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/2007-uninvited.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>uninvited</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam2007
