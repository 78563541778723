import React from 'react'

const GrandSlam2007 = () => {
  return (
    <div>
    <h1>2007 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: KAREN JAIME</p>
<p>Poets submitted these bios to me for the 2007 Finale Program, so they may not reflect projects and
accomplishments since that time. <a href="#poster">See original 2007 Grand Slam Finale Program</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2007-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>aja monet</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2007-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>nathan andrew</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2007-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>eboni</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2007-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>rico steal</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2007-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>shadokat</p>
        </div>
        <img id="poster" src="/2007Program.jpg" alt="" />
      </div>
  </div>
  )
}

export default GrandSlam2007
