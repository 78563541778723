import React from 'react'
import { Link } from 'react-router-dom'

const JulianCurry = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className="img-fluid champ-photo" src="/julian.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Julian Curry</h1>
      <h2 className='sub-head'>2003 Nuyorican Grand Slam Champion</h2>
    <p>Julian Curry started writing poetry in 1999. Besides receiving the 2003 crown at
the Nuyorican, he was also the 2003 Bowery Poetry Club Co-Grand Slam
Champion. His poetry is a glimpse into the inner city, Wall Street, family, and a
regular guy’s everyday life. Originally from the Bahamas, Julian now calls Harlem
his home. He has been featured in "Forbes" Magazine & on BET’s Lyric Cafe. He
was also featured on HBO’s Def Poetry Jam. Julian has also written this entire bio
in the third person. (!)</p>
<p>(Presently, all we know about Julian is that he is living in the midwest where he
has an impressive job at ING Bank. This bio is from the 2004 Grand Slam Finale
program, when he was the Spotlight Poet).</p>
<p>See <Link to="/national-teams/2003">National Teams/2003</Link> and <Link to="/grand-slam-finale/2003">Grand Slam Finale/2003</Link>.</p>
      </div>

      </div>
    </div>
  )
}

export default JulianCurry
