import React from 'react'
import { Link } from 'react-router-dom'

const XavierCavazos = () => {
  return (
    <div>
    <div className="row">
    <div className="col-md-4">
    <img className='img-fluid champ-photo' src="/xavier.jpg" alt="" />
</div>
      <div className="col-md-8">

      <h1>Xavier Cavazos</h1>
      <h2 className='sub-head'>1995 Nuyorican Grand Slam Champion</h2>
    <p>A close up portrait of Xavier written in 2000 by Jason Sanford, and originally
published in "Siren" tells us that before our 1995 Grand Slam Champion took the
title, he toured the West Coast on a Poetry Tour with Allen Ginsberg, his dear
friend, along with a master poet from Thailand named Navarat Pongpaiboon.
Xavier ended up touring Thailand himself with a six-week itinerary of poetry
readings; a trip that changed the way he saw almost everything. His work was
included in "1996 Best American Poetry" Anthology and he has been one of the
few recipients of the Nuyorican "Fresh Poets Award". Xavier was a member of
the Minnesota National Slam Team in 2000. He's a hard man to find, so Xavier, if
you're out there reading this, drop us an email with your most recent adventures.
Truly an original poetic voice, with no apologies to the "academy". Read the
wonderful article about him in "Siren".</p>
    <p>See <Link to="/national-teams/1995">National Teams/1995</Link>.</p>
    <p>Photo of Xavier performing his poetry at Kieran's Irish Pub
(picture by Jason Sanford from above article).</p>
      </div>

      </div>
    </div>
  )
}

export default XavierCavazos
