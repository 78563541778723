import React from 'react'
import { Link } from 'react-router-dom'

const GrandSlam2000 = () => {
  return (
    <div>
    <h1>2000 Nuyorican Grand Slam Finale</h1>
    <p>SLAMMISTRESS: FELICE BELLE</p>
<p>This was the year I created the "SlamBook", our one-and-only Nuyorican Poetry Slam magazine, instead
of an evening program. All the 2000 Grand Slam participants are included in it. To find out more about
the "SlamBook" see the <Link to="/media">Verbs Media page</Link>.</p>
<p>Poets submitted these bios to me for the 2000 "SlamBook", so they may not reflect projects and
accomplishments since that time. <a href="#poster">See SlamBook Magazine: Grand Slam Finale Program PDF (created by Clare Ultimo)</a></p>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2000-1.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>bryonn bain</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2000-2.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>celena glenn</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2000-3.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>nathan p</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2000-4.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>safahri</p>
        </div>
      </div>
      <div className="row champ-photo">
        <div className="col-md-4">
          <img src="/grand-slam/2000-5.jpg" className='img-fluid' alt="" />
        </div>
        <div className="col-md-8 teams">
          <p>jamaal st. john</p>
        </div>
        <a href="/2000program.pdf"><img id="poster" src="/SlambookCover.jpeg" alt="" /></a>
      </div>
  </div>
  )
}

export default GrandSlam2000
