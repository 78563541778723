import React from 'react'

const OpenJuliet
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-juliet.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>juliet gomez</h1>
      <h2 className='sub-head'>Open Room Host circa 1999</h2>
    <p>Juliet Gomez was born on the Lower East Side in 1979, and was raised in
Brooklyn, where she currently resides. Her acquaintance with the Nuyorican
Poets Cafe began when she was still living on East 3rd Street and visited often in
her stroller. She was five when she first performed on the Nuyorican stage ” she
read a poem about hotdogs.</p>
<p>Juliet continued to write poetry and, as a teenager, developed a desire share to
her love of words with others, especially young people of color. She found her
way back to the Nuyorican stage, this time as a host. She began as the Open
Room host on Friday nights and eventually hosted the Open Slam on Wednesday
nights as well. Juliet gradually moved away from spoken word, although she has
never varied from her belief in the transformative power of the pen and the written
word.</p>
<p>Juliet is currently working with out of school youth in a social services agency in
Sunset Park, Brooklyn. She is now bringing the magic of urban poetry to urban
youth. Some of the books she has worked from are "Aloud: Voices from the
Nuyorican Poets Cafe", "Born Palestinian, Born Black", "Where a Nickel Costs a
Dime", and "The Words Don’t Fit in My Mouth". She has been the editor of six
literary journals consisting of student art and poetry, produced four open mic
nights, and is currently developing curriculum that utilizes poetry, hip hop and
theater.</p>
<p>Juliet graduated from Brooklyn College with a degree in film and is currently a
graduate student at NYU studying Educational Theater and Social Work. In
August, 2007 she will be studying abroad in Brazil with Augusto Boal, founder of
Theater of the Oppressed. She hopes to one day work with at risk young women
using the arts as a tool for female empowerment.</p>
      </div>
      </div>

    </div>
  )
}

export default OpenJuliet

