import React from 'react'
import { Link } from 'react-router-dom'

const NationalTeams = () => {
  return (
    <div>
    <div className="row">
    <a className="link-top" href="#teams">Read about Nuyorican Slam Teams</a>
    <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1990"><img className="img-fluid" src="/nat-teams/1990NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1990">1990</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1991"><img className="img-fluid" src="/nat-teams/1991NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1991">1991</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1992"><img className="img-fluid" src="/nat-teams/1992NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1992">1992</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1993"><img className="img-fluid" src="/nat-teams/1993NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1993">1993</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1994"><img className="img-fluid" src="/nat-teams/1994NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1994">1994</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1995"><img className="img-fluid" src="/nat-teams/1995NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1995">1995</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1996"><img className="img-fluid" src="/nat-teams/1996NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1996">1996</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1997"><img className="img-fluid" src="/nat-teams/1997NatTeamIcon.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1997">1997</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1998"><img className="img-fluid" src="/nat-teams/1998.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1998">1998</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/1999"><img className="img-fluid" src="/nat-teams/1999.jpg"alt="" /></Link>
        <p><Link to="/national-teams/1999">1999</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2000"><img className="img-fluid" src="/nat-teams/2000.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2000">2000</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2001"><img className="img-fluid" src="/nat-teams/2001.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2001">2001</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2002"><img className="img-fluid" src="/nat-teams/2002.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2002">2002</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2003"><img className="img-fluid" src="/nat-teams/2003.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2003">2003</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2004"><img className="img-fluid" src="/nat-teams/2004.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2004">2004</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2005"><img className="img-fluid" src="/nat-teams/2005.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2005">2005</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2006"><img className="img-fluid" src="/nat-teams/2006.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2006">2006</Link></p>
      </div>
      <div className="col-md-3 col-sm-6 col-xs-6">
        <Link to="/national-teams/2007"><img className="img-fluid" src="/nat-teams/2007.jpg"alt="" /></Link>
        <p><Link to="/national-teams/2007">2007</Link></p>
      </div>
    </div>
      <p id="teams">Everyone without exception on this huge list of talent would have to say that being a part of the Nuyorican National Slam
team is a unique experience.</p>
<p>The National Poetry Slam is a yearly event where competing teams of performance-styled poets, representing
venues and cities around the country, come together to compete for First Place and bragging rites. Approximately seventy
teams participate each year.</p>
<p>I've heard Marc Smith call this gathering, the "poetry slam family" and it's true for most that coming out to Nationals has
a familial angle to it ” complete with the warmth as well as the difficulties! A great example of the discussions/arguments/
exchanges that happen here looks like the movie "SlamNation" where the annual SlamMaster's meeting, shown in that
film, gives you an idea of how much the Slam Community openly disagrees and/or agrees with each other. Folks from all
over the place converge to exchange notes and ideas, "spit" poems in parking lots and stay up way too late reading poetry
to each other in their rooms.</p>
<p>I have accompanied the team to Nationals for ten years now. Even though I really dislike popular sports, I admit I like
checking the daily score sheets in the lobby of the main hotel to see how my team is doing in the competition; it's fun. But I
really like listening to how (and if ) geography and "place" influence the style or content of Slam poetry. Through these
years I have observed that the differences are becoming less evident as Slam itself evolves.</p>
<p>Until 2008, each Nuyorican Team member would have participated only one time on the team to represent the Cafe, so
there are many different names and faces listed here. The Nuyorican has continuously enabled new voices to enter the
National scene and our teams have come to represent a current and constantly growing poetic community. Many past
team members have told me that being on the team had a catalytic effect on their careers, encouraging them to focus and
direct their talents into the future.</p>
<p>The Nuyorican's ongoing involvement with the National event led me to create what are now considered Nuyorican Team
traditions: T-Shirt designs as well as the yearly team photograph. I have been photographing the teams formally since
1999, making this collection of portraits a permanent record of Nuyorican National Team members since then. But photos
of earlier teams were difficult to come by, so if you've got any great team shots to share prior to 1998, please Email here.</p>
<p>Many thanks to PSI for their dedication to this through the years, and to our SlamMasters, Team Captains, Coaches (and
Guest Coaches!) who participate and work with our teams every year. -CU</p>
    </div>
  )
}

export default NationalTeams
