import React from 'react'

const NatTeam1997 = () => {
  return (
    <div>
    <h1>1997 Nuyorican National Slam Team</h1>
   <div className="row champ-photo teams">
     <div className="col-md-4">
       <img src="/nat-teams/1997-roger.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>roger bonair-agard</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1997-dot.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>dot portella</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1997-brett.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>brett halsey</p>
     </div>
   </div>
   <div className="row champ-photo">
     <div className="col-md-4">
       <img src="/nat-teams/1997-sarah.jpg" className='img-fluid' alt="" />
     </div>
     <div className="col-md-8 teams">
       <p>sarah jones</p>
     </div>
   </div>
 </div>
  )
}

export default NatTeam1997
