import React from 'react'

const BeforeWeBegin = () => {
  return (
    <div>
      <h1>Before We Begin (Scope and Goals of Project)</h1>

To avoid any possible confusion, please note some important facts about this project:
<h2>1. This is NOT a history of the Nuyorican Poets Cafe.</h2>
<p>That, my dear poets and friends, is a job that awaits even more intrepid souls. That history
encompasses the roots of a tradition whose influence has extended so far and wide, across continents
and languages, that I dare not attempt it and not do it well. If you are a Nuyorican Founding poet, or you
are in "Aloud", you are part of the history of the Nuyorican Poets Cafe, but that's a different story to
tell. The Cafe is much older than the Poetry Slam (The Cafe celebrated its 30th year in 2003) and the
rich artistic history of the Cafe includes theater, music, video and visual art.</p>
<h2>2. This is also NOT a history of Poetry Slam.</h2>
<p>As you all know by now, Marc Smith, a poet and construction worker in Chicago, is credited with
starting the thing, and now it's a global phenomenon. Thousands of names and beautiful voices all, and
parts of that history have been attempted already in print and film, by other brave documentarians, god
bless them.</p>
<h2>3. This is simply the history of Poetry Slam at the Nuyorican Poets Cafe</h2>
<p>...the original home for Slam in New York City. I feel it is possibly one of the most crucial and important
ones to tell. It spans over eighteen years and began here with Bob Holman circa 1989. And it flourishes
today with a scene bigger than (probably) anyone ever imagined 20 years ago.</p>
<h2>4. The information here is accurate but possibly not perfect</h2>
<p>How accurate is it? Pretty damn near. I have kept Nuyorican Poetry Slam records, quite accurately,
from 1997 on and I began a Poetry Slam "identity" program in 1998. Would I have liked to include every
Slammer that ever competed throughout the years or every mystical Open Room poet that graced our
ears? Of course! Could I? Not in this life.</p>
<p>Within the categories of participants listed here, there are over a hundred names and faces to note. To
complicate matters, most of the names you'll see on this site appear only once in Nuyorican Slam
history. Which leads me to the next, important point...</p>
<h2>5. Getting the most recent bios and pictures for over a hundred people hasn't been
easy</h2>
<p>Folks have moved away from Slam, or moved out of the local area. Some have considered Slam a
short-lived adventure in their artistic life and continued their artistry in other forms. Many have not been
easy to find even via internet or email, and many, even when contacted, haven't been available to
respond in time. If your bio and picture need to be changed, that's fine...shoot us an email and your
info will be updated.</p>
    </div>
  )
}

export default BeforeWeBegin
