import React from 'react'
import { Link } from 'react-router-dom';
import { getHosts } from '../../host.js';

const SlamMasters = () => {
  let hosts = getHosts()
  return (
    <div>
      <h1>Nuyorican SlamMasters: 1990-2007</h1>
      <div className="row">
      <a href="#host-content">Read about the Nuyorican Friday Night Poetry Slam</a>
      {hosts.map((host) => (

        <div className="col-md-4 col-sm-6 col-xs-6">

          <Link to={`${host.slug}`}><img className="img-fluid" src={host.picture} alt="" /></Link>
          <p className='title-bold'><Link to={`${host.slug}`}>{host.name}</Link></p>
        </div>
        ))}
      </div>
<div id="host-content">
      <p>Hosting any poetry event at the Nuyorican is not for the faint-hearted
and the Friday Night Slam is the biggest poetry event we have each
week. Our audiences come from all over the world, as well as just next
door, to check out the Slam. We have everyone from someone's
grandmother to film producers in our audience at any given time, (and
the audience is usually pretty large!) so our SlamMasters have their
work cut out for them.</p>
<p>
What we mean by "SlamMasters" at the Nuyorican Poets Cafe, (or
"SlamMistress" for females) is that person who is in charge of our
Friday Night Poetry Slams. Besides hosting the evening, this means
putting the performers together each week and being an integral part of
the Slam community. They book "Spotlight Poets" (non-competing
Features that perform before the Slam begins), and get judges from the
audience each Friday night. There are a variety of names for this sort of
person around the scene... some call them just "hosts" or "emcees". But
the role at the Nuyorican means that SlamMasters are deeply involved
and committed to the scene and the Slam community at large.</p>
<p>
SlamMasters are often poets themselves, but this role requires a certain
sacrifice ” reading your own poetry on stage is not the reason you are a
SlamMaster. It's also a weekly event, so it takes enormous energy. It
can be a tricky balance to maintain, especially for those who are
themselves developing their literary or theatrical careers. Besides
organizing and presenting an entertaining show to our audiences each
and every week, Nuyorican SlamMasters take interest in emerging
poets and encourage those who are interested in going on to the
National scene. The Nuyorican has been fortunate to have some of the
most stage-exciting, charming, intelligent and passionate artists to date
hosting and curating our Poetry Slam ” they have played a major role in
the fame and popularity of Slam at the Nuyorican.</p>
<p>
I have had the good fortune of seeing every single one of these Friday
Night SlamMasters in action, watching the different (and similar) Poetry
Slam scenes they created. I also saw the difficult demands of this job
and have a great deal of respect for it. Throughout the history of Poetry
Slam at the Nuyorican, there always has been the commitment and
strength of our SlamMasters, bringing out the freshest voices on the
scene. The Nuyorican Slam scene remains forever grateful to them for
their dedication and talent. - CU</p>
</div>
</div>
  )
}

export default SlamMasters
