import React from 'react'

const Indigo
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/aja.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>Aja Monet</h1>
      <h2 className='sub-head'>2007 Nuyorican Grand Slam Champion</h2>
    <p>Bob Holman eloquently describes her as a, "glutton for love and beauty" -
classically surrealist. I haven't heard a 'humming bird released from a mother's
ribcage in the last week or two,' wow."</p>
<p>Only 19 years old, Aja-Monet, depicts a world in us not yet known. A Cuban-
Jamaican representing East New York, Brooklyn, Aja-Monet is currently attending
Sarah Lawrence College, concentrating in Writing and Religion. Monet has been
on the Urban Word NYC Slam Team in 2005 and represented New York City at
the Brave New Voices Inter-National Poetry Slam in San Francisco, reigning, as
the first NYC team to ever win the Youth National Poetry Slam team title. She has
been featured at the 2005 Redhook Festival and the 2006 Brooklyn Literary
Festival. She has also been featured in the Say It Loud magazine, The Brooklyn
Rail, on the MNN television channel, and has been heard and interviewed on New
York City's WBAI radio station on numerous occasions. As well, Aja-Monet has
had three Human Relations Media documentary-educational films feature her
talent, drive, passion, and story. She has shared the stage and performed for the
likes of Russell Simmons, Reverend Run, Foxy Brown, Doug E Fresh, Wyclef
Jean, Black Ice, Amir Sulaiman as well as countless others. A vivid, raw, and real
expression of her experience and imagination, Aja-Monet moves audiences of all
kind. She has been reviewed in the New York Times along with Camille A. Brown
and Dancers for her "bold, glistening spoken word imagery" as quoted by Gay
City News. Monet currently dedicates her time working with inner-city youth and
poetry for non-profit organizations such as Urban Word NYC, In Arms Reach, and
Odyssey House. Aja-Monet was recently selected as a student poet to read in the
2007 Sarah Lawrence College Poetry Festival along with Jeffrey McDaniel, Sonia
Sanchez, Yusef Komunyakaa, and Phillip Levine.--If Aja-Monet could ask that
everyone come away from hearing her with one thing, it would be their soul; that
they might learn to love it, to carry it, and the importance of nurturing it.</p>
<p>See National Teams/2007 and Grand Slam Finales/2007.</p>
      </div>
      </div>

    </div>
  )
}

export default Indigo

