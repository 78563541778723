import React from 'react'

const OpenRich
 = () => {
  return (
    <div>
      <div className="row">
      <div className="col-md-4">
      <img className="img-fluid champ-photo" src="/open-atrayn.jpg" alt="" />
</div>
      <div className="col-md-8">
      <h1 className='title-bold'>a-trayn</h1>
      <h2 className='sub-head'>Open Room Host: 2006-2008</h2>
    <p>A bio of A-Trayn (Anthony Armstrong) was unavailable at this time, but not
because we don't know where to find him. It might be because he's so busy
writing new poetry, but then, I'm not sure. To get a really recent update on his
whereabouts, come down to the Open Room one Friday night, read a poem and
ask him yourself.</p>
      </div>
      </div>

    </div>
  )
}

export default OpenRich

