import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import ScrollToTop from './scrollToTop';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import Accuracy from './components/verbs-overview/accuracy';
import Media from './components/verbs-overview/media';
import Photography from './components/verbs-overview/photography';
import BeforeWeBegin from './components/verbs-overview/before-we-begin';
import Glossary from './components/verbs-overview/glossary';
import Reviews from './components/verbs-overview/reviews';
import Sensibility from './components/verbs-overview/sensibility';
import NationalTeams from './components/verbs-overview/national-teams';
import Visuality from './components/verbs-overview/visuality';
import PeterTribute from './components/verbs-overview/peter-tribute';
import PeterCalling from './components/verbs-overview/peter-calling';
import PeterMemo from './components/verbs-overview/peter-memo';
import GrandSlamFinale from './components/verbs-overview/grand-slam-finale';
import GrandSlamChampions from './components/verbs-overview/grand-slam-champions';
import PaulBeatty from './components/grand-slam-champions/paul-beatty';
import RegEGaines from './components/grand-slam-champions/reg-e-gaines';
import WilliePerdomo from './components/grand-slam-champions/willie-perdomo';
import DanaBryant from './components/grand-slam-champions/dana-bryant';
import TracieMorris from './components/grand-slam-champions/tracie-morris';
import PeteSpiro from './components/grand-slam-champions/pete-spiro';
import RegieCabico from './components/grand-slam-champions/regie-cabico';
import TishBenson from './components/grand-slam-champions/tish-bensen';
import XavierCavazos from './components/grand-slam-champions/xavier-cavazos';
import SaulWilliams from './components/grand-slam-champions/saul-williams';
import SarahJones from './components/grand-slam-champions/sarah-jones';
import Kayo from './components/grand-slam-champions/kayo';
import KirkNugent from './components/grand-slam-champions/kirk-nugent';
import BryonnBain from './components/grand-slam-champions/bryonn-bain';
import MaydaDelValle from './components/grand-slam-champions/mayda-del-valle';
import KahlilAlmustafa from './components/grand-slam-champions/kahlil-almustafa';
import JulianCurry from './components/grand-slam-champions/julian-curry';
import DanielBeaty from './components/grand-slam-champions/daniel-beaty';
import AndrewTyree from './components/grand-slam-champions/andrew-tyree';
import ArchieTheMessenger from './components/grand-slam-champions/archie-the-messenger';
import AjaMonet from './components/grand-slam-champions/aja-monet';
import SlamMasters from './components/verbs-overview/slam-masters';
import VerbsOverview from './components/verbs-overview/verbs-overview';
import BobHolman from './components/hosts/bob-holman';
import KeithRoach from './components/hosts/keith-roach';
import FeliceBelle from './components/hosts/felice-belle';
import KarenJaime from './components/hosts/karen-jaime';
import NathanP from './components/hosts/nathan-p';
import MahoganyBrowne from './components/hosts/mahogany-browne';
import AnthonyMorales from './components/hosts/anthony-morales';
import DotPortella from './components/hosts/dot-portella';
import Indigo from './components/hosts/indigo';
import OpenIndigo from './components/hosts/open-indigo';
import OpenAnthony from './components/hosts/open-anthony';
import OpenAtrayn from './components/hosts/open-atrayn';
import OpenGuy from './components/hosts/open-guy';
import OpenJeffrey from './components/hosts/open-jeffrey';
import OpenJuliet from './components/hosts/open-juliet';
import OpenKeith from './components/hosts/open-keith';
import OpenLois from './components/hosts/open-lois';
import OpenRich from './components/hosts/open-rich';
import JivePoetic from "./components/hosts/jive-poetic";
import EvertEden from "./components/hosts/evert-eden";
import ShutUpShelly from "./components/hosts/shut-up-shelly";
import EileenReyes from "./components/hosts/eileen-reyes";
import CelenaGlenn from "./components/hosts/celena-glenn";
import WedSlamMasters from './components/verbs-overview/wednesday-slam-masters';
import OpenRoom from './components/verbs-overview/open-room';
import ClareUltimo from './components/photography/clare';
import MichaelMeyers from './components/photography/michael';
import SyreetaMcFadden from './components/photography/syreeta';
import HerbBrooks from './components/photography/herb-brooks';
import ThomasPoole from './components/photography/thomas-poole';
import NatTeams1990 from './components/national-teams/1990';
import NatTeams1991 from './components/national-teams/1991';
import NatTeams1992 from './components/national-teams/1992';
import NatTeams1993 from './components/national-teams/1993';
import NatTeams1994 from './components/national-teams/1994';
import NatTeams1995 from './components/national-teams/1995';
import NatTeams1996 from './components/national-teams/1996';
import NatTeams1997 from './components/national-teams/1997';
import NatTeams1998 from './components/national-teams/1998';
import NatTeams1999 from './components/national-teams/1999';
import NatTeams2000 from './components/national-teams/2000';
import NatTeams2001 from './components/national-teams/2001';
import NatTeams2002 from './components/national-teams/2002';
import NatTeams2003 from './components/national-teams/2003';
import NatTeams2004 from './components/national-teams/2004';
import NatTeams2005 from './components/national-teams/2005';
import NatTeams2006 from './components/national-teams/2006';
import NatTeams2007 from './components/national-teams/2007';
import NatTeams2008 from './components/national-teams/2008';
import GrandSlam1990 from './components/grand-slam-finale/1990';
import GrandSlam1991 from './components/grand-slam-finale/1991';
import GrandSlam1992 from './components/grand-slam-finale/1992';
import GrandSlam1993 from './components/grand-slam-finale/1993';
import GrandSlam1994 from './components/grand-slam-finale/1994';
import GrandSlam1995 from './components/grand-slam-finale/1995';
import GrandSlam1996 from './components/grand-slam-finale/1996';
import GrandSlam1997 from './components/grand-slam-finale/1997';
import GrandSlam1998 from './components/grand-slam-finale/1998';
import GrandSlam1999 from './components/grand-slam-finale/1999';
import GrandSlam2000 from './components/grand-slam-finale/2000';
import GrandSlam2001 from './components/grand-slam-finale/2001';
import GrandSlam2002 from './components/grand-slam-finale/2002';
import GrandSlam2003 from './components/grand-slam-finale/2003';
import GrandSlam2004 from './components/grand-slam-finale/2004';
import GrandSlam2005 from './components/grand-slam-finale/2005';
import GrandSlam2006 from './components/grand-slam-finale/2006';
import GrandSlam2007 from './components/grand-slam-finale/2007';
import Bibliography from './components/verbs-overview/bibliography';
import MahoganyBrowneWeds from './components/hosts/mahogany-browne-weds';
import NathanPWeds from "./components/hosts/nathan-p-weds"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} >
          <Route path="/" element={<VerbsOverview />} />
          <Route path="/verbs-overview" element={<VerbsOverview />} />
          <Route path="/accuracy" element={<Accuracy />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/verbs-overview/accuracy" element={<Accuracy />} />
          <Route path="/before-we-begin" element={<BeforeWeBegin />} />
          <Route path="/glossary" element={<Glossary />} />
          <Route path="/slam-masters" element={ <SlamMasters/> } />
          <Route path="verbs-overview/national-teams" element={ <NationalTeams/> } />
          <Route path="/media" element={ <Media/> } />
          <Route path="verbs-overview/grand-slam-finale" element={ <GrandSlamFinale/> } />
          <Route path="verbs-overview/reviews" element={ <Reviews /> } />
          <Route path="verbs-overview/photography" element={ <Photography /> } />
          <Route path="verbs-overview/before-we-begin" element={ <BeforeWeBegin /> } />
          <Route path="verbs-overview/accuracy" element={ <Accuracy /> } />
          <Route path="verbs-overview/glossary" element={ <Glossary /> } />
          <Route path="/peter-tribute" element={ <PeterTribute /> } />
          <Route path="/peter-calling" element={ <PeterCalling /> } />
          <Route path="/peter-memo" element={ <PeterMemo /> } />
          <Route path="/visuality" element={ <Visuality /> } />
          <Route path="/sensibility" element={ <Sensibility /> } />
          <Route path="grand-slam-champions" element={ < GrandSlamChampions /> } />
          <Route path="grand-slam-champions/paul-beatty" element={ <PaulBeatty /> } />
          <Route path="grand-slam-champions/reggie-gaines" element={ <RegEGaines /> } />
          <Route path="grand-slam-champions/willie-perdomo" element={ <WilliePerdomo /> } />
          <Route path="grand-slam-champions/dana-bryant" element={ <DanaBryant /> } />
          <Route path="grand-slam-champions/tracie-morris" element={ <TracieMorris /> } />
          <Route path="grand-slam-champions/pete-spiro" element={ <PeteSpiro /> } />
          <Route path="grand-slam-champions/regie-cabico" element={ <RegieCabico /> } />
          <Route path="grand-slam-champions/tish-benson" element={ <TishBenson /> } />
          <Route path="grand-slam-champions/xavier-cavazos" element={ <XavierCavazos /> } />
          <Route path="grand-slam-champions/saul-williams" element={ <SaulWilliams /> } />
          <Route path="grand-slam-champions/sarah-jones" element={ <SarahJones /> } />
          <Route path="grand-slam-champions/kayo" element={ <Kayo /> } />
          <Route path="grand-slam-champions/kirk-nugent" element={ <KirkNugent /> } />
          <Route path="grand-slam-champions/bryonn-bain" element={ <BryonnBain /> } />
          <Route path="grand-slam-champions/mayda-del-valle" element={ <MaydaDelValle /> } />
          <Route path="grand-slam-champions/kahlil-almustafa" element={ <KahlilAlmustafa /> } />
          <Route path="grand-slam-champions/julian-curry" element={ <JulianCurry /> } />
          <Route path="grand-slam-champions/daniel-beaty" element={ <DanielBeaty /> } />
          <Route path="grand-slam-champions/andrew-tyree" element={ <AndrewTyree /> } />
          <Route path="grand-slam-champions/archie-the-messenger" element={ <ArchieTheMessenger /> } />
          <Route path="grand-slam-champions/aja-monet" element={ <AjaMonet /> } />
          <Route path="/open-room" element={ <OpenRoom /> } />
          <Route path="/wednesday-slam-masters" element={ <WedSlamMasters /> } />
          <Route path="slam-masters/bob-holman" element={ <BobHolman /> } />
          <Route path="slam-masters/keith-roach" element={ <KeithRoach /> } />
          <Route path="slam-masters/felice-belle" element={ <FeliceBelle /> } />
          <Route path="slam-masters/karen-jaime" element={ <KarenJaime /> } />
          <Route path="slam-masters/mahogany-browne" element={ <MahoganyBrowne /> } />
          <Route path="slam-masters/nathan-p" element={ <NathanP /> } />
          <Route path="slam-masters/mahogany-browne-weds" element={ <MahoganyBrowneWeds /> } />
          <Route path="slam-masters/nathan-p-weds" element={ <NathanPWeds /> } />
          <Route path="slam-masters/anthony-morales" element={ <AnthonyMorales /> } />
          <Route path="slam-masters/dot-portella" element={ < DotPortella /> } />
          <Route path="slam-masters/indigo" element={ <Indigo /> } />
          <Route path="slam-masters/jive-poetic" element={ <JivePoetic /> } />
          <Route path='slam-masters/evert-eden' element={<EvertEden />} />
          <Route path='slam-masters/shut-up-shelly' element={<ShutUpShelly/>} />
          <Route path='slam-masters/eileen-reyes' element={<EileenReyes/>} />
          <Route path='slam-masters/celena-glenn' element={<CelenaGlenn/>} />
          <Route path="slam-masters/open-anthony" element={ <OpenAnthony /> } />
          <Route path="slam-masters/open-atrayn" element={ <OpenAtrayn/> } />
          <Route path="slam-masters/open-guy" element={ < OpenGuy /> } />
          <Route path="slam-masters/open-indigo" element={ <OpenIndigo /> } />
          <Route path="slam-masters/open-jeffrey" element={ <OpenJeffrey /> } />
          <Route path='slam-masters/open-juliet' element={<OpenJuliet />} />
          <Route path='slam-masters/open-keith' element={<OpenKeith/>} />
          <Route path='slam-masters/open-lois' element={<OpenLois/>} />
          <Route path='slam-masters/open-rich' element={<OpenRich/>} />
          <Route path="photography/clare" element={ <ClareUltimo /> } />
          <Route path="photography/syreeta" element={ <SyreetaMcFadden /> } />
          <Route path="photography/michael" element={ <MichaelMeyers /> } />
          <Route path="photography/herb" element={ <HerbBrooks /> } />
          <Route path="photography/thomas" element={ <ThomasPoole /> } />
          <Route path="national-teams/1990" element={ <NatTeams1990 /> } />
          <Route path="national-teams/1991" element={ <NatTeams1991 /> } />
          <Route path="national-teams/1992" element={ <NatTeams1992 /> } />
          <Route path="national-teams/1993" element={ <NatTeams1993 /> } />
          <Route path="national-teams/1994" element={ <NatTeams1994 /> } />
          <Route path="national-teams/1995" element={ <NatTeams1995 /> } />
          <Route path="national-teams/1996" element={ <NatTeams1996 /> } />
          <Route path="national-teams/1997" element={ <NatTeams1997 /> } />
          <Route path="national-teams/1998" element={ <NatTeams1998 /> } />
          <Route path="national-teams/1999" element={ <NatTeams1999 /> } />
          <Route path="national-teams/2000" element={ <NatTeams2000 /> } />
          <Route path="national-teams/2001" element={ <NatTeams2001 /> } />
          <Route path="national-teams/2002" element={ <NatTeams2002 /> } />
          <Route path="national-teams/2003" element={ <NatTeams2003 /> } />
          <Route path="national-teams/2004" element={ <NatTeams2004 /> } />
          <Route path="national-teams/2005" element={ <NatTeams2005 /> } />
          <Route path="national-teams/2006" element={ <NatTeams2006 /> } />
          <Route path="national-teams/2007" element={ <NatTeams2007 /> } />
          <Route path="national-teams/2008" element={ <NatTeams2008 /> } />
          <Route path="grand-slam-finale/1990" element={ <GrandSlam1990 /> } />
          <Route path="grand-slam-finale/1991" element={ <GrandSlam1991 /> } />
          <Route path="grand-slam-finale/1992" element={ <GrandSlam1992 /> } />
          <Route path="grand-slam-finale/1993" element={ <GrandSlam1993 /> } />
          <Route path="grand-slam-finale/1994" element={ <GrandSlam1994 /> } />
          <Route path="grand-slam-finale/1995" element={ <GrandSlam1995 /> } />
          <Route path="grand-slam-finale/1996" element={ <GrandSlam1996 /> } />
          <Route path="grand-slam-finale/1997" element={ <GrandSlam1997 /> } />
          <Route path="grand-slam-finale/1998" element={ <GrandSlam1998 /> } />
          <Route path="grand-slam-finale/1999" element={ <GrandSlam1999 /> } />
          <Route path="grand-slam-finale/2000" element={ <GrandSlam2000 /> } />
          <Route path="grand-slam-finale/2001" element={ <GrandSlam2001 /> } />
          <Route path="grand-slam-finale/2002" element={ <GrandSlam2002 /> } />
          <Route path="grand-slam-finale/2003" element={ <GrandSlam2003 /> } />
          <Route path="grand-slam-finale/2004" element={ <GrandSlam2004 /> } />
          <Route path="grand-slam-finale/2005" element={ <GrandSlam2005 /> } />
          <Route path="grand-slam-finale/2006" element={ <GrandSlam2006 /> } />
          <Route path="grand-slam-finale/2007" element={ <GrandSlam2007 /> } />
          <Route path="/bibliography" element={ <Bibliography /> } />


        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

