import React from 'react'
import { Link } from 'react-router-dom';
import { getPhotography } from "../../photo.js";

const Photography = () => {
  let photography = getPhotography();
  return (
    <div>
      <h1>The Photography</h1>
      <div className="row">
      <a href="#media">About Verbs Media</a>
      {photography.map((photo) => (

        <div className="col-md-4 col-sm-6 col-xs-6 champ-photo">
          <Link to={`${photo.slug}`}><img className="img-fluid" src={photo.picture} alt="" /></Link>
          <p><Link to={`${photo.slug}`}>{photo.name}</Link></p>
        </div>
        ))}
      </div>
      <p id="media">
Without the artistic interest of these photographers and videographers, "Verbs on Asphalt" would have been seriously media-challenged! While I made it my business to take what I thought of as "family snapshots" and shoot some video of Slam (at least for the record) this group of visual artists were out to seriously create evocative and beautiful visions of the poets and environment of the Nuyorican at that time. As you look at their work, you can see why I became hooked on what they were doing.</p>
<p>The lighting at the Cafe is typically "club-like" (and not photo-friendly) so the photographer's task was to hit visual depth and tone along with the emotion of a performance "moment", something that is not easy. Michael shot these in black and white and printed his own photographs; Syreeta shot hers long before digital cameras existed. Media archiving seems to be a constantly shifting technology; I am happy to present the first online digital collection of their work ever seen in the context of Nuyorican Slam history.</p>
<p>In both Michael and Syreeta Photo Galleries, you'll see the Nuyorican Slam scene from 1998 until 2000, when they were both visiting the Cafe regularly with their cameras. Some of the photos you will see here inspired the "SlamBook" magazine I created as well. Together, they have given us the most extensive and beautifully shot photographs of what was going on in the Nuyorican Poetry Slam during those significant years of the form.</p>
<p>The earliest videos of Slam at the Cafe were taken by Tom Poole, well before it was the phenomenon it has become today. His work provides a crucial visual story that you can see on the Verbs Media page. Herb Brooks' work can also be seen there.</p>
<p>All along, I felt the historical importance of these wonderful visual documents; I treasured and kept track of them and of course, found them all very inspiring. The photographic galleries feel a lot to me like the photos that Fred McDarrah took of the Beat scene during the 50s; they become the kind of visual record that relate time, culture and the people who were making it happen. While you may not recognize everyone in every photo, that fleeting emotional moment is conveyed through all of them. Every story tells a thousand pictures... -CU</p>
    </div>
  )
}

export default Photography
